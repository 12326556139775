import { Component, HostListener, OnInit } from '@angular/core';
import { ConsultationHistoryComponent } from './components/consultation-header/consultation-history/consultation-history.component';

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss'],
})
export class ConsultationComponent implements OnInit {
  testhistory = ConsultationHistoryComponent;

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  constructor() {}
  
  ngOnInit(): void {}
}
