<div class="BMI_calculator" fxFlex="100" fxLayout="column">
  <div fxLayout="column">


    <mat-card-title for="">BMI Calculator</mat-card-title>
    <mat-card-title *ngIf="bmiValue != 0">{{ bmiValue }}
      <span *ngIf="bmiValue <= 18.5 && bmiValue != 0">Underweight</span>
      <span *ngIf="bmiValue >= 18.6 && bmiValue <= 24.9">Normal</span>
      <span *ngIf=" bmiValue >= 25 && bmiValue <= 29.9">Overweight</span>
      <span *ngIf="bmiValue > 30"> Obese BMI</span> 
    </mat-card-title>
  </div>

  <ng-container>
    <div class="" fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
      <div class="deserve_title" fxLayout="column">
        <h3>Underweight</h3>
        <span>0 to 18.5</span>
        <div class="status underweight" [ngClass]="{
              positive: bmiValue <= 18.5 && bmiValue != 0
            }"></div>
       
      </div>
      <div class="deserve_title" fxLayout="column">
        <h3>Normal</h3>
        <span>18.5 to 25</span>
        <div class="status normal" [ngClass]="{
              positive: bmiValue >= 18.6 && bmiValue <= 24.99
            }"></div>
        
      </div>
      <div class="deserve_title" fxLayout="column">
        <h3>Overweight</h3>
        <span>25 to 30</span>
        <div class="status overweight" [ngClass]="{
              positive: bmiValue >= 25 && bmiValue <= 29.99
            }"></div>
       
      </div>
      <div class="deserve_title" fxLayout="column">
        <h3>Obese</h3>
        <span>Above 30</span>
        <div class="status obese" [ngClass]="{
              positive: bmiValue > 30
            }"></div>
       
      </div>
    </div>
  </ng-container>
</div>