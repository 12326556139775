import { Component, OnInit } from '@angular/core';
import { RestApiService } from 'src/app/core/https/rest-api.service';
import { CALLHISTOY } from 'src/app/core/mocks/api/consultation-history';
import { ConsultationHistoryService } from 'src/app/core/services/consultation-history.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ConsultatioHistory } from 'src/app/shared/models/consultation-history.model';
import { APIEndPoint } from 'src/app/core/https/ApiEndPoint';
import { durationConstants } from '../../consultation-dailog-box/prescription/prescription.component';
import { MomentService } from 'src/app/core/services/moment.service';

@Component({
  selector: 'app-consultation-history',
  templateUrl: './consultation-history.component.html',
  styleUrls: ['./consultation-history.component.scss'],
})
export class ConsultationHistoryComponent implements OnInit {
  consultation = CALLHISTOY;
  consultHistory: ConsultatioHistory[] = [];
  selectedHistory = null;
  currentPage = 0;
  consultationDetails: any;

  constructor(
    private consultationHistoryService: ConsultationHistoryService,
    private utilityService: UtilityService,
    public restAPIService: RestApiService,
    private readonly momentService: MomentService,
  ) { }

  ngOnInit(): void {
    this.getConsultHistoryData();
  }

  displayedColumns: string[] = [
    'sr_number',
    'name',
    'frequency',
    'duration',
    'notes',
    'comments',
  ];

  getConsultHistoryData() {
    this.consultationHistoryService
      .getConsultationHistory(this.utilityService.getFromStorage('customerId'))
      .subscribe((res: ConsultatioHistory[]) => {
        this.consultHistory = res;
        if (this.consultHistory.length) {
          this.selectedHistory = this.consultHistory[this.currentPage][0];
          this.getConsultationData(this.selectedHistory.consultationId);
        }
      });
  }

  demo(consultHistorys) {
    this.selectedHistory = consultHistorys;
    this.getConsultationData(this.selectedHistory.consultationId);
  }

  getConsultationData(consultId) {
    const reqBody = {
      consultationId: consultId
    };

    this.restAPIService
      .invoke(APIEndPoint.GET_CONSULTAION_DATA, null, reqBody)
      .subscribe((res: any) => {
        if (Array.isArray(res.medicines) && res.medicines.length > 0) {
          res.medicines = res?.medicines.map((m) => {
            if (m && m.duration) {
              const foundDuration = durationConstants.find(
                (d) => +d.value === +m.duration
              );
              if (foundDuration) {
                m.duration = foundDuration.label;
              }
            }
            return m;
          });
        }
        if (res.dob) {
          res.dob = this.utilityService.getAge(res.dob);
        }
        if (res.callLandingDateTime) {
          res.callLandingDateTime = this.momentService
            ._moment(res.callLandingDateTime)
            .format('DD-MM-YYYY, h:mm:ss');
        }
        this.consultationDetails = res;
      });
  }

  getCommaSeparatedValues(val: string = '', key: string = ''): string {
    if (!val && !key && !this.consultationDetails) {
      return '';
    }
    return this.utilityService.getCommaSeparatedValues(
      this.consultationDetails[val]?.filter((c) => c[key] !== null),
      key
    );
  }

  isCommentPresent(key: string = ''): boolean {
    if (!key && !this.consultationDetails) {
      return false;
    }
    const isPresent: any[] = this.consultationDetails[key]?.filter(
      (c) => c?.comment !== null
    );
    if (isPresent.length === 0) {
      return null;
    }
    return true;
  }

  next() {
    if (this.currentPage < this.consultHistory.length - 1) {
      this.currentPage = this.currentPage + 1;
    }
    if (this.consultHistory.length) {
      this.selectedHistory = this.consultHistory[this.currentPage][0];
    }
  }

  previous() {
    if (this.currentPage > 0) {
      this.currentPage = this.currentPage - 1;
    }
    if (this.consultHistory.length) {
      this.selectedHistory = this.consultHistory[this.currentPage][0];
    }
  }
}
