import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { ToasterService } from 'angular2-toaster';
import { APIEndPoint } from 'src/app/core/https/ApiEndPoint';
import { RestApiService } from 'src/app/core/https/rest-api.service';
import { ConsultationLeftService } from 'src/app/core/services/consultation-left.service';
import { UserDetailsService } from 'src/app/core/services/userDetails.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { AddNewMember } from 'src/app/shared/models/addNewMember.model';
import { MomentService } from 'src/app/core/services/moment.service';
import { CustomValidatorService } from 'src/app/core/services/custom-validation.service';

export interface City {
  name: string;
}

@Component({
  selector: 'app-add-new-member',
  templateUrl: './add-new-member.component.html',
  styleUrls: ['./add-new-member.component.scss'],
})
export class AddNewMemberComponent implements OnInit {
  addNewMember: FormGroup;
  otpData = '';
  disabled = true;
  value = '';
  memberId: string;
  accessToken: string;
  cities: City[] = [];
  consultation: string;
  minDate: string = '';
  onsubmit = false;
  onOtp = false;
  today = new Date();
  futureDateError: boolean;

  constructor(
    public restAPIService: RestApiService,
    private consultationLeftSerivce: ConsultationLeftService,
    private formBuilder: FormBuilder,
    public toastrService: ToasterService,
    public userDetailsService: UserDetailsService,
    private utilityService: UtilityService,
    private readonly momentService: MomentService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.createAddNewMemberForm();
    this.dateAdapter.setLocale('en-GB');
    this.minDate = this.momentService.subtract(
      new Date(),
      150,
      'year',
      null,
      true
    );
  }

  ngOnInit(): void {}

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  getCity = () => {
    this.restAPIService
      .invoke(APIEndPoint.GET_CITY_NAME)
      .subscribe((res: any) => {
        this.cities = res;
      });
  };

  dispatch(input: HTMLInputElement) {
    input.dispatchEvent(new Event('input'));
  }

  createAddNewMemberForm = () => {
    this.addNewMember = this.formBuilder.group({
      firstName: [
        null,
        Validators.compose([
          Validators.required,
          this.noWhitespaceValidator,
          Validators.maxLength(50),
        ]),
      ],
      lastName: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      mobileNumber: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
        ]),
      ],
      // city: [null, Validators.required],
      dob: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          CustomValidatorService.dateValidator,
        ]),
      ],
      sex: [null, Validators.required],
      relation: [null, Validators.required],
      email: [
        null,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
    });
  };

  onSubmit() {
    this.disabled = false;
    this.onsubmit = true;
    const reqBody = this.addNewMember.getRawValue();
    reqBody.dob = reqBody?.dob?.includes('/')
      ? new Date(reqBody?.dob)
      : new Date(
          +reqBody?.dob.slice(4, 8),
          +reqBody?.dob.slice(2, 4) - 1,
          +reqBody?.dob.slice(0, 2),
          0,
          0
        );
    reqBody.firstName = reqBody?.firstName?.trim();
    reqBody.lastName = reqBody?.lastName?.trim();
    reqBody.email = reqBody?.email?.trim();
    const addNewMembers = new AddNewMember(reqBody);
    this.consultationLeftSerivce
      .saveFamilymember(addNewMembers)
      .subscribe((res) => {
        this.memberId = res.familyMemberID;
        this.accessToken = res.token;
      });
    this.addNewMember.controls['firstName'].disable();
    this.addNewMember.controls['lastName'].disable();
    this.addNewMember.controls['dob'].disable();
    this.addNewMember.controls['mobileNumber'].disable();
    this.addNewMember.controls['relation'].disable();
    this.addNewMember.controls['sex'].disable();
    this.addNewMember.controls['email'].disable();
  }

  onEdit() {
    this.addNewMember.controls['firstName'].enable();
    this.addNewMember.controls['lastName'].enable();
    this.addNewMember.controls['dob'].enable();
    this.addNewMember.controls['mobileNumber'].enable();
    this.addNewMember.controls['relation'].enable();
    this.addNewMember.controls['sex'].enable();
    this.addNewMember.controls['email'].enable();
    this.disabled = true;
    this.onOtp = true;
  }

  sendOTP = () => {};

  onOtpSubmission = (otpData: string) => {
    this.onOtp = true;
    this.restAPIService
      .invoke(
        APIEndPoint.VERIFY_ADDMEMBER_OTP,
        null,
        {
          otp: otpData,
          familyMemberId: this.memberId,
          type: 'add',
        },
        null,
        {
          Authorization: 'Bearer ' + this.accessToken,
        }
      )
      .subscribe(
        (res: any) => {
          if (res?.statusCode === 1) {
            this.disabled = true;
            this.otpData = '';
            this.addNewMember.reset();
            this.userDetailsService.setUserData('');
          } else {
            this.toastrService.pop('error', null, res?.message);
          }
        },
        (error) => {
          alert('this is Wrong OTP');
        }
      );
  };

  get f() {
    return this.addNewMember.controls;
  }

  public navigateToConsultPage(): void {
    this.utilityService.navigate('/consultation/consultation-details');
  }

  public preventSpace(event: any) {
    if (event?.target?.selectionStart === 0 && event?.code === 'Space') {
      event.preventDefault();
    }
  }
}
