import { Injectable } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { JwtHelperService } from "./jwt-helper.service";
import { AuthEnum } from "../models/auth-token";
import { map } from "rxjs/operators";
import { Dictionary } from "../models/dictionary";
import { RestApiService } from "../https/rest-api.service";
import { APIEndPoint } from "../https/ApiEndPoint";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentToken: string;
  private decodedToken: any;
  refreshToken;
  isAdminAuthorities: [];

  constructor(
    private jwt: JwtHelperService,
    private toastrService: ToasterService,
    private restAPIService: RestApiService /* , private jwtHelper: JwtHelperService */
  ) {
    this.setCurrentTokens(
      window.localStorage.getItem(AuthEnum.ACCESS_TOKEN),
      window.localStorage.getItem(AuthEnum.REFRESH_TOKEN)
    );
  }

  getAuthToken = () => {
    return this.currentToken;
  }

  getDecodedToken = () => {
    return this.decodedToken;
  }

  getAuthTokenByLocalStorage = () => {
    return window.localStorage.getItem(AuthEnum.ACCESS_TOKEN);
  }

  saveToken = token => {
    window.localStorage.setItem(AuthEnum.REFRESH_TOKEN, token);
    window.localStorage.setItem(AuthEnum.ACCESS_TOKEN, token);
  }

  deleteToken = () => {
    this.setCurrentTokens(null, null);
    this.toastrService.pop("success", null, Dictionary.LOGOUT_SUCCESS);
  }

  setCurrentTokens = (accessToken: string, refreshToken: string) => {
    this.currentToken = accessToken;
    this.refreshToken = refreshToken;
    if (this.currentToken) {
      this.decodedToken = this.jwt.decodeToken(this.currentToken);
      this.isAdminAuthorities = this.decodedToken.authorities;
      this.saveToken(this.currentToken);
    } else {
      this.decodedToken = null;
    }
  }

  loginAndSetTokens = () => {
    return this.restAPIService.invoke(APIEndPoint.LOGIN).pipe(
      map((data: any) => {
        this.saveToken(data);
        return data;
      })
    );
  }

  logoutUser = () => {
    this.deleteToken();
  }
}
