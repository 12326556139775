import { FormControl } from '@angular/forms';

import { MomentService } from './moment.service';

export class CustomValidatorService {
  public today = new Date();
  static outputDate: any;
  futureDateError: boolean;

  static dateValidator(control: FormControl): { invalidDate: boolean } {
    const _moment = MomentService.getMoment();
    let parsedDate = _moment(control.value, 'DD/MM/YYYY');
    let outputDate = parsedDate.format('DD/MM/YYYY');
    if (control?.value) {
      if (String(control?.value).length === 8 && outputDate !== 'Invalid date') {
        const targetDate = _moment(parsedDate, 'DD/MM/YYYY');
        const currentDate = _moment(
          _moment().format('DD/MM/YYYY'),
          'DD/MM/YYYY'
        );
        const difference: number = targetDate.diff(currentDate, 'days');
        if (difference > 0) {
          return { invalidDate: true };
        }
      } else if (control.value === '00000000') {
        return { invalidDate: true };
      }
    } else {
      return null;
    }
  }

  public static noWhitespaceValidator(control: FormControl): {
    whitespace: boolean;
  } {
    const isWhitespace: boolean = (control?.value || '')?.trim()?.length === 0;
    return !isWhitespace ? null : { whitespace: true };
  }
}
