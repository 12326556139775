import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { APIEndPoint } from '../https/ApiEndPoint';
import { RestApiService } from '../https/rest-api.service';

interface IMyCall {
  statusCode: number,
  message: "Success",
  callData: {
    key: string,
    userToCall: string,
    roomId: string,
    roomToken: string,
    roomName: string
  }
}
// interface IMyCall {
//   userToCall: string;
//   message: string;
//   roomId: string;
//   roomToken: string;
//   statusCode: number;
// }

@Injectable({
  providedIn: 'root',
})
export class DoctorCallService {
  constructor(private restAPIService: RestApiService) {}
  // TODO: subject to get all call data...
  message: string;

  private getAllCallDetailsSubject$: BehaviorSubject<IMyCall> =
    new BehaviorSubject(null);
  getCallData$: Observable<IMyCall> =
    this.getAllCallDetailsSubject$.asObservable();

  private checkMYCall$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  checkCall$: Observable<boolean> = this.checkMYCall$.asObservable();

  public online(): Observable<void> {
    return this.restAPIService.invoke(APIEndPoint.DOCTOR_ONLINE);
  }

  public offline(): Observable<void> {
    return this.restAPIService.invoke(APIEndPoint.DOCTOR_OFFLINE);
  }

  public myCall(): Observable<IMyCall> {
    return this.restAPIService.invoke<IMyCall>(APIEndPoint.MY_CALL, null, null);
  }
  public doctorPing(): Observable<IMyCall> {
    return this.restAPIService.invoke<IMyCall>(APIEndPoint.DOCTOR_PING, null, null);
  }
  public doctorActive(): Observable<IMyCall> {
    return this.restAPIService.invoke<IMyCall>(APIEndPoint.DOCTOR_ACTIVE, null, null);
  }

  getCallDetails(values: IMyCall): void {
    this.getAllCallDetailsSubject$.next(values);
  }
  checkcall(action: boolean): void {
    return this.checkMYCall$.next(action);
  }
  setMessage(data) {
    this.message = data;
  }

  getMessage() {
    return this.message;
  }
}
