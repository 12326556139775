import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-prescription',
  templateUrl: './send-prescription.component.html',
  styleUrls: ['./send-prescription.component.scss'],
})
export class SendPrescriptionComponent implements OnInit {
  completeCall: boolean = false;
  incompleteCall: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SendPrescriptionComponent>,
    private ngZone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {}
  callComplete() {
    this.completeCall = !this.completeCall;
    this.incompleteCall = false;
  }
  callInComplete() {
    this.incompleteCall = !this.incompleteCall;
    this.completeCall = false;
  }

  close() {
    this.dialogRef.close(true);
    // this.router.navigate(['/dashboard']);
  }
}
