import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConsultatioHistory } from 'src/app/shared/models/consultation-history.model';
import { APIEndPoint } from '../https/ApiEndPoint';
import { RestApiService } from '../https/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultationHistoryService {

  constructor(private restAPIService: RestApiService) { }

  getConsultationHistory(customerId) {
    return this.restAPIService.invoke(APIEndPoint.CONSULTATION_HISTORY, null, {
      customerId
    }).pipe(
      map((res: any) => {
        const consultationHistory = [];
        const list = res.consultList.map((elem, index) => new ConsultatioHistory({ ...elem, index: index + 1 }));
        const arrLength = Math.ceil(list.length / 5);
        for (let i = 0; i < arrLength; i++) {
          consultationHistory.push(list.splice(0, 5))
        }
        return consultationHistory;
      })
    );
  }
}
