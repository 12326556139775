import { SelectValue } from "./select-value.model";

export class Allergy {
    title = "Allergy";
    requestKey = 'allergy';
    path = 'addAllergyInList';
    addPath = 'addCustomerAllergy';
    key = "comment";
    name = 'allergy';
    selectedItems = [];
    data = [];
    constructor(allergy = null) {
        if (allergy) {
            this.data = allergy.map(elem => new SelectValue({
                id: elem.allergyId,
                name: elem.allergyName,
                comment: elem.comment
            }));
        }
    }
}