import { UtilityService } from 'src/app/core/services/utility.service';
import { v4 as uuidV4 } from 'uuid';
import { MomentService } from '../../core/services/moment.service';

export class DRange {
  id = 1;
  name = null;
  value = null;
  range = null;
  drange = null;
  isSelected = false;
  date = null;
  constructor(drange = null) {
    if (drange) {
      (this.id = uuidV4()), (this.name = drange.testName);
      this.value = drange.userTestValue;
      this.range = drange.testRange;
      this.drange = UtilityService.titleCase(drange.dRange);
    }
  }
}
