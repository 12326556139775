import { UtilityService } from "src/app/core/services/utility.service";

const GENDER = {
    male: 'M',
    female: 'F'
};

export class FamiltyDetails{
    id = 1;
    customerId =null;
    firstName = null;
    lastName = null;
    dob = null;
    gender = null;
    relationId = null;
    relation = null;
    imageUrl = null;

    constructor(familyDetails = null ) {

        if (familyDetails) {
            this.id = familyDetails.id;
            this.customerId = familyDetails.customerId || familyDetails.customerID;
            this.firstName = UtilityService.titleCase(familyDetails.firstName);
            this.lastName = UtilityService.titleCase(familyDetails.lastName);
            this.dob = UtilityService.calculateAge(UtilityService.epochToLocalDateConverter(familyDetails.dob));
            this.gender = familyDetails.gender ? GENDER [familyDetails.gender.toLowerCase()]:'-';
            this.relationId = familyDetails.familyMemberReferenceId
            this.relation =familyDetails.relation
            this.imageUrl = familyDetails.profilePicture || 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png' 
        }

    }
    
}

