import { SelectValue } from "./select-value.model";

export class SymptomsComplaints {
    title = "Symptoms Or Complains";
    requestKey = 'symptom';
    path = 'addSymptomInList';
    addPath = 'addCustomerSymptoms';
    key = "comment";
    name = 'symptoms';
    selectedItems = [];
    data = [];
    constructor(symptoms = null) {
        if (symptoms) {
            this.data = symptoms.map(elem => new SelectValue({
                id: elem.symptomsId,
                name: elem.symptomsName,
                comment: elem.comment
            }));
        }
    }
}