import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  private showLoader = new BehaviorSubject(false);

  constructor(private router: Router) {}

  static titleCase(value: string) {
    const arr = value.split(' ');
    for (const val in arr) {
      arr[val] =
        arr[val].substring(0, 1).toUpperCase() +
        arr[val].substring(1, arr[val].length).toLowerCase();
    }
    return arr.join(' ');
  }

  static epochToLocalDateConverter(epochTime: string) {
    return new Date(epochTime);
  }

  static localTimeToEpochDateConverter(date: string) {
    return Date.parse(date);
  }

  static epochTimeInDateString(date: Date) {
    return date?.toLocaleDateString();
  }

  static epochTimeInTimeString(date: Date) {
    return date?.toLocaleTimeString();
  }

  static calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970) + 'y';
  }

  saveToStorage(key: string, data: any, stringify = true) {
    if (stringify) {
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.setItem(key, data);
    }
  }

  getFromStorage(key: string, parse = true) {
    return parse
      ? JSON.parse(localStorage.getItem(key))
      : localStorage.getItem(key);
  }

  removeFromStorage(key: string): void {
    if (!key) {
      return;
    }
    window.localStorage.removeItem(key);
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  updateLoaderStatus(value: boolean) {
    this.showLoader.next(value);
  }

  get loaderStatus() {
    return this.showLoader.asObservable();
  }

  getCommaSeparatedValues(rows: any[] = [], key: string = ''): string {
    if (!rows || rows?.length === 0) {
      return '';
    }
    return rows?.map((item) => item[key])?.join(',') || '';
  }

  getAge(dateString: Date | string): number {
    if (!dateString) {
      return 0;
    }
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth() + (12 * (today.getFullYear() - birthDate.getFullYear()));
    let fAge:any = age + ' ' + 'Year'
    let fMonths:any =  months + ' ' + 'Month'
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }else if(months > 11){
     return fAge
    }else{
     return fMonths 
    }
  }

  groupBy(rows = [], key) {
    return rows?.reduce((acc, value) => {
      (acc[value[key]] = acc[value[key]] || []).push(value);
      return acc;
    }, {});
  }

  sortBy(arr: any[] = [], props: string[], orders: string[]): any[] {
    return [...arr].sort((a, b) =>
      props.reduce((acc, prop, i) => {
        if (acc === 0) {
          const [p1, p2] =
            orders && orders[i] === 'desc'
              ? [b[prop], a[prop]]
              : [a[prop], b[prop]];
          acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0;
        }
        return acc;
      }, 0)
    );
  }

  getFileExtension(path: string): string {
    const basename: string = path.split(/[\\/]/).pop();
    const pos: number = basename.lastIndexOf('.');
    if (basename === '' || pos < 1) return null;
    return basename.slice(pos + 1);
  }
}
