import { FormGroup, Validators } from '@angular/forms';

export class MedicinesData {
  breakfast = 0;
  comment = '';
  consultationId = '';
  defaultTiming = 0;
  dinner = 0;
  duration = 0;
  lunch = 0;
  medicineId = '';
  medicineIntakeInstruction = '';
  medQty = '';

  constructor(data = null) {
    if (data) {
    }
  }
}

export const createMedicineInstructionFormItem = formBuilder =>
  formBuilder.group({
    medQty: [null, Validators.required],
    medUnit: [null, Validators.required],
    defaultTimingFrom: formBuilder.group({
      medRepeatDay: ['', Validators.required],
      medTabletsPerDay: [''],
      medSchedule: ['', Validators.required],
    }),
    SwitchTimingFrom: formBuilder.group({
      breakfast: [0],
      lunch: [0],
      dinner: [0],
    }),
    duration: [null],
    comment: [null]
  });

export class MedicineUpdateReqBody {
  breakfast = 0;
  comment = '';
  dose = 0;
  consultationId = '';
  dinner = 0;
  duration = ''; // 0
  lunch = 0;
  medicineId = '';
  medQty = '';
  medRepeatDay = '';
  medSchedule = '';
  medTabletsPerDay = null;
  medUnit = 'tablets';
  constructor(data) {
    const { SwitchTimingFrom, defaultTimingFrom } = data;
    this.breakfast = SwitchTimingFrom.breakfast;
    this.medQty = data.medQty;
    this.dinner = SwitchTimingFrom.dinner;
    this.lunch = SwitchTimingFrom.lunch;
    this.comment = data.comment;
    this.medRepeatDay = defaultTimingFrom.medRepeatDay;
    this.medSchedule = defaultTimingFrom.medSchedule;
    this.medTabletsPerDay = defaultTimingFrom.medTabletsPerDay;
    this.dose = data.dosage;
    this.medicineId = data.medicineId;
    this.medUnit = data.medUnit;
    this.duration = data.duration;
    this.consultationId = data.consultationId;
  }
}
