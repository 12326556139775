import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';
import { UPDATE_API_MAPPER } from 'src/app/shared/consts/apiMapper';

@Component({
  selector: 'app-item-commentbox',
  templateUrl: './item-commentbox.component.html',
  styleUrls: ['./item-commentbox.component.scss'],
})
export class ItemCommentboxComponent implements OnInit {
  @Input() options = { consultationIndex: null, itemIndex: null };
  @Input() consultationData: any;
  @Output() saveCommentEvent = new EventEmitter();

  constructor(private utilityService: UtilityService) { }

  ngOnInit(): void { }

  get commentCondition() {
    if (
      this.consultationData?.selectedItems?.length &&
      this.consultationData?.selectedItems[this.options.itemIndex] &&
      Object.keys(this.consultationData?.selectedItems[this.options.itemIndex])
        .length
    ) {
      return true;
    }
    return false;
  }

  saveComment() {
    const selectedConsultation = UPDATE_API_MAPPER[this.consultationData.name];
    let payload: any = {
      comment:
        this.consultationData?.selectedItems[this.options.itemIndex].comment,
      consultationId: this.utilityService.getFromStorage('consultationId'),
      [selectedConsultation.keyName]: this.consultationData?.selectedItems[this.options.itemIndex]?.id,
      path: selectedConsultation.path
    };
    this.saveCommentEvent.emit(payload);
  }
}
