<div class="consultation_page_wrapper">
  <app-consultation-header [history]="testhistory"></app-consultation-header>
  <div fxLayout="row" class="container">
    <ng-container>
      <div fxFlex fxFlexFill class="grid-item">
        <app-consultation-left></app-consultation-left>
      </div>
    </ng-container>

    <ng-container>
      <div fxFlex="69" fxFlexFill class="grid-item" class="middle_container" >
        <router-outlet></router-outlet>
      </div>
    </ng-container>
  </div>
</div>
