<div class="call_notification_card" fxLayout="column" fxLayoutAlign="center center">

    <mat-card-title>
        <h3>Do you want to continue with <span>{{data?.selectedPatient?.firstName}} {{data?.selectedPatient?.lastName}}</span>
        </h3>
        <h3>Note : In case you proceed <span>{{data?.userDetails?.username}}</span> consultation data will be lost</h3>
    </mat-card-title>



    <mat-card-actions>
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <div class="call__icon" (click)="continue()">
                <button mat-raised-button color="warn">NO</button>
            </div>
            <div class="call__icon call_anwser" (click)="removeAllContinue()" fxLayout="row" fxLayoutAlign="space-around center">
                <button mat-raised-button class="basic_btn">YES</button>
            </div>
        </div>
    </mat-card-actions>
</div>