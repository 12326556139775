<div fxLayoutAlign="center center" class="caller_image_container" >
  <div class="caller_image" fxLayout="row" fxLayoutAlign="center center">
    <!-- <img src="assets/images1.jpg" alt="" /> -->
    <span *ngIf="userdetails.profileImage !== null"><img [src]="userdetails.profileImage" alt="profile_image" /></span>
    <span *ngIf="userdetails.profileImage == null"><img src="assets/images/default_profile_image.jpeg" alt="" /></span>
  </div>
</div>

<div
  class="call_notification_card"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <mat-card-title>{{userdetails.username}}</mat-card-title>
  <mat-card-subtitle fxLayoutAlign="center center">
    <mat-icon>videocam</mat-icon> Video Call
  </mat-card-subtitle>

  <mat-card-actions>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div class="call__icon" (click)="disconnectCall()">
        <img src="assets/vedio_calling_btns/end-call.svg" alt="" />
      </div>
      <div
        class="call__icon call_anwser"
        (click)="joinCall()"
        fxLayout="row"
        fxLayoutAlign="space-around center"
      >
        <mat-icon>call</mat-icon>
      </div>
    </div>
  </mat-card-actions>
</div>
