
export class VitalData {
    height = null;
    heightFt = null;
    heightInch = null;
    weight = 0;
    bmiValue = 0;
    bmiType = 'NA'
    smoking = 'No';
    alcohol = 'No';
    tobacco = 'No';
    crmVitalsComments = '';
    consultationId = '';
    customerId = '';
    date = null;

    constructor(data = null, saveData = false) {
        if (data) {
            if (!saveData) {
                const height = this.toFeet(+data.height);
                this.heightFt = height.feet;
                this.heightInch = height.inches;
                this.date = data.date ? new Date(data.date * 1000) : this.date;
            } else {
                this.height = data.heightFt * 30.48 + data.heightInch * 2.54;
                this.heightFt = data.heightFt;
                this.heightInch = data.heightInch;
                this.date = (new Date().getTime());
                this.consultationId = data.consultationId;
                this.customerId = data.customerId;
            }
            this.weight = data.weight ? data.weight : this.weight;
            this.bmiType = data?.bmiType || 'N/A'
            this.bmiValue = data.bmiValue || data.bmiValue === 0 ? +data.bmiValue : this.bmiValue;
            this.smoking = data.smoking ? data.smoking : this.smoking;
            this.alcohol = data.alcohol ? data.alcohol : this.alcohol;
            this.tobacco = data.tobacco ? data.tobacco : this.tobacco;
            this.crmVitalsComments = data.crmVitalsComments ? data.crmVitalsComments : this.crmVitalsComments;
        }
    }

    toFeet(height) {
        const realFeet = (height*0.0328084);
        const feet = Math.floor(realFeet);
        const inches = Math.round((realFeet - feet) * 10);
       
        return {
            feet,
            inches
        };
    }

    toCm(feet, inches) {
        const height = +`${feet}.${inches}`;
        const totalCm = +(height * 30.48).toFixed(2);
        return totalCm.toFixed(2);
    }
}

