import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { DRange } from 'src/app/shared/models/d-range.model';
import { APIEndPoint } from '../https/ApiEndPoint';
import { RestApiService } from '../https/rest-api.service';
import { MomentService } from './moment.service';

@Injectable({
  providedIn: 'root',
})
export class DRangeService {
  constructor(
    private restAPIService: RestApiService,
    private readonly momentService: MomentService
  ) {}

  getDRange(customerId) {
    return this.restAPIService
      .invoke(APIEndPoint.D_RANGE, null, {
        customerId,
      })
      .pipe(
        map((res: any) => {
          if (res.date && res.date.length > 0) {
            return res.date.map((d, idx) => ({
              ...d,
              id: idx,
              orderDate: d?.orderDateTime,
              reports: d?.reports?.map(
                (elem, index) => new DRange({ ...elem, index: index + 2 })
              ),
              date: this.momentService
                ._moment(d?.orderDateTime)
                .format('DD/MM/YYYY'),
            }));
          }
          return [];
        })
      );
  }
}
