import { SelectValue } from "./select-value.model";

export class ProvisionalDiagnosis {
    title = "Provisional Diagnosis";
    requestKey = 'diagnosis';
    path = 'addDiagnosisInList';
    key = "comment";
    name = 'provisional_diagnosis';
    selectedItems = [];
    data = [];

    constructor(provisionalDiagnosis = null) {
        if (provisionalDiagnosis) {
            this.data = provisionalDiagnosis.map(elem => new SelectValue({
                id: elem.diagnosisId,
                name: elem.diagnosisName,
                comment: elem.comment
            }));
        }
    }
}