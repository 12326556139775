import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddNewMemberComponent } from './components/consultation-left/add-new-member/add-new-member.component';
import { ConsultationHistoryComponent } from './components/consultation-header/consultation-history/consultation-history.component';
import { ConsultationMiddleComponent } from './components/consultation-middle/consultation-middle.component';
import { DRangeComponent } from './components/consultation-header/d-range/d-range.component';
import { AyushmanPackageReportComponent } from './components/consultation-header/test-history/ayushman-package-report/ayushman-package-report.component';
import { ConsultationComponent } from './consultation.component';
import { DocsUploadedByPatientComponent } from './components/consultation-header/docs-uploaded-by-patient/docs-uploaded-by-patient.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { AddNewFindingComponent } from './components/consultation-dailog-box/add-new-finding/add-new-finding.component';
import { TestHistoryComponent } from './components/consultation-header/test-history/test-history.component';

const CONSULTATION_ROUTE: Routes = [
  {
    path: 'consultation',
    component: ConsultationComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'consultation-details'
      },
      {
        path: 'consultation-details',
        component: ConsultationMiddleComponent
      },
      {
        path: 'addmemberform',
        component: AddNewMemberComponent
      },
      {
        path: 'drange',
        component: DRangeComponent
      },
      {
        path: 'testhistory',
        component: TestHistoryComponent
      },
      {
        path: 'packagereport',
        component: AyushmanPackageReportComponent
      },
      {
        path: 'consultationhistory',
        component: ConsultationHistoryComponent
      },
      {
        path: 'docs-uploaded-by-patient',
        component: DocsUploadedByPatientComponent
      },
      {
        path: 'dialog',
        component: AddNewFindingComponent
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(CONSULTATION_ROUTE)],
  exports: [RouterModule]
})
export class ConsultationRouteModule {}
