export class SummaryNotes {
    title = "Summary Notes";
    key = "notes";
    summaryNotes = null;
    customerId = null;
    consultationId = null;


    constructor(summaryNotes = null) {
        if (summaryNotes) {
            this.customerId = summaryNotes.customerId;
            this.consultationId = summaryNotes.consultationId;
            this.summaryNotes = summaryNotes.notes;
            this.title = undefined;
            this.key = undefined;
        }
    }
}
