import { SelectValue } from "./select-value.model";

export class MedicalHistory {
    title = "Medical History";
    requestKey = 'disease';
    path = 'addCustomerMedicalHistoryInList'
    key = "comment";
    name = 'medical_history';
    selectedItems = [];
    data = [];
    constructor(medicalHistory = null) {
        if (medicalHistory) {
            this.data = medicalHistory.map(elem => new SelectValue({
                id: elem.diseaseId,
                name: elem.diseaseName,
                comment: elem.comment
            }));
        }
    }
}