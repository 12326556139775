<section class="farmania_pdf" id="prescriptionPdf" #prescriptionPdf>
  <div style="text-align: end; margin-bottom: 10px">
    <mat-icon
      style="cursor: pointer; color: red"
      (click)="sendPrescriptionReport()"
      >exit_to_app</mat-icon
    >
  </div>
  <div class="farmania">
    <div class="top_banner_area">
      <div class="left_baner_area">
        <!-- <h3>Hindustan Wellness</h3> -->
        <img
          src="../../../../../../../assets/images/logo.svg"
          class="logo1_img"
          alt=""
        />
      </div>
      <div class="right_baner_area">
        <h4>
          Download "Hindustan Wellness" App <br />
          To Book A Test
        </h4>
        <!-- <p>(Turn overleaf to know more) </p> -->
      </div>
    </div>
  </div>

  <div class="card-1 card mt-5">
    <div class="top_head_flex">
      <div class="">
        <img
          src="../../../../../../../assets/images/2.svg"
          class="logo_img"
          alt=""
        />
      </div>
      <div class="text-wrapper">
        <div class="user-details text-right">
          <h4 class="main-text">Dr.{{ consultationDetails?.doctorName }}</h4>
          <!-- <p class="sub-text">{{ consultationDetails?.doctorDegree }}</p> -->
        </div>
      </div>
    </div>
  </div>
  <div class="farmania">
    <div class="card-2 row mt-4">
      <div class="top_head_flex doc_profile">
        <div>
          <p><b>Name : </b> {{ consultationDetails?.userName }}</p>
          <p>
            <b>Age\ Sex: </b>{{ consultationDetails?.dob }} \{{
              consultationDetails?.gender
            }}
          </p>
          <p>
            <b>Vitals: </b> Height {{ consultationDetails?.height }} cm, Weight
            {{ consultationDetails?.weight }} kg, BMI Value
            {{ consultationDetails?.bmiValue }}
            <ng-container *ngIf="consultationDetails?.cmrUserComments">
              ({{ consultationDetails?.cmrUserComments }})
            </ng-container>
          </p>
        </div>
        <div>
          <p>
            <b>Consultation Date : </b>
            {{ consultationDetails?.callLandingDateTime }}
          </p>
          <p><b>Mobile: </b> {{ consultationDetails?.mobileNumber }}</p>
          <p>
            <b>Consultation Mode:</b>{{ consultationDetails?.consultationMode }}
          </p>
        </div>
      </div>
    </div>
    <div class="row card-3 span_text">
      <p><b>Medical History : </b></p>
      <div *ngIf="consultationDetails?.medicalHistory?.length">
        <span
          >{{ getCommaSeparatedValues("medicalHistory", "diseaseName") }}
          <span *ngIf="isCommentPresent('medicalHistory')">
            ({{ getCommaSeparatedValues("medicalHistory", "comment") }})
          </span> </span
        >&nbsp;
      </div>
      <p style="margin-top: 15px"><b>Provisional Diagnosis : </b></p>
      <div *ngIf="consultationDetails?.provisionalDiagnosis?.length">
        <p>
          {{ getCommaSeparatedValues("provisionalDiagnosis", "diagnosisName") }}
          <span *ngIf="isCommentPresent('provisionalDiagnosis')">
            ({{ getCommaSeparatedValues("provisionalDiagnosis", "comment") }})
          </span>
        </p>
        &nbsp;
      </div>
      <p><b>Symptoms : </b></p>
      <div *ngIf="consultationDetails?.symptoms?.length">
        <p>
          {{ getCommaSeparatedValues("symptoms", "symptomsName") }}
          <span *ngIf="isCommentPresent('symptoms')">
            ({{ getCommaSeparatedValues("symptoms", "comment") }})
          </span>
        </p>
        &nbsp;
      </div>
      <p><b>Allergy : </b></p>
      <div *ngIf="consultationDetails?.allergy?.length">
        <p>
          {{ getCommaSeparatedValues("allergy", "allergyName") }}
          <span *ngIf="isCommentPresent('allergy')">
            ({{ getCommaSeparatedValues("allergy", "comment") }})
          </span>
        </p>
        &nbsp;
      </div>
      <p><b> Prescribed Medicines </b></p>
    </div>
    <div>
      <table
        mat-table
        [dataSource]="consultationDetails?.medicines"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="sr_number">
          <th mat-header-cell *matHeaderCellDef class="p-5">Rx.</th>
          <td mat-cell *matCellDef="let element; let i = index" class="pl-5">
            {{ i + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="p-5">Name</th>
          <td mat-cell *matCellDef="let element" class="pl-5">
            <span class="text-bold">{{ element?.medicineName }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef class="p-5">Frequency</th>
          <td mat-cell *matCellDef="let element" class="pl-5">
            <span *ngIf="element?.medQty !== 'na' ">
            {{ element?.dose }}: {{ element?.medQty }} - {{element?.medUnit}}
            <!-- <span *ngIf="element?.medUnit !== 'unit'">- {{element?.medUnit}}</span> -->
            <ng-container *ngIf="element?.medRepeatDay || element?.medSchedule">
              , {{ element?.medRepeatDay }}
              {{ element?.medRepeatDay && element?.medSchedule && "," }}
              {{ element?.medSchedule }}
            </ng-container>
          </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef class="p-5">Duration</th>
          <td mat-cell *matCellDef="let element" class="pl-5">
            <span *ngIf="element?.medQty !== 'na'">
            <span *ngIf="element?.duration > '0'">{{ element?.duration }}</span>
            <span *ngIf="element?.duration == '0'">If required</span>
          </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef class="p-5">Notes</th>
          <td mat-cell *matCellDef="let element" class="pl-5">
            <span *ngIf="element?.medQty !== 'na' ">
            <ng-container *ngIf="element?.breakfast"
              ><p>Breakfast: {{ element?.breakfast }}</p></ng-container
            >
            <ng-container *ngIf="element?.lunch"
              ><p>Lunch: {{ element?.lunch }}</p></ng-container
            >
            <ng-container *ngIf="element?.dinner"
              ><p>Dinner: {{ element?.dinner }}</p></ng-container
            >
          </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef class="p-5">Comments</th>
          <td mat-cell *matCellDef="let element">{{ element?.comment }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <p class="instructions"><b>Instructions : </b></p>
      <div>
        <ul
          class="inhalation"
          *ngFor="let instruction of consultationDetails?.instructions"
        >
          <li class="">{{ instruction?.instructions }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="recommendations">
    <p><b>Test Recommendations.</b></p>
    <ul
      class="test-fonts"
      *ngFor="
        let test of consultationDetails?.testRecommendations;
        let i = index
      "
    >
      <p>
        {{ i + 1 }}. {{ test?.testName }}
        <ng-container *ngIf="test?.comments"
          >({{ test?.comments }})</ng-container
        >
      </p>
    </ul>
  </div>
  <div class="summary">
    <p><b>Summary Notes : </b>{{ consultationDetails?.summaryNotes }}</p>
  </div>
  <div class="summary">
    <p><b>Doctor Notes : </b>{{ consultationDetails?.doctorNotes }}</p>
  </div>
  <div class="signature">
    <img
      [src]="consultationDetails?.doctorSignature"
      style="width: 100px"
      alt=""
    />
    <h4 class="signature-text">Dr.{{ consultationDetails?.doctorName }}</h4>
  </div>
  <!-- <p class="text_center">
    <b>Disclaimer Notes : </b> Medical decided accented on the internet or ove
    the phone at the customers on
  </p> -->
  <p class="text_center">
    <b>Disclaimer Notes : </b> Medical advice asked and accepted on the internet or over the phone is at the customer's own risk.
  </p>
</section>
