<ng-container
  *ngIf="options.consultationIndex || options.consultationIndex === 0"
>
  <mat-card
    fxLayoutAlign="start center"
    *ngIf="
      !consultationData?.selectedItems?.length &&
      (selectedConsultation === 'comment' ||
        selectedConsultation === 'medicines' ||
        selectedConsultation === 'instruction')
    "
  >
    <mat-card-title>
      Select an item on left to show the options
    </mat-card-title>
  </mat-card>

  <div class="right_container" *ngIf="consultationData?.selectedItems?.length">
    <!-- Medicines -->
    <app-medicine-instruction
      [options]="options"
      [(consultationData)]="consultationData"
      (updateMedicineEvent)="updateMedicineHandler($event)"
      [instructionForm]="instructionForm"
      *ngIf="selectedConsultation === 'medicines' && options.itemIndex !== null"
    ></app-medicine-instruction>
    <!-- Comment Box -->
    <app-item-commentbox
      (saveCommentEvent)="saveComment($event)"
      [options]="options"
      [(consultationData)]="consultationData"
      *ngIf="selectedConsultation === 'comment'"
    ></app-item-commentbox>

    <!-- Vitals component -->
  </div>
  <div class="right_container" *ngIf="selectedConsultation === 'vitals'">
    <app-vitals
      [options]="options"
      [(consultationData)]="consultationData"
      (saveVitalsEvent)="saveVitals($event)"
    >
    </app-vitals>
  </div>

  <div class="right_container" *ngIf="selectedConsultation === 'notes'">
    <app-notes-comment
      [(consultationData)]="consultationData"
      (saveNotesEvent)="saveNotes($event)"
    >
    </app-notes-comment>
  </div>
</ng-container>
