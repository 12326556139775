import { UtilityService } from 'src/app/core/services/utility.service';

const GENDER = {
  male: 'M',
  female: 'F',
};

export class AddNewMember {
  id = 1;
  firstName = null;
  lastName = null;
  dob = null;
  gender = null;
  relation = null;
  city = null;
  email = null;
  mobileNumber = null;
  customerId = null;

  constructor(familyDetails = null) {
    if (familyDetails) {
      this.id = familyDetails.id;
      this.firstName = UtilityService.titleCase(familyDetails.firstName);
      this.lastName = UtilityService.titleCase(familyDetails.lastName);
      this.dob = UtilityService.localTimeToEpochDateConverter(familyDetails.dob);
      this.gender = familyDetails.sex;
      this.city = familyDetails.city || '';
      this.relation = familyDetails.relation;
      this.email = familyDetails.email;
      this.mobileNumber = familyDetails.mobileNumber;
      this.customerId = familyDetails.parentCustomerId;
    }
  }
}
