import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MiddleService } from 'src/app/core/services/middle.service';
import { CallDisposeComponent } from '../call-dispose/call-dispose.component';

@Component({
  selector: 'app-add-new-finding',
  templateUrl: './add-new-finding.component.html',
  styleUrls: ['./add-new-finding.component.scss']
})
export class AddNewFindingComponent implements OnInit {

  categoryForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CallDisposeComponent>,
    private middleService: MiddleService, private fb: FormBuilder) {
  }


  ngOnInit(): void {
    this.createForm(this.data.requestKey);
  }

  createForm(key: string) {
    this.categoryForm = this.fb.group({
      [key]: [this.data.searchText, [Validators.required, Validators.minLength(2)]]
    });
  }

  save() {
    if (this.categoryForm.valid) {
      this.middleService.saveCategory(this.categoryForm.value, this.data.path)
        .subscribe(res => {
          this.dialogRef.close({ key: this.data.requestKey });
        });
    } else {
      this.categoryForm.get(this.data.requestKey).markAsTouched();
    }
  }

  close() {
    this.dialogRef.close(true);
  }
}
