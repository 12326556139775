import { SelectValue } from "./select-value.model";

export class Medicines {
    title = "Medicines";
    name = 'medicines';
    requestKey = 'medicines';
    path = 'addMedicinesInList'
    key = "medicines";
    selectedItems = [];
    data = [];
    constructor(medicines = null) {
        if (medicines) {
            this.data = medicines.map(elem => new SelectValue({
                id: elem.medicineId,
                name: elem.medicineName,
                comment: elem.comment
            }));
        }
    }
}