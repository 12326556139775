<div fxFlex fxFlexFill class="grid-item" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start start"
  class="left_bars">
  <mat-card class="vital_heading" fxLayoutAlign="start center">
    <mat-card-title> Vitals </mat-card-title>

  </mat-card>

  <mat-card class="vital_content">
    <form *ngIf="vitialForm" [formGroup]="vitialForm">
      <div class="input_title">
        <mat-card-title for="">Height</mat-card-title>
        <div class="input_container">
          <div fxFlex fxFlexFill fxLayout="row">
            <mat-form-field appearance="outline">
              <input id="vital_input_1" matInput (keyup.enter)="keytab('vital_input_2')" type="number" class="form_group" maxlength="1" (blur)="calculateBMI()" type="number"  formControlName="heightFt"  />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <input id="vital_input_2" matInput (keyup.enter)="keytab('vital_input_3')"  formControlName="heightInch"  (blur)="calculateBMI()" type="number" class="form_group" />
            </mat-form-field>
          </div>
        </div>

        <mat-card-title for="">Weight</mat-card-title>
        <div class="input_container">
          <div fxFlex fxFlexFill fxLayout="row">
            <mat-form-field appearance="outline">
              <input id="vital_input_3" (keyup.enter)="keytab('vital_input_7')" matInput placeholder="85" fxFlex="50" fxLayoutAlign="end center" fxLayout="row" type="number"
                class="form_group" class="weight_input" (blur)="calculateBMI()" formControlName="weight" />
              <span fxFlex="32" class="kgSymbole" fxLayoutAlign="start center">kg</span>
            </mat-form-field>
          </div>
        </div>

        <div>
          <p></p>
        </div>
      </div>

      <!-- BMI calculation -->
      <app-bmi-calculator [bmiValue]="bmiValue"></app-bmi-calculator>
      <!-- BMI calculation End -->

      <div class="bad_habits">
        <div class="radio_button">
          <mat-card-title for="">Smoking</mat-card-title>
          <mat-radio-group id="vital_input_4" aria-label="Select an option" (change)="saveVitals()" formControlName="smoking">
            <mat-radio-button value="Yes">Yes</mat-radio-button>
            <mat-radio-button value="No">No</mat-radio-button>
            <mat-radio-button value="Na">NA</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="radio_button">
          <mat-card-title for="">Alcohol</mat-card-title>
          <mat-radio-group id="vital_input_5" aria-label="Select an option" (change)="saveVitals()" formControlName="alcohol">
            <mat-radio-button value="Yes">Yes</mat-radio-button>
            <mat-radio-button value="No">No</mat-radio-button>
            <mat-radio-button value="Na">NA</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="radio_button">
          <mat-card-title for="">Tobacco</mat-card-title>
          <mat-radio-group id="vital_input_6" aria-label="Select an option" (change)="saveVitals()" formControlName="tobacco">
            <mat-radio-button value="Yes">Yes</mat-radio-button>
            <mat-radio-button value="No">No</mat-radio-button>
            <mat-radio-button value="Na">NA</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="comment_box">
        <div fxLayout="column" class="comment_box_container" fxFlex.lg="100">
          <div>
            <mat-card-title for="">Other</mat-card-title>
          </div>
          <div fxLayoutAlign="space-between center" class="textarea_container">
            <textarea id="vital_input_7" type="text" class="form_group" (blur)="saveVitals()" rows="10" placeholder="Enter Comment Here..."
              formControlName="crmVitalsComments"></textarea>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>