<mat-card  fxLayout="column">
  <mat-card-header fxFlex="100" fxLayoutAlign="space-between center" >
    <mat-card-title class="title">Add a new Finding</mat-card-title>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <form (ngSubmit)="save()" [formGroup]="categoryForm" fxLayout="column">
    <div class="form_container"  fxLayout="row" fxFlex="100" fxLayoutAlign="start center" >
      <p class="form_text" fxFlex="18">*Name:</p>
      <div class="add_new_data" fxFlex="60">
        <mat-form-field appearance="outline" class="custom-input" fxFlex="100" >
          <input matInput type="text" [formControlName]="this.data.requestKey" placeholder="Name" />
        </mat-form-field>
      </div>
    </div>

    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" >
      <div fxFlex="23"></div>
      <div fxLayoutAlign="start center" fxFlex="50">
        <button mat-raised-button [disabled]="categoryForm.invalid" color="primary" type="submit" class="btn btn-submit" fxFlex="35">Save</button>
        <button mat-stroked-button color="secondary" type="button" (click)="close()" class="btn btn-close" fxFlex="35">Close</button>
      </div>
    </div>
  </form>
</mat-card>
