import { UtilityService } from "src/app/core/services/utility.service";

const GENDER = {
    male: 'M',
    female: 'F'
};

export class UserDetails {
    username = null;
    profileImage = null;
    gender = null;
    age = null;

    constructor(userDetails = null) {
        if (userDetails) {
            this.username = userDetails.username;
            this.profileImage = userDetails.profileImage;
            this.gender = userDetails.gender ? GENDER[userDetails.gender.toLowerCase()] : '-';
            this.age = UtilityService.calculateAge(UtilityService.epochToLocalDateConverter(userDetails.dob));
        }
    }
}