import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CallHistory } from 'src/app/shared/models/call-history.model';

import { DoctorDetails } from 'src/app/shared/models/doctor-details.model';
import { APIEndPoint } from '../https/ApiEndPoint';
import { RestApiService } from '../https/rest-api.service';
import { ExportService, ExcelJson } from './export.service';
import { MomentService } from './moment.service';

export type ISort = 'desc' | 'asc';

@Injectable({
  providedIn: 'root',
})
export class DoctorDetailsService {
  private datePickerDateChange$: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  datePickerDate$: Observable<any> = this.datePickerDateChange$.asObservable();

  private showErrorChange$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  showError$: Observable<boolean> = this.showErrorChange$.asObservable();

  private callDisconnectedSubject$: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  callDisconnected$: Observable<any> =
    this.callDisconnectedSubject$.asObservable();

  private _sortDateChange$: BehaviorSubject<ISort> = new BehaviorSubject(
    'desc'
  );
  sortDateChange$: Observable<ISort> = this._sortDateChange$.asObservable();
  doctorData: CallHistory[] = [];
  constructor(
    private readonly restAPIService: RestApiService,
    private readonly momentService: MomentService,
    private readonly exportService: ExportService
  ) {}

  getDoctorDetails() {
    return this.restAPIService
      .invoke(APIEndPoint.DOCTOR_DETAILS, null, {})
      .pipe(map((res) => new DoctorDetails(res)));
  }

  setDataRangeChange(values: any): void {
    this.datePickerDateChange$.next(values);
  }

  showErrorMessage(action: boolean): void {
    this.showErrorChange$.next(action);
  }

  callDisconnect(values: any): void {
    this.callDisconnectedSubject$.next(values);
  }

  sortChange(change: ISort): void {
    this._sortDateChange$.next(change);
  }

  get getInitialStartEndDate(): {
    startDate: number;
    endDate: number;
  } {
    const payload = {
      startDate: this.momentService
        ._moment()
        .subtract(1, 'months')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate()
        .getTime() as number,
      endDate: this.momentService._moment().toDate().getTime() as number,
    };
    return payload;
  }

  exportToCsv(data = []) {
    return this.exportService.exportToCsv(data, 'doctor-data', [
      'Date',
      'Assign call',
      'Received',
      'Disconnected',
      'User',
      'Doctor',
      'Network',
      'Prescription sent',
      'Avg Talk Time',
      'Avg Idle',
    ]);
  }

  exportToExcel(data = []): void {
    if (!data || data?.length === 0) {
      return;
    }
    const excelData: Array<ExcelJson> = [];
    const udt: ExcelJson = {
      data: [
        { A: 'Doctor Data' }, // title
        {
          // A: '#',
          B: 'Date',
          C: 'Assign call',
          D: 'Received',
          E: 'Disconnected',
          F: 'User',
          G: 'Doctor',
          H: 'Network',
          I: 'Prescription sent',
          J: 'Avg Talk Time',
          K: 'Avg Idle',
        },
        // table header
      ],
      skipHeader: true,
    };
    data.forEach((user, idx) => {
      udt.data.push({
        // A: idx,
        B: user.date,
        C: user.assignCall,
        D: user.receivedCall,
        E: user.disconnectedCalls,
        F: user.user,
        G: user.doctor,
        H: user.network,
        I: user.prescription,
        J: user.avgTalkTime,
        K: user.avgIdle,
      });
    });
    excelData.push(udt);
    this.exportService.exportJsonToExcel(excelData, 'doctor-data');
  }
}
