import { SelectValue } from "./select-value.model";

export class Instructions {
    title = "Instructions";
    requestKey = 'instruction';
    path = 'addInstructionInList'
    key = "instruction";
    name = 'instruction';
    selectedItems = [];
    data = [];
    constructor(instruction = null) {
        if (instruction) {
            this.data = instruction.map(elem => new SelectValue({
                id: elem.instructionId,
                name: elem.instructionName,
                comment: elem.comment
            }));
        }
    }
}