export interface AuthToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

export enum AuthEnum {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
}
