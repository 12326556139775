<button class="closeDialog" (click)="onNoClick()">X</button>
<div
  class="call_dispose_container"
  fxLayout="column"
  fxLayoutAlign="space-around center"
  
>
  <mat-card class="call_title" fxLayoutAlign="center center">
    <h1 mat-dialog-title >Call Dispose</h1>
  </mat-card>
  <div mat-dialog-content>
    <div
      mat-dialog-actions
      class="mat-dialog-actions"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <!-- <button
        mat-raised-button
        [ngClass]="{ 'call-complete-btn': completeCall || !incompleteCall }"
        (click)="completeCallStatus(1)"
      >
        Call Complete
      </button> -->
      <button [disabled]="clicked" mat-raised-button (click)="callComp()" class="callbtn" >Call Complete</button>
      
      <button mat-raised-button (click)="callInComplete(2); clicked = true; " 
      [ngClass]="{ 'call-complete-btn': incompleteCall }">
        Call Incomplete
      </button>
      <!--   [disabled]="isPrescriptionSent" --> 
      <!-- <button
      class="closeButton"
        mat-raised-button
        (click)="onNoClick()"
      >
        Close
      </button> -->
    </div>

    <!-- <div class="complete_call_container" *ngIf="completeCall">
      <div class="complete_call_message"></div>
      <h1>Please complete and send prescription!</h1>

      <button
        mat-raised-button
        color="primary"
        class="ok_btn"
        (click)="close()"
      >
        ok
      </button>
    </div> -->

    <div class="incomplete_call_container" *ngIf="incompleteCall">
      <div class="incomplete_call_message">
        <h1>What is the issue ?</h1>

        <mat-radio-group aria-labelledby="Select an option">
          <mat-radio-button
            class="example-radio-button"
            value="Dispose Doctor"
            [checked]="incompleteCallReason === 'Dispose Doctor'"
            (change)="changeSelection('Dispose Doctor')"
            >Dispose Doctor</mat-radio-button
          >
          <mat-radio-button
            class="example-radio-button"
            value="Dispose User"
            (change)="changeSelection('Dispose User')"
            >Dispose User</mat-radio-button
          >
          <mat-radio-button
            class="example-radio-button"
            value="Dispose Network"
            (change)="changeSelection('Dispose Network')"
            >Dispose Network</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <button
        mat-raised-button
        color="primary"
        class="submit_btn"
        (click)="close()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
