import { UtilityService } from "src/app/core/services/utility.service";

export class ConsultatioHistory {
    id = 1;
    date = null;
    time = null;
    mode = null;
    consultationId = null;
    talkTime = null;
    doctorNotes = null;
    

    constructor(consultationhistory = null) {
        if (consultationhistory) {
            this.id = consultationhistory.index;
            this.date = consultationhistory.callLandingDateTime;
            // this.date = UtilityService.epochTimeInDateString(UtilityService.epochToLocalDateConverter(consultationhistory.callLandingDateTime));
            this.time = UtilityService.epochTimeInTimeString(UtilityService.epochToLocalDateConverter(consultationhistory.callLandingDateTime));
            this.mode = consultationhistory.consultationMode;
            this.consultationId = consultationhistory.consultationId;
            this.talkTime = consultationhistory.talkTime;
            this.doctorNotes = consultationhistory.doctorNotes;
            this.consultationId = consultationhistory.consultationId;
        }
    }

}




// "consultList": [
//     {
//       "callLandingDateTime": 0,
//       "consultationMode": "string",
//       "prescriptionPdfPath": "string",
//       "talkTime": 0
//     }