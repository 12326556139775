import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TestHistory } from 'src/app/shared/models/test-history.model';
import { APIEndPoint } from '../https/ApiEndPoint';
import { RestApiService } from '../https/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class TestHistoryService {

  constructor(private restAPIService: RestApiService) { }

  getTestHistory(customerID) {
    return this.restAPIService.invoke(APIEndPoint.TEST_HISTORY, null, {
      customerID
    }).pipe(
      map((res: any) => {
        return res.items.map((elem, index) => new TestHistory({ ...elem, index: index + 1 }))
      })
    );
  }

}
