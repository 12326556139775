import { Injectable } from '@angular/core';
import { DocsUploadedByPatient } from 'src/app/shared/models/docsUploadedByPatient.model';
import { APIEndPoint } from '../https/ApiEndPoint';
import { RestApiService } from '../https/rest-api.service';
import { UtilityService } from './utility.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocsUploadedByPatientService {
  constructor(
    private restAPIService: RestApiService,
    private utilityService: UtilityService
  ) {}

  getDocsUploadedByPatient(body) {
    return this.restAPIService
      .invoke(APIEndPoint.DOCS_UPLOADED_BY_PATIENT, null, {
        customerId: this.utilityService.getFromStorage('customerId'),
        // consultationId: this.utilityService.getFromStorage('consultationId'),
      })
      .pipe(
        map((res: any) => {
          return res.docsList.map((elem) => {
            elem['extention'] = this.utilityService.getFileExtension(
              elem?.imageUrl
            );
            return new DocsUploadedByPatient({ ...elem });
          });
        })
      );
  }
}
