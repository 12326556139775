import { Injectable } from '@angular/core';
import {
  LocalTrack,
  LocalVideoTrack,
  LocalAudioTrack,
  Participant,
} from 'twilio-video';

@Injectable({ providedIn: 'root' })
export class VideoChartService {
  constructor() {}

  public detachLocalTrack(track: LocalTrack): void {
    if (this.isDetachable(track)) {
      track.detach().forEach((el) => el.remove());
    }
  }

  public isDetachable(
    track: LocalTrack
  ): track is LocalAudioTrack | LocalVideoTrack {
    return (
      !!track &&
      ((track as LocalAudioTrack).detach !== undefined ||
        (track as LocalVideoTrack).detach !== undefined)
    );
  }

  public getTwilioOptions(name: string) {
    const options: any = {
      name,
      audio: true,
      video: { height: 720, frameRate: 24, width: 1280 },
      bandwidthProfile: {
        video: {
          mode: 'collaboration',
          renderDimensions: {
            high: { height: 1080, width: 1980 },
            standard: { height: 720, width: 1280 },
            low: { height: 176, width: 144 },
          },
        },
      },
    };
    return { options };
  }

  public getTracks(participant: Participant) {
    return Array.from(participant.tracks.values())
      .filter((publication: any) => {
        return publication.track;
      })
      .map((publication: any) => {
        return publication.track;
      });
  }

  public attachTrack(
    track: LocalAudioTrack | LocalVideoTrack,
    container: HTMLElement
  ): void {
    const trackContainer = document.createElement('div');
    trackContainer.className = 'participant-video-list';
    trackContainer.appendChild(track.attach());
    container.appendChild(trackContainer);
  }
}
