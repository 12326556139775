import {
  OnDestroy,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  Room,
  connect,
  Participant,
  RemoteTrack,
  LocalVideoTrack,
  LocalAudioTrack,
  RemoteParticipant,
  LocalTrackPublication,
  RemoteTrackPublication,
  LocalAudioTrackPublication,
} from 'twilio-video';
import { MatDialog } from '@angular/material/dialog';

import { FamiltyDetails } from 'src/app/shared/models/familyDetails.model';
import { VideoExpendService } from 'src/app/core/services/video-expend.service';
import { RestApiService } from 'src/app/core/https/rest-api.service';
import { ConsultationLeftService } from 'src/app/core/services/consultation-left.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ChangeUserDetailsComponent } from '../consultation-dailog-box/change-user-details/change-user-details.component';
import { UserDetailsService } from 'src/app/core/services/userDetails.service';
import { VideoChartService } from 'src/app/core/services/video-chat.service';
import { DoctorCallService } from 'src/app/core/services/doctor-call.service';
import { StorageEnum } from 'src/app/core/auth/jwt-helper.service';

@Component({
  selector: 'app-consultation-left',
  templateUrl: './consultation-left.component.html',
  styleUrls: ['./consultation-left.component.scss'],
})
export class ConsultationLeftComponent implements OnInit, OnDestroy {
  panelOpenState: boolean = false;
  patients: FamiltyDetails[] = [];
  micOnSwitch: boolean = true;
  micOffSwitch: boolean = false;
  expandIcon: boolean = true;
  collapseIcon: boolean = false;
  expand: any;
  isVideoMute: boolean = false;
  isAudioMute: boolean = false;
  searchText: any;
  doctorNotes: string = '';
  userDetails: any;
  newConsultationID: any;
  MemberId: string = '';
  value = '';
  isTouched: boolean = false;
  selectedUser: boolean = false;
  @ViewChild('localMedia') localMedia: ElementRef<HTMLDivElement>;
  @ViewChild('remoteMedia') remoteMedia: ElementRef<HTMLDivElement>;
  isInitializing: boolean = true;
  videoTrack: LocalVideoTrack = null;
  activeRoom: Room = null;
  isPreviewing: boolean = false;
  roomParticipants: Map<string, RemoteParticipant> = null;
  twilioRoomName: string = '';
  twilioJwtToken: string = '';
  constructor(
    public videoExpanedService: VideoExpendService,
    public restAPIService: RestApiService,
    private consultationLeftSerivce: ConsultationLeftService,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
    private readonly renderer: Renderer2,
    private readonly videoChartService: VideoChartService,
    private readonly doctorCallService: DoctorCallService
  ) {}

  // @HostListener('window:beforeunload', ['$event']) onCallDisconnect(
  //   event: Event
  // ) {
  //   let result = confirm('Changes you made may not be saved.');
  //   if (result) {
  //     this.disconnectRoom();
  //   }
  //   event.returnValue = false; // stay on same page
  // }

  // @HostListener('window:popstate', ['$event']) onBackPress(event) {
  //   const result = confirm('Changes you made may not be saved.');
  //   if (result) {
  //     this.disconnectRoom();
  //   }
  //   event.preventDefault();
  // }

  onSwitch(): void {
    this.micOnSwitch = !this.micOnSwitch;
    this.micOffSwitch = !this.micOffSwitch;
  }

  ngOnInit(): void {
    this.videoExpanedService.videoExpended.subscribe((res) => {
      this.expand = res;
    });
    this.userDetailService.userChange.subscribe((res) => {
      this.getFamilyList();
      this.getDoctorNotes();
      this.getUserDetails();
    });

    this.connectVideoCall();

    // this.doctorCallService.getCallData$.subscribe((myCall) =>
    //   this.connectVideoCall(myCall)
    // );
  }

  private connectVideoCall() {
   
    this.twilioJwtToken = localStorage.getItem('roomToken').replace("\"", "");
      this.twilioRoomName = localStorage.getItem('roomId').replace("\"", "");
      // console.log('save token',this.twilioJwtToken, );
      // console.log("Custome data 1234");
      // console.log('save id',this.twilioRoomName,  );
      this.twilioConnection(); 
    
    // if (myCall && myCall?.callData.roomToken && myCall?.callData.roomId) {
    //   this.twilioJwtToken = localStorage.getItem('roomToken');
    //   this.twilioRoomName = localStorage.getItem('roomId');
    //   console.log('save token',this.twilioJwtToken, );
    //   console.log('save id',this.twilioRoomName,  );
      
    //   this.twilioConnection();
    // } else {
    //   this.getMyCall();
    // }
  }

  // private getMyCall(): void {
  //   this.doctorCallService.myCall().subscribe((myCall) => {
  //     if (myCall?.statusCode == 1) {
  //       this.connectVideoCall(myCall);
  //     }
  //   });
  // }

  private twilioConnection(): void {
    const { options } = this.videoChartService.getTwilioOptions(
      this.twilioRoomName
    );

    connect(this.twilioJwtToken, options).then(
      (room: Room) => {
        this.activeRoom = room;
        if (!this.isPreviewing && options['video']) {
          this.startLocalVideo();
          this.isPreviewing = true;
        }
        this.roomParticipants = room.participants;
        this.registerRoomEvents();
      },
      (error) => {
        alert(error?.message);
      }
    );
  }

  private registerRoomEvents(): void {
    this.activeRoom.participants.forEach((participant) => {
      this.attachParticipantTracks(participant);
    });

    this.activeRoom.on('participantDisconnected', (participant) => {
      this.detachTracks(participant);
      this.disconnectRoom();
      alert('Participant Disconnected');
    });

    this.activeRoom.on('participantConnected', (participant: Participant) => {
      this.roomParticipants = this.activeRoom.participants;
      this.attachParticipantTracks(participant);

      participant.on(
        'trackPublished',
        (track: LocalAudioTrack | LocalVideoTrack) => {
          const element = track.attach();
          this.renderer.data.id = track['id'];
          this.renderer.setAttribute(element, 'id', track['id']);
          this.renderer.setStyle(element, 'height', '100%');
          this.renderer.setStyle(element, 'max-width', '100%');
          this.renderer.appendChild(this.remoteMedia.nativeElement, element);
        }
      );
    });

    // When a Participant adds a Track, attach it to the DOM.
    this.activeRoom.on('trackPublished', (track) => {
      this.attachTracks([track]);
    });

    this.activeRoom.on('disconnected', (room: Room) => {
      room.localParticipant.tracks.forEach(
        (publication: LocalTrackPublication) =>
          this.videoChartService.detachLocalTrack(publication.track)
      );
    });
  }

  startLocalVideo(): void {
    if (this.activeRoom) {
      this.activeRoom.localParticipant.videoTracks.forEach(
        (publication: any) => {
          const element = publication.track.attach();
          this.renderer.data.id = publication.track.id;
          this.renderer.setAttribute(
            element,
            'id',
            `local-${publication.track.id}`
          );
          this.renderer.setStyle(element, 'max-width', '350px');
          this.renderer.appendChild(this.remoteMedia.nativeElement, element);
        }
      );
    }
  }

  attachParticipantTracks(participant): void {
    participant.tracks.forEach((part) => {
      this.trackPublished(part);
    });
  }

  trackPublished(publication: RemoteTrackPublication | any) {
    if (publication.isSubscribed) this.attachTracks(publication.track);
    if (!publication.isSubscribed)
      publication.on('subscribed', (track: RemoteTrack) => {
        this.attachTracks(track);
      });
  }

  attachTracks(tracks) {
    if (tracks) {
      const element = tracks.attach();
      this.renderer.data.id = tracks.sid;
      if (
        this.remoteMedia.nativeElement &&
        this.remoteMedia.nativeElement.children &&
        this.remoteMedia.nativeElement.children.length > 0 &&
        element.nodeName === 'VIDEO'
      ) {
        this.renderer.setAttribute(element, 'id', `remote-${tracks.id}`);
        this.renderer.setStyle(element, 'max-width', '110px');
        this.renderer.setStyle(element, 'top', '10px');
        this.renderer.setStyle(element, 'right', '10px');
        this.renderer.setStyle(element, 'position', 'absolute');
        this.renderer.appendChild(this.remoteMedia.nativeElement, element);
      } else {
        this.renderer.setAttribute(element, 'id', tracks.id);
        this.renderer.setStyle(element, 'height', '100%');
        this.renderer.setStyle(element, 'max-width', '100%');
        this.renderer.appendChild(this.remoteMedia.nativeElement, element);
      }
    }
  }

  detachTracks(participant): void {
    participant.tracks.forEach(() => {
      const element: HTMLDivElement = this.remoteMedia.nativeElement;
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
    });
  }

  expandWidth(action: 'expand' | 'collapse') {
    this.videoExpanedService.toggleVideoExpended(!this.expand);
    this.expandIcon = !this.expandIcon;
    this.collapseIcon = !this.collapseIcon;
    const element: any = this.remoteMedia.nativeElement;
    if (action === 'collapse') {
      if (element && element.hasChildNodes()) {
        element.childNodes.forEach((e) => {
          if (e && e.id) {
            if (e.id.startsWith('local-')) {
              e.style.maxWidth = '350px';
            }
            if (e.id.startsWith('remote-')) {
              e.style.maxWidth = '120px';
            }
          }
        });
      }
    }
    if (action === 'expand') {
      if (element && element.hasChildNodes()) {
        element.childNodes.forEach((e) => {
          if (e && e.id) {
            if (e.id.startsWith('local-')) {
              e.style.maxWidth = '100%';
            }
            if (e.id.startsWith('remote-')) {
              e.style.maxWidth = '250px';
            }
          }
        });
      }
    }
  }

  togglePreviewCamera(): void {
    if (this.isVideoMute) {
      this.unMuteVideo();
    } else {
      this.muteVideo();
    }
  }

  muteVideo() {
    if (this.activeRoom) {
      const localParticipant = this.activeRoom.localParticipant;
      localParticipant.videoTracks.forEach((videoTracks) => {
        videoTracks.track.disable();
      });
      this.isVideoMute = true;
    }
  }

  unMuteVideo(): void {
    if (this.activeRoom) {
      const localParticipant = this.activeRoom.localParticipant;
      localParticipant.videoTracks.forEach((videoTracks) => {
        videoTracks.track.enable();
      });
      this.isVideoMute = false;
    }
  }

  muteAudio(): void {
    if (this.activeRoom) {
      const localParticipant = this.activeRoom.localParticipant;
      localParticipant.audioTracks.forEach((audioTrack) => {
        audioTrack.track.disable();
      });
      this.isAudioMute = true;
    }
  }

  unMuteAudio(): void {
    if (this.activeRoom) {
      const localParticipant = this.activeRoom.localParticipant;
      localParticipant.audioTracks.forEach(
        (audioTrack: LocalAudioTrackPublication) => {
          audioTrack.track.enable();
        }
      );
      this.isAudioMute = false;
    }
  }

  disconnectRoom(): void {
    if (this.activeRoom) {
      console.log("Disconnect room");
      window.localStorage.removeItem(StorageEnum.ROOM_ID);  
      this.activeRoom.disconnect();
      this.activeRoom = null;
      this.expandIcon = false;
      this.collapseIcon = false;
      if (
        this.remoteMedia.nativeElement &&
        this.remoteMedia.nativeElement.hasChildNodes()
      ) {
        while (this.remoteMedia.nativeElement.hasChildNodes()) {
          this.remoteMedia.nativeElement.removeChild(
            this.remoteMedia.nativeElement.firstChild
          );
        }
      }
      if (
        this.localMedia.nativeElement &&
        this.localMedia.nativeElement.hasChildNodes()
      ) {
        while (this.localMedia.nativeElement.hasChildNodes()) {
          this.localMedia.nativeElement.removeChild(
            this.localMedia.nativeElement.firstChild
          );
        }
      }
    }
  }

  // Twilio End ----------------

  getFamilyList() {
    this.consultationLeftSerivce
      .getFamilyList({})
      .subscribe((res: FamiltyDetails[]) => {
        this.patients = res;
      });
    if ((this.MemberId = this.utilityService.getFromStorage('customerId'))) {
      this.selectedUser = true;
    }
  }

  saveDoctorNotes() {
    if (this.doctorNotes?.length > 0) {
      this.isTouched = true;
      this.consultationLeftSerivce
        .saveDoctorComments({
          customerId: this.utilityService.getFromStorage('customerId'),
          consultationId: this.utilityService.getFromStorage('consultationId'),
          doctorNotes: this.doctorNotes,
        })
        .subscribe();
    } else {
      this.isTouched = true;
    }
  }

  getDoctorNotes() {
    this.consultationLeftSerivce
      .getDoctorComments({
        consultationId: this.utilityService.getFromStorage('consultationId'),
      })
      .subscribe((res) => {
        this.doctorNotes = res.doctorNotes;
      });
  }

  searchFamilyByNumber() {
    if (this.searchText) {
      this.consultationLeftSerivce
        .searchFamilyByNumber({
          mobileNumber: this.searchText,
        })
        .subscribe((res: FamiltyDetails[]) => {
          this.patients = res;
        });
    }
  }

  clearSearch() {
    this.searchText = null;
    this.getFamilyList();
  }

  getUserDetails() {
    this.userDetailService
      .getUserDetails(this.utilityService.getFromStorage('customerId'))
      .subscribe((res) => {
        this.userDetails = res;
      });
  }

  openChangeUserDetails = (patients) => {
    this.consultationLeftSerivce
      .checkPrescriptionSent({
        customerId: this.utilityService.getFromStorage('customerId'),
        consultationId: this.utilityService.getFromStorage('consultationId'),
      })
      .subscribe((res: any) => {
        this.newConsultationID = res.newConsultationId;

        // if (this.newConsultationID) {
        //   this.utilityService.saveToStorage(
        //     'customerId',
        //     patients.customerId || patients.customerID
        //   );
        //   this.utilityService.saveToStorage(
        //     'consultationId',
        //     this.newConsultationID
        //   );
        //   this.userDetailService.setUserData(
        //     patients.customerId || patients.customerID
        //   );
        // }

        if (patients.customerId !== this.MemberId && !this.newConsultationID) {
          this.dialog.open(ChangeUserDetailsComponent, {
            data: {
              selectedPatient: patients,
              userDetails: this.userDetails,
            },
            panelClass: 'change-user-details',
          });
        }
      });
  };
  ngOnDestroy(): void {
    this.disconnectRoom();
  }
}
