import { SelectValue } from "./select-value.model";

export class TestRecommendation {
    title = "Test Recommendation";
    name = 'test_recommendation';
    requestKey = 'test';
    path = 'addTestRecommendationInList'
    key = "comment";
    selectedItems = [];
    data = [];
    constructor(TestRecom = null) {
        if (TestRecom) {
            this.data = TestRecom.map(elem => new SelectValue({
                id: elem.testId,
                name: elem.testName,
                comment: elem.comment
            }));
        }
    }
}