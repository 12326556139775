<div class="comment_box" fxLayout="column">
  <mat-card fxLayoutAlign="start center">
    <mat-card-title>{{consultationData.title}}</mat-card-title>
  </mat-card>
  <ng-container *ngIf="commentCondition">
    <div class="comment_box_heading" fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="row" class="title" *ngIf="options.itemIndex !== null">
        <p>{{consultationData?.selectedItems[options.itemIndex]?.name}}</p>
      </div>
      <div fxLayout="row">
        <mat-card-title class="comment">Comment</mat-card-title>
      </div>
    </div> 
    <div fxLayoutAlign="space-between center" class="comment_box_textarea" fxFlex="100">
      <div fxLayoutAlign="space-between center" fxLayout="row" fxFlex="100">
        <textarea (blur)="saveComment()" rows="10" gdRows.lg="8" placeholder="Enter Comment Here.." fxFlex="100" 
          [(ngModel)]="consultationData?.selectedItems[options.itemIndex].comment"></textarea>
      </div>
    </div>
  </ng-container>
</div>