import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallDisposeComponent } from '../call-dispose/call-dispose.component';

@Component({
  selector: 'app-past-history',
  templateUrl: './past-history.component.html',
  styleUrls: ['./past-history.component.scss']
})
export class PastHistoryComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<CallDisposeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close(true);
  }
}
