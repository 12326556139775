import { Component, OnInit } from '@angular/core';
import { DRangeService } from 'src/app/core/services/d-range.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { DRange } from 'src/app/shared/models/d-range.model';
@Component({
  selector: 'app-d-range',
  templateUrl: './d-range.component.html',
  styleUrls: ['./d-range.component.scss'],
})
export class DRangeComponent implements OnInit {
  dRangeArray: DRange[] = [];
  selectedDRange = null;
  currentPage: number = 0;
  dateRangeArray: DRange[] = [];
  constructor(
    private dRangeService: DRangeService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.getDRangeData();
  }

  getDRangeData() {
    this.dRangeService
      .getDRange(this.utilityService.getFromStorage('customerId'))
      .subscribe((data: any[]) => {
        this.dRangeArray = data;
        if (this.dRangeArray.length > 0) {
          this.dRangeArray[0].isSelected = true;
        }
        if (this.dRangeArray?.length > 0) {
          this.selectedDRange = this.dRangeArray[this.currentPage];
          if (this.dRangeArray.length >= 5) {
            this.dateRangeArray = this.dRangeArray.slice(0, 5);
          } else {
            this.dateRangeArray = this.dRangeArray;
          }
        }
      });
  }

  private deSelectCurrent() {
    this.dateRangeArray = this.dateRangeArray.map((d) => ({
      ...d,
      isSelected: false,
    }));
    this.dRangeArray = this.dRangeArray.map((d) => ({
      ...d,
      isSelected: false,
    }));
  }

  next() {
    if (this.currentPage === this.dRangeArray.length) {
      return;
    }
    if (this.currentPage >= this.dateRangeArray.length - 1) {
      this.currentPage = this.currentPage + 1;
      if (this.dRangeArray[this.currentPage]) {
        this.deSelectCurrent();
        this.dateRangeArray.push({
          ...this.dRangeArray[this.currentPage],
          isSelected: true,
        });
        this.dateRangeArray.shift();
        this.selectedDRange = this.dRangeArray[this.currentPage];
      }
    } else {
      if (this.dateRangeArray.length > 0) {
        this.deSelectCurrent();
        this.currentPage = this.currentPage + 1;
        const obj: any = this.dateRangeArray[this.currentPage];
        if (obj) {
          obj.isSelected = true;
        }
        this.selectedDRange = obj;
      }
    }
  }

  previous() {
    if (this.currentPage === 0) {
      return;
    }
    if (this.currentPage >= this.dateRangeArray.length - 1) {
      this.currentPage = this.currentPage - 1;
      if (this.dRangeArray[this.currentPage]) {
        const obj = this.dRangeArray.slice(0, this.currentPage - 3);
        if (obj && obj.length > 0) {
          this.deSelectCurrent();
          this.dateRangeArray.pop();
          this.dateRangeArray.unshift(obj[obj?.length - 1]);
          const currentSelected =
            this.dateRangeArray[this.dateRangeArray.length - 1];
          if (currentSelected) {
            this.dateRangeArray[this.dateRangeArray.length - 1].isSelected =
              true;
            this.selectedDRange = currentSelected;
          }
        }
      }
    } else {
      if (this.dateRangeArray.length > 0) {
        this.deSelectCurrent();
        this.currentPage = this.currentPage - 1;
        const obj: any = this.dateRangeArray[this.currentPage];
        if (obj) {
          obj.isSelected = true;
        }
        this.selectedDRange = obj;
      }
    }
  }

  onSelectCurrent(dRange: DRange, idx: number) {
    this.selectedDRange = dRange;
    this.currentPage = idx;
    this.deSelectCurrent();
    this.dateRangeArray[idx].isSelected = true;
  }
}
