import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ToasterService } from 'angular2-toaster';

import { UtilityService } from './core/services/utility.service';
import { JwtHelperService } from './core/auth/jwt-helper.service';
import {
  OnlineStatusService,
  OnlineStatusType,
} from './core/services/online-status.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Hindustan Wellness';
  showLoader: Observable<boolean>;
  constructor(
    private readonly toasterService: ToasterService,
    private readonly utilityService: UtilityService,
    private readonly jwtHelperService: JwtHelperService,
    private readonly onlineStatusService: OnlineStatusService
  ) {
    this.jwtHelperService.checkUserIsAuthenticated();
  }

  public ngOnInit(): void {
    this.showLoader = this.utilityService.loaderStatus;
    this.initConnectionStatus();
  }

  private initConnectionStatus(): void {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      if (status === OnlineStatusType.OFFLINE) {
        this.toasterService.pop(
          'error',
          'Offline',
          'Internet connection lost'
        );
      }
      if (status === OnlineStatusType.ONLINE) {
        this.toasterService.pop(
          'success',
          'Online',
          'Internet connection back'
        );
      }
    });
  }
}
