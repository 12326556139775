const API_BASE_URL = 'https://doctorcrm.stetho.hindustanlab.com';
const API_VIDEO_URL = 'https://video.stetho.hindustanlab.com';
const hw = `${API_BASE_URL}`;
const vs = `${API_VIDEO_URL}`;
console.log(hw);
console.log(vs);

export class APIEndPoint {
  public static LOGIN: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/login/crm`,
  };
  public static VERIFY_LOGIN_OTP: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/login/verify/otp`,
  };

  public static VERIFY_ADDMEMBER_OTP: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/family/add/otp/verify`,
    noNeedAuthToken: true,
  };

  public static USER_DETAILS: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/user/UserDetails`,
  };
  public static DOCTOR_DETAILS: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/doctorDetails`,
  };

  public static GET_CALL_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/doctor-dashboard`,
  };

  public static GET_DOCTOR_DETAIL_STEAM: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/doctorDetailsteam`,
  };

  public static GET_CITY_NAME: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/family/getCity`,
  };

  public static GET_CONSULTATION: APIDef = {
    method: 'GET',
    api: () => `local/api/consultation.json`,
  };
  public static GET_PATIENT_UPLOADED_DOC: APIDef = {
    method: 'GET',
    api: () => `local/api/patient-uploaded-doc.json`,
  };


  public static DOCTOR_OFFLINE: APIDef = {
    method: 'POST',
    api: () => `${API_VIDEO_URL}/doctor/offline`,
  };
  public static DOCTOR_ONLINE: APIDef = {
    method: 'POST',
    api: () => `${API_VIDEO_URL}/doctor/online`,
  };
  public static MY_CALL: APIDef = {
    method: 'POST',
    api: () => `${API_VIDEO_URL}/doctor/myCall`,
  };
  public static DOCTOR_PING: APIDef = {
    method: 'POST',
    api: () => `${API_VIDEO_URL}/doctor/ping`,
  };
  public static DOCTOR_ACTIVE: APIDef = {
    method: 'POST',
    api: () => `${API_VIDEO_URL}/doctor/active`,
  };
  public static CALL_WAITING: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/callWaiting`,
  };
  //<====================================> DEFAULT MIDDLE PAGE API <====================================>

  public static GET_USER_VITALS: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/CustomerVitals`,
  };
  public static CREATE_USER_VITALS: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/updateCustomerUserVitals`,
  };
  public static CREATE_SUMMARY_NOTES: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/updateSummaryNotes`,
  };

  public static GET_SUMMARY_NOTES: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/summary-notes`,
  };

  public static GET_DEFAULT_ALLERGY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/getDefaultAllergy`,
  };
  public static GET_DEFAULT_MEDICAL_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/getDefaultMedicalHistory`,
  };
  public static GET_DEFAULT_MEDICINES: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/getDefaultMedicines`,
  };
  public static GET_DEFAULT_PROVISIONAL_DIAGNOSIS: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/getDefaultDiagnosis`,
  };
  public static GET_DEFAULT_SYMPTOMS: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/getDefaultSymptoms`,
  };
  public static GET_DEFAULT_INSTRUCTIONS: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/getDefaultInstruction`,
  };
  public static GET_DEFAULT_TEST_RECOMMENDATION: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/getDefaultTestRecommendation`,
  };

  //<====================================> GET MIDDLE PAGE API <====================================>

  public static GET_CUSTOMER_ALLERGY: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/getCustomerAllergy',
  };
  public static GET_CUSTOMER_DIAGNOSIS: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/getCustomerDiagnosis',
  };
  public static GET_CUSTOMER_INSTRUCTION: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/getCustomerInstruction',
  };
  public static GET_CUSTOMER_MEDICINES: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/getCustomerMedicines',
  };

  public static GET_CUSTOMER_MEDICAL_HISTORY: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/getCustomerMedicalHistory',
  };

  public static GET_CUSTOMER_SYMPTOMS: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/getCustomerSymptoms',
  };
  public static GET_CUSTOMER_TEST_RECOMMENDATION: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/getCustomerTestRecommendation',
  };
  // public static ADD_GET_CUSTOMER_VITALS: APIDef = {
  //   method: "POST",
  //   api: () => "${API_BASE_URL}/getCustomerVitals",
  // };

  // <====================================> SEARCH MIDDLE PAGSEARCH <====================================>

  public static SEARCH_CUSTOMER_ALLERGY: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/searchAllergy',
  };

  public static SEARCH_CUSTOMER_DIAGNOSIS: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/searchDiagnosis',
  };

  public static SEARCH_CUSTOMER_INSTRUCTION: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/searchInstruction',
  };

  public static SEARCH_CUSTOMER_MEDICINE: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/searchMedicines',
  };

  public static SEARCH_CUSTOMER_MEDICAL_HISTORY: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/searchMedicalHistory',
  };

  public static SEARCH_CUSTOMER_SYMPTOMS: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/searchSymptoms',
  };

  public static SEARCH_CUSTOMER_TEST_RECOMENDATION: APIDef = {
    method: 'POST',
    api: () => '${API_BASE_URL}/searchTestRecommendation',
  };

  public static SAVE_NEW_CATEGORIES: APIDef = {
    method: 'POST',
    api: (data) => `${API_BASE_URL}/${data.id}`,
  };

  // COMMON API CALL FOR ADD/REMOVE/UPDATE

  public static CATEGORIES: APIDef = {
    method: 'POST',
    api: (data) => `${API_BASE_URL}/${data.id}`,
  };

  public static DOCS_UPLOADED_BY_PATIENT: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/docsUploadedByPatient`,
  };

  public static D_RANGE: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/drange`,
  };

  public static CONSULTATION_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/Consultation-history`,
  };

  public static TEST_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/Test-history`,
  };

  public static DOCTOR_COMMENTS: APIDef = {
    method: 'POST',
    //api: () => '${API_BASE_URL}/updateDoctorNotes',
    api: () => `${API_BASE_URL}/updateDoctorNotes`,
  };

  public static GET_DOCTOR_COMMENTS: APIDef = {
    method: 'POST',
    //api: () => '${API_BASE_URL}/doctor-notes',
    api: () => `${API_BASE_URL}/doctor-notes`,
  };

  // <==================================== PASTHISTORY MIDDLE PAGSEARCH ====================================>

  public static GET_SYMPTOMS_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/customerSymptomsPastHistory`,
  };

  public static GET_ALLERGY_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/customerAllergyPastHistory`,
  };

  public static GET_INSTRUCTION_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/customerInstructionPastHistory`,
  };

  public static GET_DIAGNOSIS_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/customerDiagnosisPastHistory`,
  };

  public static UPDATE_MEDICINES_FORM: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/updateCustomerMedicines`,
  };

  public static GET_MEDICINES_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/customerMedicinesPastHistory`,
  };
  public static GET_MEDICAL_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/customerMedicalPastHistory`,
  };

  public static GET_TESTRECOMM_HISTORY: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/customerTestRecommendationPastHistory`,
  };

  public static LANDING_CALL: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/call-landing`,
  };

  public static UPDATE_CALL_LANDING: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/updateCallLanding`,
  };

  public static UPDATE_CALL_DISPOSE: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/call-dispose`,
  };

  public static GET_FAMILY_DETAILS: APIDef = {
    method: 'POST',
    api: (data) => `${API_BASE_URL}/family/${data.id}`,
  };

  public static CHECK_PRESCRIPTION_SENT: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/checkPrescriptionSent`,
  };

  public static SEARCH_FAMILY_BY_NUMBER: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/family/getUserByMobileNumber`,
  };

  public static UPDATE_MEDICINE_DOSAGE_BY_ID: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/updateCustomerMedicines`,
  };

  public static GET_MEDICINE_DOSAGE: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/getCustomerMedicinesDoses`,
  };

  public static REMOVE_MEDICINE_DOSAGE: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/removeCustomerDose`,
  };
  public static REMOVE_ALL_DATA: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/remove-all-data`,
  };

  // <==================================== API TO GET DATA FOR PRESCRIPTION AND SEND PRESCRIPTION TO DB ====================================>

  public static GET_CONSULTAION_DATA: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/ConsultationReport`,
  };

  public static SEND_PRESCRIPTION_REPORT: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/updatePrescriptionStatus`,
  };

  public static CHECK_MANDATORY_FIELDS_ON_PREVIEW: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/checkMandatoryFieldsOnPreview`,
  };

  public static MOVE_DASHBOARD: APIDef = {
    method: 'POST',
    api: () => `${API_BASE_URL}/moveDashboard`,
  };
}

export interface APIDef {
  method: string;
  api: any;
  noNeedAuthToken?: boolean;
}

export interface APIInput {
  id?: string;
}
