import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { APIEndPoint } from 'src/app/core/https/ApiEndPoint';
import { RestApiService } from 'src/app/core/https/rest-api.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ADD_API_MAPPER } from 'src/app/shared/consts/apiMapper';
import {
  createMedicineInstructionFormItem,
  MedicineUpdateReqBody,
} from 'src/app/shared/models/medicines-data.model';
import { MiddleService } from 'src/app/core/services/middle.service';

interface MedicinesRepeatperDay {
  name: string;
  value: string;
}

@Component({
  selector: 'app-medicine-instruction',
  templateUrl: './medicine-instruction.component.html',
  styleUrls: ['./medicine-instruction.component.scss'],
})
export class MedicineInstructionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() options = { consultationIndex: null, itemIndex: null };
  @Input() consultationData: any;
  @Input() instructionForm: FormGroup;
  @Output() updateMedicineEvent = new EventEmitter();
  @Output() selectEmitt = new EventEmitter();
  unsubscribeInstructionForm: any;
  panelOpenState = false;
  myControl = new FormControl();
  Options: string[] = ['1/3', '1/2', '3/4', '1', '1½', '2', '2½', '3', '3½'];
  filteredOptions: Observable<string[]>;
  qtyValue = null;
  defaultTiming: boolean = true;
  switchTiming: boolean = false;
  selectedIndex = 0;
  tabletsPerday: any;
  optionValue:any = 'sos';


  //Default Timing Menu
  mediRepeatPerDay: MedicinesRepeatperDay[] = [
    { value: '4_Hourly', name: '4 Hourly' },
    { value: '6_Hourly', name: '6 Hourly' },
    { value: '8_Hourly', name: '8 Hourly' },
    { value: '12_Hourly', name: '12 Hourly' },
    { value: '48_Hourly', name: '48 Hourly' },
    { value: 'Once_a_day', name: 'Once a day' },
    { value: 'Once_a_week', name: 'Once a week' },
    { value: 'Once_a_month', name: 'Once a month' },
    { value: 'Twice_a_day', name: 'Twice a day' },
    { value: 'Twice_a_week', name: 'Twice a week' },
    { value: 'Twice_a_month', name: 'Twice a month' },
    { value: 'Thrice_a_day', name: 'Thrice a day' },
    { value: 'Thrice_a_week', name: 'Thrice a week' },
    { value: 'Thrice_a_month', name: 'Thrice a month' },
    { value: '4_times_a_day', name: '4 times a day' },
    { value: '4_times_a_week', name: '4 imes a week' },
    { value: '4_times_a_month', name: '4 times a month' },
    { value: '5_times_a_day', name: '5 times a day' },
    { value: '5_times_a_week', name: '5 times a week' },
    { value: '5_times_a_month', name: '5 times a month' },
    { value: 'sos', name: 'SOS' },
  ];
  public customPatterns = { '0': { pattern: new RegExp('[0-2]') } };
  constructor(
    public formBuilder: FormBuilder,
    public restAPIService: RestApiService,
    private utilityService: UtilityService,
    private middleService: MiddleService
  ) {}

  onMedTabletsPerDay(event, idx: number): void {
    const numbers: string[] = event?.target?.value?.split('-');
    if (!numbers || numbers?.length === 0) {
      return;
    }
    this.utilityService.saveToStorage('medTabletsPerDay', event.target.value);
    this.tabletsPerday = event.target.value;
    localStorage.setItem('medTabletsPerDay', event.target.value);

    const medicinesForm: FormGroup = this.dosages.at(idx) as FormGroup;
    const switchTimingFrom = medicinesForm.controls['SwitchTimingFrom'];
    const defaultTimingFrom = medicinesForm.controls['defaultTimingFrom'];
    if (numbers?.length === 1) {
      const [one] = numbers;
      switchTimingFrom.get('breakfast').setValue(one);
    } else if (numbers?.length === 2) {
      const [one, two] = numbers;
      switchTimingFrom.get('breakfast').setValue(one);
      switchTimingFrom.get('lunch').setValue(two);
    } else if (numbers?.length === 3) {
      const [one, two, three] = numbers;
      switchTimingFrom.get('breakfast').setValue(one);
      switchTimingFrom.get('lunch').setValue(two);
      switchTimingFrom.get('dinner').setValue(three);
      defaultTimingFrom.get('medTabletsPerDay').setValue(this.tabletsPerday);
    }
  }

  switch(idx: number) {
    const medicinesForm: FormGroup = this.dosages.at(idx) as FormGroup;
    this.switchTiming = !this.switchTiming;
    this.defaultTiming = !this.defaultTiming;
    const defaultTimingFrom = medicinesForm.controls['defaultTimingFrom'];
    const switchTimingFrom = medicinesForm.controls['SwitchTimingFrom'];
    if (this.switchTiming) {
      medicinesForm.get('duration').setValue('');
      medicinesForm.get('duration').setValidators([]);
      defaultTimingFrom.get('medRepeatDay').setValue('');
      defaultTimingFrom.get('medRepeatDay').setValidators([]);
      defaultTimingFrom.get('medSchedule').setValue('');
      defaultTimingFrom.get('medTabletsPerDay').setValue(this.tabletsPerday);
      defaultTimingFrom.get('medSchedule').setValidators([]);
      switchTimingFrom.get('breakfast').setValidators([Validators.required]);
      switchTimingFrom.get('lunch').setValidators([Validators.required]);
      switchTimingFrom.get('dinner').setValidators([Validators.required]);
    } else {
      medicinesForm.get('duration').setValidators([Validators.required]);
      defaultTimingFrom
        .get('medRepeatDay')
        .setValidators([Validators.required]);
      defaultTimingFrom.get('medSchedule').setValidators([Validators.required]);
      switchTimingFrom.get('breakfast').setValue('');
      switchTimingFrom.get('breakfast').setValidators([]);
      switchTimingFrom.get('lunch').setValue('');
      switchTimingFrom.get('lunch').setValidators([]);
      defaultTimingFrom.get('medTabletsPerDay').setValidators([]);
      switchTimingFrom.get('dinner').setValue('');
      switchTimingFrom.get('dinner').setValidators([]);
    }
    defaultTimingFrom.updateValueAndValidity();
    switchTimingFrom.updateValueAndValidity();
    medicinesForm.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.changeValue(this.optionValue)
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

    this.middleService.switchTiming.subscribe(
      (action) => (this.switchTiming = action)
    );

    this.middleService.defaultTiming.subscribe(
      (action) => (this.defaultTiming = action)
    );

    this.setMedicineInstruction();
    this.unsubscribeInstructionForm =
      this.instructionForm.valueChanges.subscribe((res) => {
        if (res?.dosages?.length) {
          if (res.dosages.length === 1) {
            this.selectedIndex = 0;
          }
          this.consultationData.selectedItems[
            this.options.itemIndex
          ].instructions = res;
          if (res.touched) {
            this.updateMedicineDosage();
          }
        }
      });
  }

  changeValue(medirepeatday){
    // if(medirepeatday.value == 'tt'){
    //   this.optionValue == 'bbnnn'
    // }
   
    this.optionValue = medirepeatday;
    //console.log(this.optionValue); 
    
  }

  get dosages(): FormArray {
    return this.instructionForm.get('dosages') as FormArray;
  }

  makeFormDirty = () => {
    this.instructionForm.controls['touched'].setValue(true);
  };

  addDosage = () => {
    this.dosages.push(createMedicineInstructionFormItem(this.formBuilder));
    this.selectedIndex = this.selectedIndex + 1;
    this.defaultTiming = true;
    this.switchTiming = false;
    this.handleSelectedConsultation(
      this.consultationData,
      this.consultationData.selectedItems[this.options.itemIndex]
    ).subscribe(() => {});
    //add dosage -> defaultswitch
  };

  onSelectDose(idx: number) {
    /**
     * SwitchTimingFrom -> breakfast, dinner, lunch
     * defaultTimingFrom -> medRepeatDay, medSchedule, medTabletsPerDay
     */
    if (this.dosages?.length > 0) {
      const currentDose: FormGroup = this.dosages.at(idx) as FormGroup;
      const defaultTimingFrom: AbstractControl =
        currentDose.get('defaultTimingFrom');
      const medRepeatDay = defaultTimingFrom.get('medRepeatDay')?.value;
      const medSchedule = defaultTimingFrom.get('medSchedule')?.value;
      const medTabletsPerDay = defaultTimingFrom.get('medTabletsPerDay')?.value;

      const switchTimingFrom: AbstractControl =
        currentDose.get('SwitchTimingFrom');

      const breakfast = switchTimingFrom.get('breakfast')?.value;
      const dinner = switchTimingFrom.get('dinner')?.value;
      const lunch = switchTimingFrom.get('lunch')?.value;
      if (
        medRepeatDay &&
        medRepeatDay !== '0' &&
        medSchedule &&
        medSchedule !== '0'
      ) {
        this.switchTiming = false;
        this.defaultTiming = true;
      } else if (
        breakfast &&
        breakfast !== '0' &&
        dinner &&
        dinner !== '0' &&
        lunch &&
        lunch !== '0' &&
        medTabletsPerDay &&
        medTabletsPerDay !== '0'
      ) {
        this.switchTiming = true;
        this.defaultTiming = false;
      } else {
        this.defaultTiming = true;
        this.switchTiming = false;
      }
    }
    this.selectedIndex = idx;
  }

  handleSelectedConsultation(consultation, selectedItem) {
    const selectedConsultation = ADD_API_MAPPER[consultation.name];
    if (selectedConsultation) {
      let payload: any = {
        consultationId: this.utilityService.getFromStorage('consultationId'),
        [selectedConsultation.keyName]: selectedItem.id,
        dose: `Dose${this.selectedIndex + 1}`,
        medTabletsPerDay:
          this.utilityService.getFromStorage('medTabletsPerDay'),
      };
      return this.middleService.consultationCategory(
        payload,
        selectedConsultation.path
      );
    }
    return of([]);
  }

  removeDosage = (index) => {
    let reqBody = {
      consultationId: this.utilityService.getFromStorage('consultationId'),
      dose: `Dose${this.selectedIndex + 1}`,
      medicineId:
        this.consultationData.selectedItems[this.options.itemIndex].id,
      // medTabletsPerDay: this.utilityService.getFromStorage('medTabletsPerDay'),
    };

    this.restAPIService
      .invoke(APIEndPoint.REMOVE_MEDICINE_DOSAGE, null, reqBody)
      .subscribe(() => {});
    this.dosages.removeAt(index);
    if (this.selectedIndex == index) {
      this.selectedIndex = this.selectedIndex - 1;
    } else {
      this.selectedIndex = index - 1;
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'].isFirstChange) {
      this.setMedicineInstruction();
    }
  }

  setMedicineInstruction = () => {
    // if (
    //   this.consultationData?.selectedItems[this.options.itemIndex]?.instructions
    // ) {
    //   this.instructionForm.patchValue(
    //     this.consultationData.selectedItems[this.options.itemIndex].instructions
    //   );
    // }
  };

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Options.filter((Option) =>
      Option.toLowerCase().includes(filterValue)
    );
  }

  setQtyValue(toggleVal, index) {
    this.dosages.at(index).get('medQty').setValue(toggleVal);
  }

  setMedDurtaionVal(value: string) {
    this.instructionForm.controls['medDuration'].setValue(value);
  }

  updateMedicineDosage = () => {
    let reqBody = {
      ...this.consultationData?.selectedItems[this.options.itemIndex]
        ?.instructions?.dosages[this.selectedIndex],
      dosage: `Dose${this.selectedIndex + 1}`,
      medicineId:
        this.consultationData?.selectedItems[this.options.itemIndex].id,
      consultationId: this.utilityService.getFromStorage('consultationId'),
      medTabletsPerDay: localStorage.getItem('medTabletsPerDay'),
    };
    reqBody = new MedicineUpdateReqBody(reqBody);
    reqBody.medTabletsPerDay = this.tabletsPerday;
    this.restAPIService
      .invoke(APIEndPoint.UPDATE_MEDICINE_DOSAGE_BY_ID, null, reqBody)
      .subscribe((res) => {
        //this.utilityService.saveToStorage('customerId', res.customerId);
      });
  };
  ngOnDestroy(): void {
    this.unsubscribeInstructionForm.unsubscribe();
  }
}
