import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationError,
  NavigationEnd,
  Event as IRouterEvent,
} from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIEndPoint } from 'src/app/core/https/ApiEndPoint';
import { RestApiService } from 'src/app/core/https/rest-api.service';
import { DoctorDetailsService } from 'src/app/core/services/doctorDetails.service';
import { UserDetailsService } from 'src/app/core/services/userDetails.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { VideoExpendService } from 'src/app/core/services/video-expend.service';
import { DoctorDetails } from 'src/app/shared/models/doctor-details.model';
import { UserDetails } from 'src/app/shared/models/user-details.model';
import { DashboardDailogBoxComponent } from '../../../dashboard/dashboard-dailog-box/dashboard-dailog-box.component';
import { CallDisposeComponent } from '../consultation-dailog-box/call-dispose/call-dispose.component';
import { SendPrescriptionComponent } from '../consultation-dailog-box/send-prescription/send-prescription.component';
import { PrescriptionComponent } from '../consultation-dailog-box/prescription/prescription.component';
import { DoctorCallService } from 'src/app/core/services/doctor-call.service';
import { JwtHelperService } from 'src/app/core/auth/jwt-helper.service';

@Component({
  selector: 'app-consultation-header',
  templateUrl: './consultation-header.component.html',
  styleUrls: ['./consultation-header.component.scss'],
})
export class ConsultationHeaderComponent implements OnInit {
  @Input() history: any;
  @Input() fromDashboard = false;
  @Output() dashboardEvent = new EventEmitter();
  private showError$: Subscription = null;
  dummyCall = false;
  preview = false;
  selectedTabIndex = 0;
  expand: boolean;
  accordionExpand = [];
  userdetails: UserDetails = new UserDetails();
  doctordetails: DoctorDetails = new DoctorDetails();

  logoutButton: boolean = false;
  noInternetConnection: boolean = true;
  checkError: any;

  timerSubscription: Subscription;
  audio = new Audio();
  queueDetails: any;
  totalCallQueue: any;
  waitingUsers: any = [];
  usersdata: any = [];
  increaeCount: any;
  increaseCount: number;
  callCount: number = 0;
  waitingUsersAge: any;
  callWatingCount: any;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    private videoExpendService: VideoExpendService,
    private utilityService: UtilityService,
    private userDetailsServices: UserDetailsService,
    private doctorDetailsServices: DoctorDetailsService,
    private restApiService: RestApiService,
    public toasterService: ToasterService,
    public readonly router: Router,
    private readonly doctorCallService: DoctorCallService,
    private readonly jwtService: JwtHelperService
  ) {
    this.router.events.subscribe((event: IRouterEvent) => {
      if (event instanceof NavigationStart) {
        this.preview = false;
      }
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        if (this.router.url === '/consultation/consultation-details') {
          this.preview = true;
        }
      }
    });
  }

  ngOnInit(): void {
    this.timerSubscription = timer(0, 2000).pipe(
      map(() => {
        this.callWaitingQueue();
      })
    ).subscribe();
    
    this.userDetailsServices.userChange.subscribe((res) => {
      this.getUserDetailsData();
      this.getDoctorDetailsData();
    });

    this.setSelectedTabIndex(this.route.url);
    this.videoExpendService.videoExpended.subscribe((res: boolean) => {
      this.expand = res;
    });

    if (this.router.url === '/dashboard') {
      this.checkOfflineStatus();
    }  
  }

  checkOfflineStatus() {
    if (localStorage.getItem("offlineStatus")) {
      this.doctorCallService.offline().subscribe(() => {
        this.noInternetConnection = false;

      });
    }
     else{
      this.doctorCallService.online().subscribe(() => {
        this.noInternetConnection = true;

      });
    }
  }

  getUserDetailsData() {
    if (this.fromDashboard === false) {
      this.userDetailsServices
        .getUserDetails(this.utilityService.getFromStorage('customerId'))
        .subscribe((res: any) => {
          this.userdetails = res;
        });
    }
  }

  getDoctorDetailsData() {
    if (this.fromDashboard === true) {
      this.doctorDetailsServices.getDoctorDetails().subscribe((res: any) => {
        this.doctordetails = res;
      });
    }
  }

  setSelectedTabIndex = (url: string) => {
    if (url.includes('docs-uploaded-by-patient')) {
      this.selectedTabIndex = 1;
    } else if (url.includes('testhistory') || url.includes('packagereport')) {
      this.selectedTabIndex = 2;
    } else if (url.includes('drange')) {
      this.selectedTabIndex = 3;
    } else if (url.includes('consultationhistory')) {
      this.selectedTabIndex = 4;
    } else {
      this.selectedTabIndex = 0;
    }
  };

  vedioExpand() {
    this.videoExpendService.toggleVideoExpended(false);
  }

  openDummyCall = (): void => {
    this.doctorDetailsServices.showErrorMessage(true);
    this.restApiService
      .invoke(APIEndPoint.CHECK_MANDATORY_FIELDS_ON_PREVIEW, null, {
        consultationId: this.utilityService.getFromStorage('consultationId'),
      })
      .subscribe(
        (res: any) => {
          if (res?.checkValue === true) {
            this.dummyCall = !this.dummyCall;
            this.preview = !this.preview;
            // this.videoExpendService.toggleVideoExpended(false);
            this.dialog.open(PrescriptionComponent, {
              // disableClose: true
            });
          } else {
            // this.doctorDetailsServices.showErrorMessage(true);
            this.toasterService.pop(
              'error',
              null,
              'Please Fill Mandatory Field'
            );
          }
        },
        () => { }
      );
  };

  openCallModal = () => {
    this.dialog.open(DashboardDailogBoxComponent, {
      data: {},
      panelClass: 'incomingCall-Popup',
      disableClose: true,
    });

    // this.restApiService
    //   .invoke(APIEndPoint.LANDING_CALL)
    //   .subscribe((res: any) => {
    //     this.utilityService.saveToStorage('consultationId', res.consultationId);
    //     this.utilityService.saveToStorage('customerId', res.customerId);
    //   });
  };

  openDialog = () => {
    this.dialog.open(CallDisposeComponent, {
      disableClose: true,
    });
    this.dummyCall = !this.dummyCall;
    this.preview = !this.preview;
  };

  sendPrescription = () => {
    this.dialog.open(SendPrescriptionComponent, {
      disableClose: true,
    });
    this.preview = !this.preview;
    this.dummyCall = !this.dummyCall;
  };

  navigatePath() {
    this.utilityService.navigate('/consultation');
  }

  showLogout() {
    this.logoutButton = true;
  }

  logout() {
    this.jwtService.logoutUser();
    localStorage.clear();
  }

  onOfflineOnline(type: 'online' | 'offline') {
    if (type === 'online') {
      this.doctorCallService.online().subscribe(() => {
        this.noInternetConnection = true;
        localStorage.setItem("onlineStatus", 'online');
        localStorage.removeItem("offlineStatus");
      });
    }
    if (type === 'offline') {
      this.doctorCallService.offline().subscribe(() => {
        this.noInternetConnection = false;
        localStorage.setItem("offlineStatus", 'offline');
        localStorage.removeItem("onlineStatus")
      });
    }
  }

  playAudio(): void {
    this.audio.src = '../assets/tone.mp3';
    this.audio.load();
    this.audio.play();
    this.audio.loop = false;
  }

  private callWaitingQueue(): void {
    this.restApiService
      .invoke(APIEndPoint.CALL_WAITING)
      .subscribe((res: any) => {
        this.waitingUsers = res.responseCallWaiting;
        this.usersdata = res.responseCallWaiting;
        this.callWatingCount =this.usersdata.length - 1;
        this.increaseCount = res.totalCallQueue;
        if (this.increaseCount > this.callCount) {
          this.callCount = res.totalCallQueue;
          this.playAudio();
        }
      });
  }

}
