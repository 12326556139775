import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultationRouteModule } from './consultation.route';
import { AngularMaterialModule } from 'src/app/shared/angular-material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConsultationComponent } from './consultation.component';
import { ConsultationRightComponent } from './components/consultation-right/consultation-right.component';
import { ConsultationMiddleComponent } from './components/consultation-middle/consultation-middle.component';
import { ConsultationLeftComponent } from './components/consultation-left/consultation-left.component';
import { VitalsComponent } from './components/consultation-right/vitals/vitals.component';
import { ItemCommentboxComponent } from './components/consultation-right/item-commentbox/item-commentbox.component';
import { MedicineInstructionComponent } from './components/consultation-right/medicine-instruction/medicine-instruction.component';
import { AddNewMemberComponent } from './components/consultation-left/add-new-member/add-new-member.component';
import { SendPrescriptionComponent } from './components/consultation-dailog-box/send-prescription/send-prescription.component';
import { CallDisposeComponent } from './components/consultation-dailog-box/call-dispose/call-dispose.component';
import { ImageViewerModule } from 'ngx-image-viewer';
import { BmiCalculatorComponent } from './components/consultation-right/vitals/bmi-calculator/bmi-calculator.component';
import { VedioCallComponent } from './components/consultation-left/vedio-call/vedio-call.component';
import { NotesCommentComponent } from './components/consultation-right/notes-comment/notes-comment.component';
import { AddNewFindingComponent } from './components/consultation-dailog-box/add-new-finding/add-new-finding.component';
import { PastHistoryComponent } from './components/consultation-dailog-box/past-history/past-history.component';
import { ChangeUserDetailsComponent } from './components/consultation-dailog-box/change-user-details/change-user-details.component';
import { AyushmanPackageReportComponent } from './components/consultation-header/test-history/ayushman-package-report/ayushman-package-report.component';
import { ConsultationHeaderComponent } from './components/consultation-header/consultation-header.component';
import { ConsultationHistoryComponent } from './components/consultation-header/consultation-history/consultation-history.component';
import { DRangeComponent } from './components/consultation-header/d-range/d-range.component';
import { TestHistoryComponent } from './components/consultation-header/test-history/test-history.component';
import { DocsUploadedByPatientComponent } from './components/consultation-header/docs-uploaded-by-patient/docs-uploaded-by-patient.component';
import { PrescriptionComponent } from './components/consultation-dailog-box/prescription/prescription.component';
import { DirectiveModule } from 'src/app/shared/directive/directive.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DocViewerModule } from 'src/app/shared/components/document-viewer';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    ConsultationComponent,
    ConsultationRightComponent,
    ConsultationMiddleComponent,
    ConsultationLeftComponent,
    VitalsComponent,
    ItemCommentboxComponent,
    MedicineInstructionComponent,
    AddNewMemberComponent,
    TestHistoryComponent,
    DRangeComponent,
    ConsultationHistoryComponent,
    ConsultationHeaderComponent,
    DocsUploadedByPatientComponent,
    AyushmanPackageReportComponent,
    SendPrescriptionComponent,
    CallDisposeComponent,
    BmiCalculatorComponent,
    VedioCallComponent,
    NotesCommentComponent,
    AddNewFindingComponent,
    PastHistoryComponent,
    ChangeUserDetailsComponent,
    PrescriptionComponent,
  ],
  imports: [
    CommonModule,
    ConsultationRouteModule,
    AngularMaterialModule,
    SharedModule,
    ImageViewerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    DirectiveModule,
    DocViewerModule,
  ],
  exports: [ConsultationHeaderComponent],
})
export class ConsultationModule {}
