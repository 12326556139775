<section>
  <div fxLayout="column" (click)="makeFormDirty()">
    <mat-card class="heading" fxFlex="100">
      <mat-card-title> Medicines </mat-card-title>
    </mat-card>
    <div class="dose">
      <p class="tablet_name">
        {{ consultationData?.selectedItems[options.itemIndex]?.name }}
      </p>
      <div
        *ngIf="dosages?.value?.length > 0"
        class="dose_container"
        fxLayout="row"
        fxLayoutAlign="space-between end"
      >
        <div class="dose_left" fxLayoutAlign="center center">
          <mat-card-title
            *ngFor="let item of dosages.value; let $index = index"
            (click)="onSelectDose($index)"
            [ngClass]="$index == selectedIndex ? 'selected' : 'noSelected'"
            >Dose

            <span>
              {{ $index + 1
              }}<mat-icon *ngIf="$index > 0" (click)="removeDosage($index)"
                >cancel
              </mat-icon></span
            >
          </mat-card-title>
        </div>

        <div class="dose_right" fxLayoutAlign="end end">
          <button mat-icon-button (click)="addDosage()">
            <mat-icon color="primary">add</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <form class="medicines_instruction_container" [formGroup]="instructionForm">
      <div
        formArrayName="dosages"
        *ngFor="
          let dosage of instructionForm.get('dosages')['controls'];
          let $index = index
        "
      >
        <div *ngIf="selectedIndex === $index" [formGroupName]="$index">
          <mat-card-title>Quantity</mat-card-title>
          <div fxLayout="row" class="quantity_input_container">
            <mat-form-field
              appearance="outline"
              class="quantity_input_container"
              fxFlex="50"
            >
              <input
                type="text"
                placeholder="Pick one Quantity"
                aria-label="Number"
                matInput
                [matAutocomplete]="auto"
                formControlName="medQty"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                  (click)="setQtyValue(option, $index)"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field
              appearance="outline"
              class="quantity_select_container"
              fxFlex="50"
            >
              <select matNativeControl formControlName="medUnit" placeholder="UNIT">
                <option value="null" disabled>UNIT</option>
                <option value="tablets">TABLETS</option>
                <option value="ml">ML</option>
                <option value="tbsp">TBSP</option>
                <option value="gms">GMS</option>
                <option value="tsf">TSF</option>
              </select>
            </mat-form-field>
          </div>

          <div fxLayout="row" class="quantity_btn_container">
            <!-- <mat-button-toggle-group name="fontStyle" aria-label="Font Style" fxLayoutAlign="center center" formControlName="medQty">
              <mat-button-toggle *ngFor="let toggleVal of filteredOptions | async"
                (click)="setQtyValue(toggleVal, $index)">{{
                toggleVal }}</mat-button-toggle>
            </mat-button-toggle-group> -->

            <mat-button-toggle-group
              name="fontStyle"
              aria-label="Font Style"
              fxLayoutAlign="center center"
              formControlName="medQty"
            >
              <mat-button-toggle
                value="1/3"
                (click)="setQtyValue('1/3', $index)"
                >1/3
              </mat-button-toggle>
              <mat-button-toggle
                value="1/2"
                (click)="setQtyValue('1/2', $index)"
                >1/2</mat-button-toggle
              >
              <mat-button-toggle
                value="3/4"
                (click)="setQtyValue('3/4', $index)"
                >3/4</mat-button-toggle
              >
              <mat-button-toggle value="1" (click)="setQtyValue('1', $index)"
                >1</mat-button-toggle
              >
              <mat-button-toggle value="1½" (click)="setQtyValue('1½', $index)"
                >1½</mat-button-toggle
              >
              <mat-button-toggle value="2" (click)="setQtyValue('2', $index)"
                >2</mat-button-toggle
              >
              <mat-button-toggle value="2½" (click)="setQtyValue('2½', $index)"
                >2½</mat-button-toggle
              >
              <mat-button-toggle value="3" (click)="setQtyValue('3', $index)"
                >3</mat-button-toggle
              >
              <mat-button-toggle value="3½" (click)="setQtyValue('3½', $index)"
                >3½</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>

          <div class="default_timeing_container">
            <div fxLayout="row" fxLayoutAlign="space-between end">
              <div fxLayout="row" class="row" fxLayoutAlign="center center">
                <mat-card-title>Default Timing*</mat-card-title>
                <button mat-button color="primary" (click)="switch($index)">
                  Switch
                </button>
              </div>

              <mat-form-field
                class="switch_input"
                appearance="outline"
                *ngIf="switchTiming"
                formGroupName="defaultTimingFrom"
              >
                <input
                  type="text"
                  placeholder="0-0-0"
                  mask="0-0-0"
                  matInput
                  [patterns]="customPatterns"
                  (input)="onMedTabletsPerDay($event, $index)"
                  formControlName="medTabletsPerDay"
                />
              </mat-form-field>
            </div>

            <ng-container
              *ngIf="defaultTiming"
              formGroupName="defaultTimingFrom"
            >
              <div fxLayout="column" class="default_timeing">
                <mat-form-field appearance="fill">
                  <mat-label>Select Default Timing (required)</mat-label>
                  <mat-select
                    matNativeControl
                    required
                    formControlName="medRepeatDay"
                    
                  >
                  <div  *ngFor="let medirepeatday of mediRepeatPerDay"
                  >
                    <mat-option
                    [value]="medirepeatday.value"
                      (click)="changeValue(medirepeatday.value)"
                    >
                      {{ medirepeatday.name }}
                    </mat-option>
                  </div>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="default_timeing_radio_btn">
                <mat-radio-group
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  class="row"
                  formControlName="medSchedule"
                >
                  <mat-radio-button value="before_food" fxFlex="50"
                    >Before Food</mat-radio-button
                  >
                  <mat-radio-button value="after_food" fxFlex="50"
                    >After Food</mat-radio-button
                  >
                  <mat-radio-button value="empty_stomach" fxFlex="50"
                    >Empty Stomach</mat-radio-button
                  >
                  <mat-radio-button value="bed_time" fxFlex="50"
                    >Bedtime</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </ng-container>
            <ng-container formGroupName="SwitchTimingFrom">
              <ng-container class="switch_container" *ngIf="switchTiming">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div
                    fxLayout="column"
                    fxFlex="30"
                    class="switch_content"
                    fxLayoutAlign="center center"
                  >
                    <h3 fxLayoutAlign="center center" class="switch_heading">
                      Breakfast
                    </h3>
                    <div fxLayout="row" class="switch_grp_btn">
                      <mat-button-toggle-group
                        fxLayoutAlign="center center"
                        formControlName="breakfast"
                      >
                        <mat-button-toggle value="1">Before</mat-button-toggle>
                        <mat-button-toggle value="2">After</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  </div>
                  <div
                    fxLayout="column"
                    fxFlex="30"
                    class="switch_content"
                    fxLayoutAlign="center center"
                  >
                    <h3 fxLayoutAlign="center center" class="switch_heading">
                      Lunch
                    </h3>
                    <div fxLayout="row" class="switch_grp_btn">
                      <mat-button-toggle-group
                        fxLayoutAlign="center center"
                        formControlName="lunch"
                      >
                        <mat-button-toggle value="1">Before</mat-button-toggle>
                        <mat-button-toggle value="2">After</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  </div>
                  <div
                    fxLayout="column"
                    fxFlex="30"
                    class="switch_content"
                    fxLayoutAlign="center center"
                  >
                    <h3 fxLayoutAlign="center center" class="switch_heading">
                      Dinner
                    </h3>
                    <div fxLayout="row" class="switch_grp_btn">
                      <mat-button-toggle-group
                        fxLayoutAlign="center center"
                        formControlName="dinner"
                      >
                        <mat-button-toggle value="1">Before</mat-button-toggle>
                        <mat-button-toggle value="2">After</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <div class="duration" *ngIf="optionValue !== 'sos'">
            <mat-card-title>Duration*</mat-card-title>
            <div fxLayout="column">
              <mat-button-toggle-group
                class="duration_btn_group"
                formControlName="duration"
              >
                <mat-button-toggle value="1" id="mat-button-toggle-1d"
                  >1d
                </mat-button-toggle>
                <mat-button-toggle value="2">2d</mat-button-toggle>
                <mat-button-toggle value="3">3d</mat-button-toggle>
                <mat-button-toggle value="4">4d</mat-button-toggle>
                <mat-button-toggle value="5">5d</mat-button-toggle>

                <mat-button-toggle value="7" id="mat-button-toggle-1w"
                  >1w</mat-button-toggle
                >
                <mat-button-toggle value="10">10d</mat-button-toggle>
                <mat-button-toggle value="14">2w</mat-button-toggle>
                <mat-button-toggle value="15">15d</mat-button-toggle>
                <mat-button-toggle value="21">3w</mat-button-toggle>

                <mat-button-toggle value="28" id="mat-button-toggle-4w"
                  >4w</mat-button-toggle
                >
                <mat-button-toggle value="30">1m</mat-button-toggle>
                <mat-button-toggle value="60">2m</mat-button-toggle>
                <mat-button-toggle value="90">3m</mat-button-toggle>
                <mat-button-toggle value="180">6m</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>

          <div class="comment_box">
            <mat-card-title>Comment</mat-card-title>
            <textarea
              rows="5"
              cols="45"
              placeholder="Enter Comment Here..."
              formControlName="comment"
              [value]="
                instructionForm.get('dosages')['controls'][$index]['controls'][
                  'comment'
                ].value
              "
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
