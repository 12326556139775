import { Component, HostListener, Input, OnInit } from '@angular/core';
import { VideoExpendService } from 'src/app/core/services/video-expend.service';
import { MiddleService } from 'src/app/core/services/middle.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PastHistoryComponent } from '../consultation-dailog-box/past-history/past-history.component';
import { AddNewFindingComponent } from '../consultation-dailog-box/add-new-finding/add-new-finding.component';
import { VitalData } from 'src/app/shared/models/vital-data.model';
import {
  ADD_API_MAPPER,
  GET_DEFAULT_API_MAPPER,
  GET_HSITORY_API_MAPPER,
  PAST_HSITORY_API_MAPPER,
  REMOVE_API_MAPPER,
  SEARCH_API_MAPPER,
} from 'src/app/shared/consts/apiMapper';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility.service';
import { createMedicineInstructionFormItem } from 'src/app/shared/models/medicines-data.model';
import { UserDetailsService } from 'src/app/core/services/userDetails.service';
import { DoctorDetailsService } from 'src/app/core/services/doctorDetails.service';
import { APIEndPoint } from 'src/app/core/https/ApiEndPoint';
import { RestApiService } from 'src/app/core/https/rest-api.service';

@Component({
  selector: 'app-consultation-middle',
  templateUrl: './consultation-middle.component.html',
  styleUrls: ['./consultation-middle.component.scss'],
})
export class ConsultationMiddleComponent implements OnInit {
  selectedConsultation = '';
  instructionForm: FormGroup;
  consultationData: any[];
  selectedItemIndex = null;
  selectedPanelIndex = null;
  vedioexpand: any;
  expand: boolean;
  accordionExpand = [];
  selectedConsult = null;
  searchText = '';
  vitalsDataExists = false;
  summaryNotes: string;
  isChipDisabled: boolean = false;
  checkError: any;
  check: any;
  constructor(
    private videoExpendService: VideoExpendService,
    private middleService: MiddleService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    public userDetailsService: UserDetailsService,
    private doctorDetailsServices: DoctorDetailsService,
    private restApiService: RestApiService
  ) { }

  ngOnInit(): void {
    this.userDetailsService.userChange.subscribe((res) => {
      this.getAllData();
    });
    this.videoExpendService.videoExpended.subscribe((res: boolean) => {
      this.expand = res;
    });
    this.createMedicineInstructionForm();
    this.getNotes();
    this.showErrorMessageSubject();
  }

  private showErrorMessageSubject() {
    this.doctorDetailsServices.showError$.subscribe((res) => {
      if (res) {
        for (const c in this.consultationData) {
          this.onClosedPanel(+c, false);
        }
      }
    });
  }

  // tslint:disable-next-line:typedef
  // @HostListener('window:beforeunload', ['$event']) onReload(event) {
  //   const result = confirm('Changes you made may not be saved.');
  //   if (result) {
  //     // Do more processing...
  //   }
  //   event.returnValue = false;
  // }
  // tslint:disable-next-line:typedef
  // @HostListener('window:popstate', ['$event']) onBackPress(event) {
  //   const result = confirm('Changes you made may not be saved.');
  //   if (result) {
  //     // Do more processing...
  //   }
  //   event.preventDefault();
  // }

  private getIconPath(name: string): string {
    const basePath = '../../../../../../assets/icons';
    if (name === 'vitals') {
      return `${basePath}/vitals.png`;
    }
    if (name === 'medical_history') {
      return `${basePath}/findings-icon.png`;
    }
    if (name === 'symptoms') {
      return `${basePath}/symptoms-icon.png`;
    }
    if (name === 'provisional_diagnosis') {
      return `${basePath}/investigations-icon.png`;
    }
    if (name === 'allergy') {
      return `${basePath}/allergy.png`;
    }
    if (name === 'test_recommendation') {
      return `${basePath}/test-recommendation.png`;
    }
    if (name === 'medicines') {
      return `${basePath}/medicines-icon.png`;
    }
    if (name === 'instruction') {
      return `${basePath}/instructions-icon.png`;
    }
    if (name === 'notes') {
      return `${basePath}/prcedures-icon.png`;
    }
  }

  getAllData = () => {
    this.middleService
      .getCumulativeData()
      .pipe(
        switchMap((res) => {
          if (Array.isArray(res) && res.length > 0) {
            res = res.map((r) => ({
              ...r,
              iconPath: this.getIconPath(r.name || r.key),
            }));
          }
          this.consultationData = res;
          this.accordionExpand = [false, ...Array(res.length - 1).fill(false)];
          this.consultationData.forEach((elem, index) => {
            this.getConsultationData(elem, index, false);
          });
          return this.getVitalsDetails();
        })
      )
      .subscribe(
        (res) => {
          for (let i = 0; i < this.consultationData.length; i++) {
            if (this.consultationData[i].name === 'vitals') {
              this.consultationData[i].data = { ...res.data };
              this.selectedConsultation = 'vitals';
              this.setConsultationValues(this.consultationData[i], i, true);
              break;
            }
          }
          this.vitalsDataExists = true;
        },
        (err) => {
          this.vitalsDataExists = false;
          for (let i = 0; i < this.consultationData.length; i++) {
            if (this.consultationData[i].name === 'vitals') {
              this.setConsultationValues(null, i, false);
              break;
            }
          }
        }
      );
  };

  searchChip(consultation) {
    if (this.searchText.length > 2) {
      this.fetchSearchlist(consultation).subscribe(
        (res) => {
          consultation.data = [...res.data];
        },
        (err) => (consultation.data = [])
      );
    } else {
      if (!this.searchText) {
        this.resetSearchList(consultation).subscribe();
      }
    }
  }

  fetchSearchlist(consultation) {
    const selectedConsultation = SEARCH_API_MAPPER[consultation.name];
    if (selectedConsultation) {
      let payload = {
        [selectedConsultation.keyName]: this.searchText,
      };
      return this.middleService
        .categoriesData(
          payload,
          selectedConsultation.path,
          selectedConsultation.model,
          selectedConsultation.resKeyName
        )
        .pipe(debounceTime(1000), distinctUntilChanged());
    }
  }

  resetSearchList(consultation) {
    const selectedConsultation = GET_DEFAULT_API_MAPPER[consultation.name];
    if (selectedConsultation) {
      return this.middleService
        .getDefaultData(
          selectedConsultation.path,
          selectedConsultation.model,
          selectedConsultation.resKeyName
        )
        .pipe(
          map((res: any) => {
            let data = [];
            res.data.forEach((elem) => {
              if (
                !consultation.selectedItems.find((item) => item.id === elem.id)
              ) {
                data.push(elem);
              }
            });
            consultation.data = [...data];
            return res;
          })
        );
    }
  }

  clearSearch(consultation) {
    this.resetSearchList(consultation).subscribe((res: any) => {
      this.searchText = '';
    });
  }

  onOpenPanel = (consultation, index: number, isOpen: boolean) => {
    this.consultationData[index].isOpened = true;
    this.summaryNotes = this.selectedConsult.summaryNotes || this.summaryNotes;

    this.selectedConsult = { ...consultation };
    if (consultation.key === 'notes') {
      this.getNotes();
    }
    if (consultation.name === 'vitals') {
      this.setConsultationValues(consultation, index, isOpen);
    } else {
      this.getConsultationData(consultation, index, isOpen);
    }
  };

  getConsultationData = (consultation, index, isOpen) => {
    this.handleGetDataCall(consultation).subscribe(
      (res) => {
        this.setConsultationValues(consultation, index, isOpen);
      },
      (err) => this.setConsultationValues(consultation, index, isOpen)
    );
  };

  getVitalsDetails() {
    return this.middleService.getUserVitals();
  }

  setConsultationValues(consultation, index: number, isOpen: boolean) {
    if (consultation) {
      this.accordionExpand[index] = isOpen;
      this.selectedPanelIndex = index;
      this.selectedConsultation = consultation.key;
      this.selectedConsult = { ...consultation };
      this.selectedItemIndex = consultation.selectedItems
        ? consultation.selectedItems.length - 1
        : this.selectedItemIndex;
      this.videoExpendService.toggleVideoExpended(false);
    }
  }

  handleGetDataCall(consultation): Observable<any> {
    const selectedConsultation = GET_HSITORY_API_MAPPER[consultation.name];
    if (selectedConsultation) {
      const payload = {
        consultationId: this.utilityService.getFromStorage('consultationId'),
      };
      return this.getSavedData(
        payload,
        selectedConsultation.path,
        selectedConsultation.model,
        selectedConsultation.resKeyName
      );
    }
    return of(true);
  }

  getSavedData(payload, path: string, model, resKeyName: string) {
    return this.middleService
      .categoriesData(payload, path, model, resKeyName)
      .pipe(
        switchMap((res: any) => {
          const uniqueItems = [];
          res.data.forEach((elem) => {
            if (
              uniqueItems.length &&
              !uniqueItems.find((item) => item.id === elem.id)
            ) {
              uniqueItems.push(elem);
            } else if (!uniqueItems.length) {
              uniqueItems.push(elem);
            }
          });
          this.consultationData.forEach((consultation) => {
            if (consultation.title === res.title) {
              consultation.selectedItems = [...uniqueItems];
              uniqueItems.forEach((item) => {
                const selectedItemIndex = consultation.data.findIndex(
                  (elem) => elem.id === item.id
                );
                if (selectedItemIndex > -1) {
                  consultation.data.splice(selectedItemIndex, 1);
                }
              });
            }
          });
          if (this.consultationData.length > 0) {
            this.consultationData.forEach((c) => {
              c.isDisabled = false;
              if (c?.selectedItems && c?.selectedItems?.length > 0) {
                const isNaFound = c?.selectedItems.find(
                  (s) => s?.name?.toLowerCase() === 'na'
                );
                if (isNaFound) {
                  c.isDisabled = true;
                }
              }
            });
          }
          return of(this.consultationData);
        })
      );
  }

  onClosedPanel = (index: number, isClose: boolean) => {
    this.consultationData[index].isClosed = true;
    this.accordionExpand[index] = isClose;
    this.selectedItemIndex = this.accordionExpand.includes(true)
      ? this.selectedItemIndex
      : null;
    if (!this.accordionExpand.find((elem) => elem)) {
      this.selectedConsultation = '';
    }
  };

  getNotes() {
    this.middleService.getSummarryNotes().subscribe((res: any) => {
      this.summaryNotes = res.summaryNotes;
      this.selectedConsult = {
        ...this.selectedConsult,
        summaryNotes: this.summaryNotes,
      };
    });
  }

  onSelectedConsultationItem = (
    item,
    consultation,
    index: number,
    selectedItemIndex: number
  ) => {
    if (consultation?.selectedItems?.length > 0) {
      if (item?.name?.toLowerCase() === 'na') {
        return;
      }
      const foundNa = consultation?.selectedItems?.find(
        (s) =>
          s?.name?.toLowerCase() === 'na' && consultation?.isDisabled === true
      );
      if (foundNa) {
        return;
      }
    }
    this.handleSelectedConsultation(consultation, item).subscribe((res) => {
      this.selectedConsult = { ...consultation };
      consultation.selectedItems.push({ ...item });
      consultation.data.splice(selectedItemIndex, 1);

      // To disable because na is exist
      const foundNa = consultation?.selectedItems.find(
        (s: any) => s?.name?.toLowerCase() === 'na'
      );
      if (foundNa) {
        const idx = this.consultationData.findIndex(
          (c) => c.name === consultation.name
        );
        if (idx !== -1) {
          this.consultationData[idx].isDisabled = true;
        }
      }

      this.resetSearchList(consultation).subscribe(() => { });
      if (consultation.key === 'medicines') {
        this.getMedicineInstruction(item);
      }
      this.onSelectItem(
        index,
        consultation.selectedItems.length - 1,
        consultation
      );
      this.middleService.onDefaultTimingChange(true);
      this.middleService.onSwitchTimingChange(false);
    });
  };

  getMedicineInstruction = (item: any) => {
    if (item) {
      this.middleService.getMedicineDosage(item.id).subscribe((res: any) => {
        this.dosages.clear();
        this.instructionForm.controls['touched'].setValue(false);
        if (item?.name?.toLowerCase() !== 'na') {
          const dosages = res.doses.map((dose, index) => {
            dose.medUnit = dose?.medUnit || null;
            dose.defaultTimingFrom = dose.defaultTimingFrom[0];
            dose.SwitchTimingFrom = dose.switchTimingFrom[0];
            dose.SwitchTimingFrom.breakfast =
              dose.SwitchTimingFrom.breakfast.toString();
            dose.SwitchTimingFrom.lunch =
              dose.SwitchTimingFrom.lunch.toString();
            dose.SwitchTimingFrom.dinner =
              dose.SwitchTimingFrom.dinner.toString();
            dose.duration =
              dose.duration === 0 ? null : dose.duration.toString();
            this.dosages.push(
              createMedicineInstructionFormItem(this.formBuilder)
            );
            this.dosages.at(index).patchValue(dose);
            return dose;
          });
          this.dosages.patchValue(dosages);
        }
        else {
          let body = {
            "breakfast": 0,
            "comment": " ",
            "consultationId": this.utilityService.getFromStorage('consultationId'),
            "dinner": 0,
            "dose": "Dose1",
            "duration": 0,
            "lunch": 0,
            "medQty": "na",
            "medRepeatDay": "na",
            "medSchedule": " ",
            "medTabletsPerDay": " ",
            "medUnit": "na",
            "medicineId":  item.id
          }

          return this.restApiService.invoke(APIEndPoint.UPDATE_MEDICINES_FORM, null, body)
            .subscribe()
        }
      });
    }
  };

  handleSelectedConsultation(consultation, selectedItem) {
    this.clearSearch(consultation);
    const selectedConsultation = ADD_API_MAPPER[consultation.name];

    if (selectedConsultation) {
      let payload: any = {
        consultationId: this.utilityService.getFromStorage('consultationId'),
        [selectedConsultation.keyName]: selectedItem.id,
        dose: `Dose` + 1,
      };
      return this.middleService.consultationCategory(
        payload,
        selectedConsultation.path
      );
    }
    return of([]);
  }

  onDeselectedConsultationItem = (
    item,
    consultation,
    index: number,
    selectedItemIndex: number
  ) => {
    this.handleDeselectedConsultation(consultation, item).subscribe(() => {
      consultation.data.push(item);
      consultation.selectedItems.splice(selectedItemIndex, 1);
      this.selectedConsult = { ...consultation };
      if (consultation?.selectedItems?.length === 1) {
        const foundNA = consultation.selectedItems.find(
          (s) => s?.name === 'NA'
        );
        if (foundNA) {
          this.dosages.clear();
          this.instructionForm.controls['touched'].setValue(false);
        }
      }
      if (selectedItemIndex === consultation.selectedItems.length) {
        this.onSelectItem(index, selectedItemIndex - 1, consultation);
      } else {
        this.onSelectItem(index, selectedItemIndex, consultation);
      }
    });
  };

  handleDeselectedConsultation(consultation, selectedItem) {
    const selectedConsultation = REMOVE_API_MAPPER[consultation.name];
    if (selectedConsultation) {
      let payload: any = {
        consultationId: this.utilityService.getFromStorage('consultationId'),
        [selectedConsultation.keyName]: selectedItem.id,
      };
      return this.middleService.consultationCategory(
        payload,
        selectedConsultation.path
      );
    }
    return of([]);
  }

  onSelectItem = (
    selectedPanelIndex,
    selectedItemIndex,
    consultation,
    item = null
  ) => {
    this.selectedPanelIndex = selectedPanelIndex;
    this.selectedItemIndex = selectedItemIndex;
    if (consultation.key === 'medicines') {
      this.getMedicineInstruction(item);
    }
  };

  getSelectedNames(selectedItems) {
    return selectedItems.map((elem) => elem.name).join(', ');
  }

  createMedicineInstructionForm = () => {
    this.instructionForm = this.formBuilder.group({
      touched: [false],
      dosages: this.formBuilder.array([
        createMedicineInstructionFormItem(this.formBuilder),
      ]),
    });
  };

  get dosages(): FormArray {
    return this.instructionForm.get('dosages') as FormArray;
  }

  openPastHistory(consultation) {
    this.handlePastHistory(consultation).subscribe((res) => {
      this.dialog.open(PastHistoryComponent, {
        data: res,
      });
    });
  }

  handlePastHistory(consultation) {
    const selectedConsultation = PAST_HSITORY_API_MAPPER[consultation.name];
    const payload = {
      customerId: this.utilityService.getFromStorage('customerId'),
      consultationId: this.utilityService.getFromStorage('consultationId'),
    };
    if (selectedConsultation) {
      return this.middleService.getPastHistory(
        payload,
        selectedConsultation.path,
        selectedConsultation.model,
        selectedConsultation.resKeyName
      );
    }
    return of(true);
  }

  addNewItem(consultation) {
    const dialogRef = this.dialog.open(AddNewFindingComponent, {
      data: {
        ...consultation,
        searchText: this.searchText,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.searchChip(consultation);
    });
  }

  saveVitals(data: VitalData) {
    this.consultationData.forEach((elem) => {
      if (elem.key === 'vitals') {
        elem.data = {
          ...elem.data,
          ...data,
        };
        this.vitalsDataExists = true;
        this.accordionExpand[0] = true;
      }
    });
    this.middleService.createUserVitals(data).subscribe();
  }

  private isInvalidKey(val) {
    return !val || val === 0;
  }

  checkConsultation(consultation, accordionExpand) {
    if (
      consultation?.isClosed &&
      consultation.key === 'medicines' &&
      !accordionExpand &&
      !this.instructionForm.valid
    ) {
      return true;
    }
    if (
      consultation.key === 'vitals' &&
      !accordionExpand &&
      consultation?.isClosed
    ) {
      const { weight, bmiValue, heightFt } = consultation?.data;
      if (
        this.isInvalidKey(weight) ||
        this.isInvalidKey(bmiValue) ||
        this.isInvalidKey(heightFt)
      ) {
        return true;
      }
    }
    if (
      consultation?.isClosed &&
      !accordionExpand &&
      consultation.name === 'medical_history' &&
      !consultation.selectedItems.length
    ) {
      return true;
    }
    if (
      consultation?.isClosed &&
      !accordionExpand &&
      consultation.name === 'symptoms' &&
      !consultation.selectedItems.length
    ) {
      return true;
    }
    if (
      consultation?.isClosed &&
      !accordionExpand &&
      consultation.name === 'provisional_diagnosis' &&
      !consultation.selectedItems.length
    ) {
      return true;
    }
    if (
      consultation?.isClosed &&
      !accordionExpand &&
      consultation.name === 'allergy' &&
      !consultation.selectedItems.length
    ) {
      return true;
    }
    if (
      consultation?.isClosed &&
      !accordionExpand &&
      consultation.name === 'test_recommendation' &&
      !consultation.selectedItems.length
    ) {
      return true;
    }
    if (
      consultation?.isClosed &&
      !accordionExpand &&
      consultation.key === 'instruction' &&
      !consultation.selectedItems.length
    ) {
      return true;
    }
    if (
      consultation.key === 'notes' &&
      !accordionExpand &&
      consultation?.isClosed &&
      !this.selectedConsult.summaryNotes
    ) {
      return true;
    }
    return false;
  }
}
