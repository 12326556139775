import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Allergy } from "src/app/shared/models/allergy.model";
import { Medicines } from "src/app/shared/models/medicines.model";
import { ProvisionalDiagnosis } from "src/app/shared/models/provisional-diagnosis.model";
import { TestRecommendation } from "src/app/shared/models/test-recommendation.model";
import { SummaryNotes } from "src/app/shared/models/summary-notes.model";
import { SymptomsComplaints } from "src/app/shared/models/symptoms.model";
import { Vital } from "src/app/shared/models/vital.model";
import { APIEndPoint } from "../https/ApiEndPoint";
import { RestApiService } from "../https/rest-api.service";
import { Instructions } from "src/app/shared/models/instruction.model";
import { MedicalHistory } from "src/app/shared/models/medical-history.model";
import { UtilityService } from "./utility.service";

@Injectable({
  providedIn: "root",
})
export class MiddleService {
  private defaultTimingChange$: BehaviorSubject<boolean> = new BehaviorSubject(true)
  public defaultTiming: Observable<boolean> = this.defaultTimingChange$.asObservable();

  private switchTimingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public switchTiming: Observable<boolean> = this.switchTimingChange$.asObservable();

  private isPrescriptionSentChange$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public isPrescriptionSent$: Observable<boolean> = this.isPrescriptionSentChange$.asObservable();

  constructor(
    private restAPIService: RestApiService,
    private utilityService: UtilityService
  ) {}

  public onDefaultTimingChange(value: boolean): void {
    this.defaultTimingChange$.next(value)
  }

  public onSwitchTimingChange(value: boolean): void {
    this.switchTimingChange$.next(value)
  }

  public onPrescriptionSentChangeChange(value: boolean): void {
    this.isPrescriptionSentChange$.next(value)
  }

  getCumulativeData = () => {
    const allApiArr = [];
    allApiArr.push(this.getDefaultMedicalHistory());
    allApiArr.push(this.getDefaultSymptoms());
    allApiArr.push(this.getDefaultProvisionalDiagnosis());
    allApiArr.push(this.getDefaultAllergy());
    allApiArr.push(this.getDefaultTestRecommendation());
    allApiArr.push(this.getDefaultMedicines());
    allApiArr.push(this.getDefaultInstruction());
    return forkJoin(allApiArr).pipe(
      map((res) => {
        res.push(new SummaryNotes());
        res.unshift(new Vital());
        return res;
      })
    );
  };

  createUserVitals = (body) => {
    return this.restAPIService.invoke(
      APIEndPoint.CREATE_USER_VITALS,
      null,
      body
    );
  };

  //<====================================> DEFAULT MIDDLE PAGE API <====================================>

  getDefaultMedicalHistory = () => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_DEFAULT_MEDICAL_HISTORY)
      .pipe(
        map((res: any) => {
          return new MedicalHistory(res.diseaseList);
        })
      );
  };

  getDefaultSymptoms = () => {
    return this.restAPIService.invoke(APIEndPoint.GET_DEFAULT_SYMPTOMS).pipe(
      map((res: any) => {
        return new SymptomsComplaints(res.symptomsList);
      })
    );
  };

  getDefaultProvisionalDiagnosis = () => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_DEFAULT_PROVISIONAL_DIAGNOSIS)
      .pipe(
        map((res: any) => {
          return new ProvisionalDiagnosis(res.diagnosisList);
        })
      );
  };

  getDefaultAllergy = () => {
    return this.restAPIService.invoke(APIEndPoint.GET_DEFAULT_ALLERGY).pipe(
      map((res: any) => {
        return new Allergy(res.allergyList);
      })
    );
  };

  getDefaultInstruction = () => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_DEFAULT_INSTRUCTIONS)
      .pipe(
        map((res: any) => {
          return new Instructions(res.instructionList);
        })
      );
  };

  getDefaultTestRecommendation = () => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_DEFAULT_TEST_RECOMMENDATION)
      .pipe(
        map((res: any) => {
          return new TestRecommendation(res.testList);
        })
      );
  };

  getDefaultMedicines = () => {
    return this.restAPIService.invoke(APIEndPoint.GET_DEFAULT_MEDICINES).pipe(
      map((res: any) => {
        return new Medicines(res.medicinesList);
      })
    );
  };

  getSummarryNotes = () => {
    return this.restAPIService.invoke(APIEndPoint.GET_SUMMARY_NOTES, null, {
      consultationId: this.utilityService.getFromStorage("consultationId"),
    });
  };

  updateSummarryNotes = (body) => {
    return this.restAPIService.invoke(
      APIEndPoint.CREATE_SUMMARY_NOTES,
      null,
      body
    );
  };

  //<====================================> GET MIDDLE PAGE API <====================================>

  getUserVitals = () => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_USER_VITALS, null, {
        customerId: this.utilityService.getFromStorage("customerId"),
        consultationId: this.utilityService.getFromStorage("consultationId"),
      })
      .pipe(
        map((res: any) => {
          return new Vital(res);
        })
      );
  };
  // getCallLanding = () => {
  //   return this.restAPIService
  //     .invoke(APIEndPoint.LANDING_CALL, null, {
  //       consultationMode: "video",
  //       customerId: this.utilityService.getFromStorage('customerId'),
  //       pickStatus: 0,
  //       roomId: this.utilityService.getFromStorage('roomId'),
  //     })
  //     .pipe(
  //       map((res: any) => {
  //         return new Vital(res);
  //       })
  //     );
  // };

  getMedicalHistory = (body) => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_CUSTOMER_MEDICAL_HISTORY, null, body)
      .pipe(
        map((res) => {
          return new MedicalHistory(res.diseaseList);
        })
      );
  };

  getSymptoms = (body) => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_CUSTOMER_SYMPTOMS, null, body)
      .pipe(
        map((res) => {
          return new SymptomsComplaints(res.symptomsList);
        })
      );
  };

  getAllergy = (body) => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_CUSTOMER_ALLERGY, null, body)
      .pipe(
        map((res) => {
          return new Allergy(res.allergyList);
        })
      );
  };

  getInstruction = (body) => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_CUSTOMER_INSTRUCTION, null, body)
      .pipe(
        map((res) => {
          return new Instructions(res.instructionList);
        })
      );
  };

  getDiagnosis = (body) => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_CUSTOMER_DIAGNOSIS, null, body)
      .pipe(
        map((res) => {
          return new ProvisionalDiagnosis(res.diagnosisList);
        })
      );
  };
  getMedicines = (body) => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_CUSTOMER_MEDICINES, null, body)
      .pipe(
        map((res) => {
          return new Medicines(res.medicinesList);
        })
      );
  };

  getTestRecomm = (body) => {
    return this.restAPIService
      .invoke(APIEndPoint.GET_CUSTOMER_TEST_RECOMMENDATION, null, body)
      .pipe(
        map((res) => {
          return new TestRecommendation(res.testList);
        })
      );
  };

  //<====================================> SEARCH MIDDLE PAGE API <====================================>

  categoriesData = (body, path: string, model, resKeyName: string) => {
    return this.restAPIService
      .invoke(APIEndPoint.CATEGORIES, { id: path }, body)
      .pipe(map((res) => new model(res[resKeyName])));
  };

  getPastHistory = (body, path: string, model, resKeyName: string) => {
    return this.restAPIService
      .invoke(APIEndPoint.CATEGORIES, { id: path }, body)
      .pipe(
        map((res) => {
          return {
            ...new model(res[resKeyName]),
            dataToDisplay: new model(res[resKeyName]).data
              .map((elem) => elem.name)
              .join(", "),
          };
        })
      );
  };

  getDefaultData = (path, model, resKeyName) => {
    return this.restAPIService
      .invoke(APIEndPoint.CATEGORIES, { id: path })
      .pipe(map((res: any) => new model(res[resKeyName])));
  };

  //   <======= AddInList Customer API's =======>

  saveCategory = (body, path: string) => {
    return this.restAPIService.invoke(
      APIEndPoint.SAVE_NEW_CATEGORIES,
      { id: path },
      body
    );
  };

  //<====================================> CONSULTATION CATEGORY MIDDLE PAGE API <====================================>

  consultationCategory = (body, path: string) => {
    return this.restAPIService.invoke(
      APIEndPoint.CATEGORIES,
      { id: path },
      body
    );
  };

  getMedicineDosage(medicineId: string) {
    const payload = {
      consultationId: this.utilityService.getFromStorage("consultationId"),
      medicineId,
    };
    return this.restAPIService.invoke(
      APIEndPoint.GET_MEDICINE_DOSAGE,
      null,
      payload
    );
  }
}
