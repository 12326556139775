<div class="add_member_container">
  <mat-card class="add_member-card">
    <mat-card-header>
      <mat-card-title class="title">Add a new Member</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form
        fxLayout="row"
        style="flex: 1"
        [formGroup]="addNewMember"
        (ngSubmit)="onSubmit()"
      >
        <div fxFlex="11"></div>
        <div fxFlex="85" style="padding: 37px 0 100px">
          <div>
            <div class="member_information_container">
              <mat-card-title class="heading"
                >Member Information</mat-card-title
              >

              <div
                class="form_container"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <p class="form_text">First Name:</p>
                <div class="first_name_container">
                  <mat-form-field appearance="outline" class="custom-input">
                    <input
                      characterOnly
                      formControlName="firstName"
                      matInput
                      type="text"
                      (keydown)="preventSpace($event)"
                      placeholder="First Name"
                    />
                  </mat-form-field>
                  <div
                    *ngIf="f?.firstName?.touched && f?.firstName?.errors"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        f?.firstName?.errors?.required &&
                        f?.firstName?.errors?.whitespace
                      "
                    >
                      First Name is required
                    </div>
                    <div
                      *ngIf="
                        !f?.firstName?.errors?.required &&
                        f?.firstName?.errors?.whitespace
                      "
                    >
                      Please enter valid First Name
                    </div>
                    <div *ngIf="f.firstName.errors.maxlength">
                      First Name must be at less than 50 characters
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form_container"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <p class="form_text">Last Name:</p>
                <div class="last_name_container">
                  <mat-form-field appearance="outline" class="custom-input">
                    <!-- characterOnly -->
                    <input
                      characterOnly
                      formControlName="lastName"
                      matInput
                      type="text"
                      (keydown)="preventSpace($event)"
                      placeholder="Last Name"
                    />
                  </mat-form-field>
                  <div
                    *ngIf="f.lastName.touched && f.lastName.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.lastName.errors.required">
                      Last Name is required
                    </div>
                    <div *ngIf="f.lastName.errors.maxlength">
                      Last Name must be at less than 50 characters
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form_container"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <p class="form_text">Mobile No.:</p>
                <div>
                  <mat-form-field appearance="outline" class="custom-input">
                    <input
                      mobileNumber
                      numbersOnly
                      formControlName="mobileNumber"
                      matInput
                      type="text"
                      placeholder="Enter Mobile"
                      maxLength="10"
                      [(ngModel)]="value"
                    />
                    <button
                      *ngIf="value"
                      matSuffix
                      mat-icon-button
                      aria-label="Clear"
                      (click)="value = ''"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <div
                    *ngIf="f.mobileNumber.touched && f.mobileNumber.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.mobileNumber.errors.required">
                      Mobile is required
                    </div>

                    <div *ngIf="f.mobileNumber.errors.invalidNumber">
                      invalidNumber
                    </div>
                    <div *ngIf="f.mobileNumber.errors.minlength">
                      Mobile must be at least than 10 characters
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="form_container " fxLayout="row" fxLayoutAlign="start center">
                <p class="form_text">City Name:</p>
                <div class="dob_container">
                  <mat-form-field appearance="outline" class="custom-input">
                    <input formControlName="city" matInput type="text" placeholder="Enter your city name" (blur)="findCity(cityname)" #cityname/>
                    <mat-autocomplete #auto="matAutocomplete" *ngIf="cities.length">
                      <mat-option *ngFor="let city of cities" [value]="city">
                        {{city.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div> -->
              <div
                class="form_container"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <p class="form_text">DOB:</p>
                <div class="dob_container">
                  <mat-form-field appearance="outline" class="custom-input">
                    <input
                      class="test"
                      #dateInput
                      (focus)="maskedInput.focus()"
                      matInput
                      [min]="minDate"
                      [max]="today"
                      [matDatepicker]="picker"
                      minlength="8"
                    />
                    <input
                      class="test"
                      #maskedInput
                      formControlName="dob"
                      placeHolderCharacter="*"
                      [(ngModel)]="dateInput.value"
                      (input)="dispatch(dateInput)"
                      class="mat-input-element mat-datepicker-input"
                      [dropSpecialCharacters]="true"
                      [showMaskTyped]="true"
                      mask="d0/M0/0000"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  <!-- <mat-form-field appearance="outline" class="custom-input">
                   <input
                      matInput
                      formControlName="dob"
                      placeholder="DD/MM/YYYY"
                      [min]="minDate"
                      [matDatepicker]="picker"
                    /> 
                    <input
                      matInput
                      formControlName="dob"
                      [matDatepicker]="picker"
                      placeholder=" "
                      mask="00/00/0000"
                      [showMaskTyped]="true"
                      [dropSpecialCharacters]="false"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field> -->

                  <div
                    *ngIf="f.dob.touched && f.dob.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.dob.errors.required">Dob is required</div>
                    <div *ngIf="f.dob.errors.invalidDate">
                      Please provide valid date
                    </div>
                    <!-- <mat-error *ngIf="f.dob.errors.required">{{
                      getErrorMessage(dateInput.value)
                    }}</mat-error> -->
                    <div *ngIf="f.dob.errors.minlength">
                      Please provide date in DD/MM/YYYY format
                    </div>
                    <!-- <div *ngIf="dob.errors.max">Date must not be in future</div> -->
                  </div>
                </div>
              </div>
              <div class="form_container">
                <div
                  class="radio_btn_container"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                >
                  <p class="form_text">Gender:</p>
                  <div class="gender_container">
                    <mat-radio-group
                      formControlName="sex"
                      aria-label="Select an option"
                      class="custom-input"
                    >
                      <mat-radio-button value="male">Male</mat-radio-button>
                      <mat-radio-button value="female">Female</mat-radio-button>
                    </mat-radio-group>
                    <div
                      *ngIf="f.sex.touched && f.sex.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.sex.errors.required">
                        Gender is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form_container"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <p class="form_text">Relation:</p>
                <div
                  class="relation_container"
                  fxLayoutAlign="space-between center"
                >
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="relation"
                      placeholder=" select one"
                    >
                      <mat-option value="Brother">Brother</mat-option>
                      <mat-option value="Child">Child</mat-option>
                      <mat-option value="Father">Father</mat-option>
                      <mat-option value="Mother">Mother</mat-option>
                      <mat-option value="Sister">Sister</mat-option>
                      <mat-option value="Spouse">Spouse</mat-option>
                      <mat-option value="Other">Other</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    *ngIf="f.relation.touched && f.relation.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.relation.errors.required">
                      Relation is required
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="form_container"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <p class="form_text">Email ID:</p>
                <div class="email_container">
                  <mat-form-field appearance="outline" class="custom-input">
                    <input
                      formControlName="email"
                      matInput
                      type="email"
                      placeholder="xyz@gmail.com"
                    />
                  </mat-form-field>
                  <div *ngIf="f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.pattern">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="form_container"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div
                  fxFlex="50"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <div fxFlex="40"></div>
                  <button
                    mat-stroked-button
                    color="secondary"
                    class="btn btn-close"
                    class="allButton"
                    type="reset"
                    (click)="navigateToConsultPage()"
                  >
                    Close
                  </button>
                  <button
                    [disabled]="addNewMember.invalid || !disabled"
                    mat-raised-button
                    class="allButton"
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </button>
                  <button
                    *ngIf="onsubmit && !disabled"
                    (click)="onEdit()"
                    class="allButton"
                    mat-raised-button
                    color="primary"
                    type="button"
                  >
                    Edit
                  </button>
                </div>
                <div fxFlex="50"></div>
              </div>
            </div>
            <div class="verification_container">
              <div
                class="phone_container"
                fxLayout="row"
                fxLayoutAlign="start center"
                *ngIf="!disabled"
              >
                <p class="phone_text">OTP:</p>
                <div>
                  <mat-form-field appearance="outline" class="custom-input">
                    <input
                      numbersOnly
                      matInput
                      type="text"
                      placeholder="000000"
                      #otpData
                      maxlength="6"
                    />
                    <button
                      mat-button
                      type="button"
                      [disabled]="!otpData?.value?.trim()"
                      class="verify_otp_btn"
                      [routerLink]="consultation"
                      routerLinkActive="active"
                      (click)="onOtpSubmission(otpData.value)"
                    >
                      Verify OTP
                    </button>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
