<mat-card fxLayout="column">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100" >
      <mat-card-title class="title">Past History</mat-card-title>
      <button mat-icon-button (click)="close()">
        <mat-icon >close</mat-icon>
      </button>
    </mat-card-header>
    <div class="past-history" fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
      <p>{{data.dataToDisplay ? data.dataToDisplay : 'No Past History'}}</p>
   </div>
  </mat-card>
 