import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { UtilityService } from '../services/utility.service';
import { URLS } from '../constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    public toastrService: ToasterService,
    private utilityService: UtilityService
  ) {}
  blacklistedRoutes: Array<string | RegExp> = [];
  isBlacklistedRoute(request: HttpRequest<any>): boolean {
    const url = request.url;
    return (
      this.blacklistedRoutes.findIndex((route) =>
        typeof route === 'string'
          ? route === url
          : route instanceof RegExp
          ? route.test(url)
          : false
      ) > -1
    );
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  getCookie(name): any {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    // add authorization header with jwt token if available
    this.utilityService.updateLoaderStatus(true);
    if (!req.withCredentials) {
      // console.log('hello this is the hello ', req);
      return next.handle(req).pipe(
        map((res) => {
          this.utilityService.updateLoaderStatus(false);
          return res;
        })
      );
    } else {
      return this.authService.currentToken
        ? next.handle(this.addToken(req, this.authService.currentToken)).pipe(
            map((res: any) => {
              if (res && res.status && res.status === 200) {
                if (res.body && res.body.statusCode === -7) {
                  throw res;
                }
              }
              this.utilityService.updateLoaderStatus(false);
              return res;
            }),
            catchError((err) => {
              if (err.status === 200) {
                if (!err.url.includes('get')) {
                  if (
                    !err?.url?.includes(
                      '/doctor-dashboard' || '/doctorDetailsteam'
                    )
                  ) {
                    this.toastrService.pop(
                      'error',
                      null,
                      err.body.message.toUpperCase()
                    );
                  }
                }
              } else if (err.status === 401) {
                this.utilityService.navigate(URLS.LOGIN);
              } else {
                if (
                  err?.status === 404 &&
                  !['/doctor-dashboard', '/doctorDetailsteam'].includes(
                    err?.error?.path
                  )
                ) {
                  this.toastrService.pop(
                    'error',
                    null,
                    err.error.error.toUpperCase()
                  );
                }
              }
              this.utilityService.updateLoaderStatus(false);
              return throwError(err);
            })
          )
        : next.handle(req).pipe(
            map((res) => {
              this.utilityService.updateLoaderStatus(false);
              return res;
            }),
            catchError((err) => {
              this.utilityService.updateLoaderStatus(false);
              return throwError(err);
            })
          );
    }
  }
}
