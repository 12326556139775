import { VitalData } from "./vital-data.model";

export class Vital {
    title = "Vitals";
    name = 'vitals';
    key = "vitals";
    data = new VitalData();

    constructor(vitals = null) {
        if (vitals) {
            this.data = new VitalData(vitals);
        }
    }
}