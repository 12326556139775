<div
  class="consultation_middle_container"
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <div
    class="grid-item"
    class="accordion"
    [ngClass]="expand ? 'expandedAccordion' : ''"
  >
    <mat-accordion>
      <!-- Medical History Start  -->
      <mat-expansion-panel
        [expanded]="accordionExpand[$index]"
        (opened)="onOpenPanel(consultation, $index, true)"
        (closed)="onClosedPanel($index, false)"
        hideToggle="true"
        class="expansion"
        *ngFor="let consultation of consultationData; let $index = index"
      >
        <mat-expansion-panel-header
          *ngIf="consultation.key !== 'blankNotes'"
          class="mat-expansion-panel-header-title"
        >
          <div fxFlex.xl="100" fxFlex="100" fxLayoutAlign="start center">
            <mat-panel-title
              class="mat-panel-title mat_panel_title"
              fxLayout="column"
              fxLayoutAlign="start start"
            >
              <ng-container *ngIf="consultation?.iconPath">
                <img
                  style="width: 30px; height: 30px"
                  [src]="consultation?.iconPath"
                  alt="icon"
                />
              </ng-container>
              <span class="mat_panel_text"> {{ consultation.title }}*</span>
              <div
                *ngIf="selectedItemIndex !== key"
                class="selected_disease_below_title_content"
                fxLayout="row"
              >
                <ng-container *ngIf="!accordionExpand[$index]">
                  <span
                    color="primary"
                    class="selected_disease_below_title"
                    cdkDrag
                  >
                    {{
                      consultation.selectedItems
                        ? getSelectedNames(consultation.selectedItems)
                        : null
                    }}
                  </span>
                  <div
                    class="selected_disease_below_title"
                    *ngIf="consultation.key === 'vitals'"
                  >
                    <p *ngIf="vitalsDataExists">
                      {{ consultation.data.heightFt }}'{{
                        consultation.data.heightInch
                      }}, {{ consultation.data.weight }} Kg,
                      {{ consultation.data.bmiValue }} BMI
                      {{ consultation.data.bmiType }},
                      {{ consultation.data.smoking }} Smoking,
                      {{ consultation.data.alcohol }} Alcohol,
                      {{ consultation.data.tobacco }} Tobacco
                      {{ consultation?.data?.crmVitalsComments }}
                    </p>
                  </div>
                  <span
                    color="primary"
                    class="selected_disease_below_title"
                    cdkDrag
                    *ngIf="consultation.key === 'notes'"
                  >
                    {{ selectedConsult.summaryNotes || summaryNotes }}
                  </span>
                </ng-container>
              </div>
            </mat-panel-title>
            <mat-panel-description class="error-medicines">
              <mat-error
                *ngIf="checkConsultation(consultation, accordionExpand[$index])"
              >
                Please fill all the required fields.
              </mat-error>
            </mat-panel-description>
            <!-- <mat-panel-description>
              <mat-error
                class="error-medicines"
                *ngIf="
                  consultation?.isOpened &&
                  consultation.key === 'medicines' &&
                  !accordionExpand[$index] &&
                  !instructionForm.valid &&
                  !consultation.selectedItems.length
                "
              >
                Please fill all the required fields.
              </mat-error>
            </mat-panel-description> -->
            <!-- <mat-panel-description>
              <mat-error
                class="error-vitals"
                *ngIf="
                  consultation.key === 'vitals' &&
                  !accordionExpand[$index] &&
                  vitalsDataExists &&
                  consultation?.isOpened &&
                  !instructionForm.valid
                "
              >
                Please fill all the required fields.
              </mat-error>
            </mat-panel-description>
            <mat-panel-description>
              <mat-error
                class="error-medical-history"
                *ngIf="
                  consultation?.isOpened &&
                  !accordionExpand[$index] &&
                  consultation.name === 'medical_history' &&
                  !consultation.selectedItems.length
                "
              >
                Please fill all the required fields.
              </mat-error>
            </mat-panel-description>
            <mat-panel-description>
              <mat-error
                class="error-symptoms"
                *ngIf="
                  consultation?.isOpened &&
                  !accordionExpand[$index] &&
                  consultation.name === 'symptoms' &&
                  !consultation.selectedItems.length
                "
              >
                Please fill all the required fields.
              </mat-error>
            </mat-panel-description>
            <mat-panel-description>
              <mat-error
                class="error-provisional-diagnosis"
                *ngIf="
                  consultation?.isOpened &&
                  !accordionExpand[$index] &&
                  consultation.name === 'provisional_diagnosis' &&
                  !consultation.selectedItems.length
                "
              >
                Please fill all the required fields.
              </mat-error>
            </mat-panel-description>
            <mat-panel-description>
              <mat-error
                class="error-allergy"
                *ngIf="
                  consultation?.isOpened &&
                  !accordionExpand[$index] &&
                  consultation.name === 'allergy' &&
                  !consultation.selectedItems.length
                "
              >
                Please fill all the required fields.
              </mat-error>
            </mat-panel-description>
            <mat-panel-description>
              <mat-error
                class="error-test-recommendation"
                *ngIf="
                  consultation?.isOpened &&
                  !accordionExpand[$index] &&
                  consultation.name === 'test_recommendation' &&
                  !consultation.selectedItems.length
                "
              >
                Please fill all the required fields.
              </mat-error>
            </mat-panel-description>
            <mat-panel-description>
              <mat-error
                class="error-instruction"
                *ngIf="
                  consultation?.isOpened &&
                  !accordionExpand[$index] &&
                  consultation.key === 'instruction' &&
                  !consultation.selectedItems.length
                "
              >
                Please fill all the required fields.
              </mat-error>
            </mat-panel-description> -->
            <mat-icon
              *ngIf="
                !accordionExpand[$index] &&
                (consultation.key === 'vitals' || consultation.key === 'notes')
              "
              fxLayoutAlign="end end"
              fxFlex="20"
              >add</mat-icon
            >
            <mat-icon
              *ngIf="
                !accordionExpand[$index] &&
                consultation.selectedItems &&
                consultation.selectedItems.length === 0
              "
              fxLayoutAlign="end end"
              fxFlex="20"
              >add</mat-icon
            >
            <button
              mat-button
              *ngIf="
                consultation.selectedItems &&
                consultation.selectedItems.length > 0 &&
                !accordionExpand[$index]
              "
              fxLayoutAlign="end end"
              fxFlex="20"
            >
              Edit
            </button>
          </div>
        </mat-expansion-panel-header>

        <ng-container
          *ngIf="
            selectedPanelIndex === $index &&
            consultation.key !== 'vitals' &&
            consultation.key !== 'notes'
          "
        >
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="space-between center"
            class="expansion_search_bar"
          >
            <div fxLayoutAlign="end center">
              <p
                mat-button
                (click)="openPastHistory(consultation)"
                fxLayoutAlign="end center"
                class="past_history_button"
              >
                Past History
              </p>
            </div>
            <div
              fxLayout="row"
              fxFlex="80"
              class="search_input"
              fxLayoutAlign="space-around center"
            >
              <div class="mat-form-flied" fxFlex="80">
                <mat-form-field appearance="none" fxFlex="80">
                  <div fxLayoutAlign="space-around center">
                    <div fxFlex.xl="100" fxLayout="row">
                      <mat-icon
                        matPrefix
                        style="transform: rotate(90deg)"
                        class="search_icon"
                        >search</mat-icon
                      >
                      <input
                        matInput
                        type="search"
                        (input)="searchChip(consultation)"
                        [(ngModel)]="searchText"
                        placeholder="Search"
                        class="search-input"
                      />
                      <mat-icon
                        (click)="clearSearch(consultation)"
                        *ngIf="searchText"
                        class="cancelIcon"
                        >cancel</mat-icon
                      >
                    </div>
                  </div>
                </mat-form-field>
              </div>

              <div fxFlex="5" fxLayoutAlign="center center" *ngIf="consultation && consultation?.name !== 'test_recommendation'">
                <button class="add_button" (click)="addNewItem(consultation)">
                  + Add
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="vital_body" *ngIf="consultation.key === 'vitals'">
          <p *ngIf="vitalsDataExists">
            {{ consultation.data.heightFt }}'{{ consultation.data.heightInch }},
            {{ consultation.data.weight }} Kg,
            {{ consultation.data.bmiValue }} BMI
            {{ consultation.data.bmiType }},
            {{ consultation.data.smoking }} Smoking,
            {{ consultation.data.alcohol }} Alcohol,
            {{ consultation.data.tobacco }} Tobacco
            {{ consultation?.data?.crmVitalsComments }}
          </p>
        </div>
        <!-- <span color="primary" class="selected_disease_below_title" cdkDrag *ngIf="consultation.key === 'notes'">
          {{selectedConsult.summaryNotes}}
         </span> -->

        <!-- Expansion Disease Container -->
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxFlex="100"
          fxLayoutAlign="column"
          fxLayoutAlign="start center"
          class="expansion_chips"
          *ngIf="
            consultation.key !== 'vitals' &&
            consultation.key !== 'summaryNotes' &&
            selectedPanelIndex === $index
          "
        >
          <!-- Expansion Disease Data -->
          <div
            fxLayoutAlign="start none"
            fxLayout="column"
            class="disease_container"
            [disabled]="true"
          >
            <!-- Expansion selected Disease Data -->
            <div class="selected_disease_container">
              <mat-chip-list
                class="disease-chip"
                cdkDropList
                cdkDropListOrientation="horizontal"
              >
                <mat-chip
                  color="primary"
                  (click)="onSelectItem($index, $itemIndex, consultation, item)"
                  class="selected_disease_name"
                  cdkDrag
                  *ngFor="
                    let item of consultation.selectedItems;
                    let $itemIndex = index
                  "
                >
                  {{ item.name }}
                  <mat-icon
                    (click)="
                      onDeselectedConsultationItem(
                        item,
                        consultation,
                        $index,
                        $itemIndex
                      )
                    "
                    >cancel
                  </mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
            <!-- Expansion non selected Disease Data -->
            <mat-chip-list
              *ngIf="consultation.data && consultation.data.length"
              class="disease-chip"
              cdkDropList
              cdkDropListOrientation="horizontal"
            >
              <mat-chip
                (click)="
                  onSelectedConsultationItem(
                    item,
                    consultation,
                    $index,
                    $itemIndex
                  )
                "
                class="disease-box"
                cdkDrag
                *ngFor="let item of consultation.data; let $itemIndex = index"
              >
                {{ item.name }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <!-- Expansion Disease Data -->
      </mat-expansion-panel>
      <!-- Medical History End  -->
    </mat-accordion>
  </div>

  <div class="right_container">
    <app-consultation-right
      *ngIf="instructionForm && selectedConsult?.name !== 'instruction'"
      [instructionForm]="instructionForm"
      [selectedConsultation]="selectedConsultation"
      [(consultationData)]="selectedConsult"
      [options]="{
        consultationIndex: selectedPanelIndex,
        itemIndex: selectedItemIndex
      }"
      [ngClass]="expand ? 'vedioExpand' : null"
      (vitalsEvent)="saveVitals($event)"
    ></app-consultation-right>
  </div>
</div>
