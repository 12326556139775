<div class="test-history">
  <div class="title">
    <mat-card-title id="heading" fxLayoutAlign="start center"
      >Test History</mat-card-title
    >
  </div>
  <div class="content">
    <mat-card-actions style="margin-left: 18px; margin-bottom: -20px">
      <table id="customers" >
        <thead>
          <tr>
            <th class="txt-left">Test/Package Name</th>
            <th class="txt-left">Date Of Report</th>
            <th class="txt-left">Test Report</th>
          </tr>
        </thead>
        <tbody>
          <!-- *ngFor="let test of testhistory" -->
          <!-- *ngFor="let items of test.title" -->
          <tr *ngFor="let test of testhistory">
            <td>
              <span style="display: block" *ngFor="let item of test.title">
                <a>{{ item?.title }}</a>
              </span>
            </td>
            <td style="color: #7b7b7b">
              {{ test?.date | date: 'dd/MM/yyyy' }}
            </td>
            <td>
              <p style="color: #3190ff; font-size: 22px;">
                <a [href]="test?.reportPdfPath" target="_blank"><button mat-button>Open</button></a>
              </p>
            </td>
          </tr>
          
        </tbody>
      </table>
    </mat-card-actions>
  </div>
</div>
