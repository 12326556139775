import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoExpendService {
  _videoExpended = new Subject();
  videoExpended = this._videoExpended.asObservable();
  constructor() { }

  toggleVideoExpended = (value) => {
    this._videoExpended.next(value); 
  }
}
