import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as _ from 'lodash';

import { APIDef, APIInput } from './ApiEndPoint';
import { environment } from 'src/environments/environment';

@Injectable()
export class RestApiService {
  constructor(public http: HttpClient) { }

  public invoke<T>(
    def: APIDef,
    apiInput: APIInput = {},
    data?: T,
    queryMap?: any,
    header?: any
  ): Observable<T> {
    return this.invokeAPI(
      def.api(apiInput),
      def.method,
      data,
      queryMap,
      header,
      !def.noNeedAuthToken
    );
  }

  // function for post
  private invokeAPI<T>(
    api: string,
    method: string,
    body?: T,
    queryMap?: any,
    header?: any,
    needApiAuthToken?: boolean
  ): Observable<T> {
    if (environment.production) {
      if (api.startsWith('hw')) {
        api = api.replace('hw/',environment.hw);
      }
      if (api.startsWith('vs')) {
        api = api.replace('vs/',environment.vs);
      }
    }
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    for (const key in header) {
      if (header.hasOwnProperty(key)) {
        headers = headers.append(key, header[key]);
      }
    }
    let queryParams = new HttpParams();
    if (queryMap !== undefined && queryMap !== null) {
      for (const key of Object.keys(queryMap)) {
        if (queryMap[key] || queryMap[key] === 0 || queryMap[key] === false) {
          if (queryMap[key] instanceof Array) {
            queryMap[key].forEach((item) => {
              queryParams = queryParams.append(`${key.toString()}`, item);
            });
          } else {
            queryParams = queryParams.append(key.toString(), queryMap[key]);
          }
        }
      }
    }
    const httpOptions: Option = {
      headers,
      params: queryParams,
      observe: 'body',
      withCredentials: needApiAuthToken,
    };
    switch (method) {
      case 'POST':
        return this.post<T>(api, body, httpOptions);
      case 'GET':
        return this.get<T>(api, httpOptions);
      case 'PUT':
        return this.put<T>(api, body, httpOptions);
      case 'PATCH':
        return this.patch<T>(api, body, httpOptions);
      case 'DELETE':
        return this.delete<T>(api, httpOptions);
      default:
        break;
    }
  }

  private post<T>(api: string, body: T, httpOptions: Option): Observable<T> {
    return this.http
      .post<T>(api, body, httpOptions)
      .pipe(catchError((err) => this.handleError<T>(err)));
  }

  private get<T>(api: string, httpOptions: Option): Observable<T> {
    return this.http
      .get<T>(api, httpOptions)
      .pipe(catchError((err) => this.handleError<T>(err)));
  }

  private put<T>(api: string, body: T, httpOptions: Option): Observable<T> {
    return this.http
      .put<T>(api, body, httpOptions)
      .pipe(catchError((err) => this.handleError<T>(err)));
  }
  private delete<T>(api: string, httpOptions: Option): Observable<T> {
    return this.http
      .delete<T>(api, httpOptions)
      .pipe(catchError((err) => this.handleError<T>(err)));
  }
  private patch<T>(api: string, body: T, httpOptions: Option): Observable<T> {
    return this.http
      .patch<T>(api, body, httpOptions)
      .pipe(catchError((err) => this.handleError<T>(err)));
  }
  private handleError<T>(error: any): Observable<T> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    }
    return  throwError(error);
    // return an observable with a user-facing error message
  }
}

interface Option {
  headers?:
  | HttpHeaders
  | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?:
  | HttpParams
  | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  needAuthToken?: boolean;
}
