<div class="ayushman-report">
  <mat-card>
    <div div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="10">
        <a (click)="onBack()" fxLayoutAlign="start center">
          <button mat-button>
            <mat-card-title>Back</mat-card-title>
          </button></a
        >
      </div>
      <div>
        <mat-card-title class="heading" fxLayoutAlign="center center">
          {{ selectedTest?.date }} - {{ selectedTest?.name }} - Report
        </mat-card-title>
      </div>
    </div>
  </mat-card>

  <mat-card>
    <div class="report_container">
      <iframe
        [src]="selectedTest.reportPdfPath | safeHTML"
        width="1000"
        height="700"
      ></iframe>
    </div>
  </mat-card>
</div>
