<div>
  <mat-card>
    <mat-card-title>Docs uploaded by patient</mat-card-title>
  </mat-card>
  <!-- <iframe
  src="http://40.65.182.43/crm/Reports/Lab/15060378822_Mr_AMITSINGH_8_4_2020_6_38_57_PM.pdf"
  width="800"
  height="600"
></iframe> -->
  <mat-card>
    <div fxFlex="27" class="image_container" fxLayout="column">
      <p fxLayoutAlign="center center">
        {{ selectedImageIndex + 1 }}/{{ patientUploadedDocs?.length }}
      </p>
      <mat-card
        class="image_content"
        fxLayout="row"
        fxLayoutAlign="start start"
      >
        <ng-container
          aria-selected="true"
          *ngFor="let docs of patientUploadedImage; let $index = index"
        >
          <div
            class="select_image"
            fxLayoutAlign="center center"
            (click)="onImageSelection($index)"
            [class.selected]="$index === selectedImageIndex"
          >
            <img
              *ngIf="docs.extention !== 'pdf'"
              alt="doctor_report_image"
              [src]="docs?.imageThumb"
              class="prescription_thumb"
            />

            <img
              style="width: 100px; height: 100px"
              *ngIf="docs.extention === 'pdf'"
              src="https://www.myesr.org/sites/default/files/media-icons/generic/application-pdf.png"
            />
          </div>
        </ng-container>
      </mat-card>
    </div>
    <!-- image_viewer_section -->
    <div class="image_viewer" fxLayout="column" fxFlex="76">
      <div class="image_viewer_handler" fxLayoutAlign="center center"></div>

      <div
        fxLayout="row"
        class="image_viewer_container"
        fxLayoutAlign="space-around center"
      >
        <div class="previous_image" fxFlex="5" fxLayoutAlign="center center">
          <button
            mat-button
            fxLayoutAlign="center center"
            (click)="prevImage()"
          >
            <mat-icon>navigate_before</mat-icon>
          </button>
        </div>
        <div class="selected_image" fxLayoutAlign="center center">
          <div
            matTooltip="Click to expand"
            matTooltipPosition="above"
            class="zoom-icon pointer"
            *ngIf="!openImageInZoomMode"
            (click)="openImageInZoomMode = true"
          >
            <mat-icon>aspect_ratio</mat-icon>
          </div>
          <div class="img-preview-zoom" *ngIf="openImageInZoomMode">
            <div
              matTooltip="Click to normal view"
              matTooltipPosition="above"
              class="cancel-btn pointer"
              (click)="openImageInZoomMode = false"
            >
              <mat-icon>aspect_ratio</mat-icon>
            </div>
            <img [src]="patientUploadedDocs[selectedImageIndex]" alt="" />
          </div>
          

          <div class="report_container" *ngIf="
          patientUploadedDocs &&
          patientUploadedDocs.length > 0 &&
          patientUploadedImage[selectedImageIndex]?.extention === 'pdf'
        ">
            <iframe
              [src]="patientUploadedDocs[selectedImageIndex] | safeHTML"
              style="width: 500px; height: 600px"
            ></iframe>
          </div>

          <!-- <app-doc-viewer
            *ngIf="
              patientUploadedDocs &&
              patientUploadedDocs.length > 0 &&
              patientUploadedImage[selectedImageIndex]?.extention === 'pdf'
            "
          
            viewer="google"
            style="width: 100%; height: 93vh"
            [url]="patientUploadedDocs[selectedImageIndex]"
            (loaded)="contentLoaded()"
          >
            <div>Loading..</div>
          </app-doc-viewer> -->

          <ngx-image-viewer
            *ngIf="
              patientUploadedDocs &&
              patientUploadedDocs.length > 0 &&
              patientUploadedImage[selectedImageIndex]?.extention !== 'pdf'
            "
            [(index)]="selectedImageIndex"
            [src]="patientUploadedDocs"
            [config]="imageViewerConfig"
            class="ngx_image_viewer"
          ></ngx-image-viewer>
        </div>
        <div class="next_image" fxFlex="5" fxLayoutAlign="center center">
          <button
            mat-button
            fxLayoutAlign="center center"
            (click)="nextImage()"
          >
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card>
</div>
