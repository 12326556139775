import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';
import { SummaryNotes } from 'src/app/shared/models/summary-notes.model';

@Component({
  selector: 'app-notes-comment',
  templateUrl: './notes-comment.component.html',
  styleUrls: ['./notes-comment.component.scss'],
})
export class NotesCommentComponent implements OnInit {
  @Input() options = { consultationIndex: null, itemIndex: null };
  @Input() consultationData: any;
  @Output() changeConsultationData: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly saveNotesEvent = new EventEmitter();

  constructor(private utilityService: UtilityService) {}

  ngOnInit(): void {}

  saveNotes() {
    this.saveNotesEvent.emit(
      new SummaryNotes({
        ...this.consultationData,
        notes: this.consultationData.summaryNotes,
        customerId: this.utilityService.getFromStorage('customerId'),
        consultationId: this.utilityService.getFromStorage('consultationId'),
      })
    );
  }
}
