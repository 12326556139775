import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { JwtHelperService } from '../auth/jwt-helper.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private authService: AuthService,
    private route: Router,
    private readonly jwtService: JwtHelperService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.jwtService.checkUserIsAuthenticated(true)) {
      return true;
    }
    return false;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthenticated: boolean =
      this.jwtService.checkUserIsAuthenticated(false);
    // if (this.authService.getAuthToken()) {
    if (isAuthenticated) {
      return true;
    } else {
      this.jwtService.removeLocalStorage();
      const redirectPath = segments.map((elem) => elem.path).join('/');
      this.route.navigate(['/auth'], {
        queryParams: { redirectPath },
      });
      return false;
    }
  }
}
