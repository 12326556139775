<div class="d-range">
  <div class="consultation_history">
    <mat-card class="mat-card mat-focus-indicator"
      ><mat-card-title class="mat-card-title mat_card_title"
        >D-Range</mat-card-title
      ></mat-card
    ><mat-card class="mat-card mat-focus-indicator mat_focus_indicator">
      <div class="call_history_date" *ngIf="dRangeArray?.length">
        <div fxFlex="75" class="call_history_box">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title
              fxLayoutAlign="center center"
              class="call_history_buttons"
              >Date</mat-card-title
            >
            <ng-container>
              <button
                (click)="previous(i)"
                mat-stroked-button
                class="extend_button"
                fxLayoutAlign="space-around center"
              >
                <mat-icon fxLayoutAlign="center center"
                  >keyboard_arrow_left</mat-icon
                >
              </button>
              <div
                fxLayout="row"
                *ngFor="let dRange of dateRangeArray; let i = index"
                class="call_history_button"
              >
                <button
                  type="button"
                  [ngClass]="
                    dRange?.isSelected
                      ? 'cdk-focused cdk-keyboard-focused button-selected'
                      : ''
                  "
                  (click)="onSelectCurrent(dRange, i)"
                  mat-stroked-button
                  class="call_history_buttons"
                >
                  {{ dRange?.orderDate | dateFormat: "DD/MM/YYYY" }}
                </button>
              </div>
              <button
                (click)="next(i)"
                mat-stroked-button
                class="extend_button"
                fxLayoutAlign="space-around center"
              >
                <mat-icon fxLayoutAlign="center center"
                  >keyboard_arrow_right</mat-icon
                >
              </button>
            </ng-container>
          </div>
        </div>
        <div fxFlex="25"></div>
      </div>
    </mat-card>
  </div>

  <mat-card>
    <mat-card-title class="heading"
      >{{ selectedDRange?.orderDate | dateFormat: "DD/MM/YYYY" }} Report , D-
      Range Parameters</mat-card-title
    >

    <div fxLayout="column" class="d_range_container">
      <mat-card-actions class="titlelist">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <mat-card-title fxFlex="25">Name</mat-card-title>
          <mat-card-title fxFlex="25" fxLayoutAlign="space-around center"
            >Value</mat-card-title
          >
          <mat-card-title fxFlex="25" fxLayoutAlign="space-around center"
            >Range</mat-card-title
          >
          <mat-card-title fxFlex="25" fxLayoutAlign="space-around center"
            >D range</mat-card-title
          >
        </div>
      </mat-card-actions>

      <ng-container *ngIf="selectedDRange?.reports?.length; else noRecord">
        <mat-card-actions
          class="titlevalue"
          *ngFor="let d of selectedDRange?.reports"
        >
          <div fxLayout="row">
            <mat-card-title fxFlex="25">{{ d?.name }}</mat-card-title>
            <mat-card-subtitle
              fxFlex="25"
              fxLayoutAlign="space-around center"
              >{{ d?.value }}</mat-card-subtitle
            >
            <mat-card-subtitle
              fxFlex="25"
              fxLayoutAlign="space-around center"
              >{{ d?.range }}</mat-card-subtitle
            >
            <mat-card-subtitle
              fxFlex="25"
              fxLayoutAlign="space-around center"
              >{{ d?.drange }}</mat-card-subtitle
            >
          </div>
        </mat-card-actions>
      </ng-container>
      <ng-template #noRecord>
        <mat-card-subtitle fxFlex="100" fxLayoutAlign="center"
          >No record found</mat-card-subtitle
        >
      </ng-template>
    </div>
  </mat-card>
</div>
