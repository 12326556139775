import { Component, OnInit } from '@angular/core';

import { DocsUploadedByPatientService } from 'src/app/core/services/docs-uploaded-by-patient.service';
import { DocsUploadedByPatient } from 'src/app/shared/models/docsUploadedByPatient.model';

@Component({
  selector: 'app-docs-uploaded-by-patient',
  templateUrl: './docs-uploaded-by-patient.component.html',
  styleUrls: ['./docs-uploaded-by-patient.component.scss'],
})
export class DocsUploadedByPatientComponent implements OnInit {
  patientUploadedDocs: DocsUploadedByPatient[] = [];
  patientUploadedImage: DocsUploadedByPatient[] = [];
  openImageInZoomMode = false;
  selectedImageIndex = 0;
  scale = 1;
  imageViewerConfig = {
    allowFullscreen: false,
    zoomFactor: 0.1,
    wheelZoom: false,
    btnShow: {
      fullScreenView: false,
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: true,
      rotateCounterClockwise: true,
    },
  };
  isDocumentLoaded: boolean = true;
  constructor(private docsUploadedBypatient: DocsUploadedByPatientService) {}

  ngOnInit(): void {
    this.getPatientUploadedDoc();
  }

  onImageSelection(idx: number) {
    this.selectedImageIndex = idx;
    this.isDocumentLoaded = false;
  }

  contentLoaded() {
    this.isDocumentLoaded = true;
  }

  getPatientUploadedDoc() {
    this.docsUploadedBypatient.getDocsUploadedByPatient({}).subscribe((res) => {
      console.log({ res });
      this.patientUploadedDocs = res.map((elem) => elem.imageUrl);
      this.patientUploadedImage = res;
    });
  }

  nextImage = () => {
    if (this.selectedImageIndex + 1 < this.patientUploadedDocs.length - 1) {
      this.selectedImageIndex++;
    } else {
      this.selectedImageIndex = 0;
    }
  };

  prevImage = () => {
    if (this.selectedImageIndex > 0) {
      this.selectedImageIndex--;
    } else {
      this.selectedImageIndex = this.patientUploadedDocs.length - 1;
    }
  };
}
