import { Pipe, PipeTransform } from '@angular/core';

import { MomentService } from '../../core/services/moment.service';

@Pipe({ name: 'dateFormat' })
export class FormatDatePipe implements PipeTransform {
  constructor(private readonly momentService: MomentService) {}
  transform(value: any, dateFormat: string): string {
    if (!value) {
      return '';
    }
    return this.momentService.formatDate(new Date(value), dateFormat);
  }
}
