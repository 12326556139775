import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CharacterOnlyDirective } from './character-only.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { MobileNumberDirective } from './mobile-number.directive';

@NgModule({
  declarations: [
    CharacterOnlyDirective,
    NumbersOnlyDirective,
    MobileNumberDirective,
  ],
  exports: [
    CharacterOnlyDirective,
    NumbersOnlyDirective,
    MobileNumberDirective,
  ],
  imports: [CommonModule],
})
export class DirectiveModule {}
