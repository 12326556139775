import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bmi-calculator',
  templateUrl: './bmi-calculator.component.html',
  styleUrls: ['./bmi-calculator.component.scss']
})
export class BmiCalculatorComponent implements OnInit {

 @Input() bmiValue: any;
  
  constructor() { 
    
  }

  ngOnInit(): void {
    
    
  }

}
