import { UtilityService } from "src/app/core/services/utility.service";



export class DoctorDetails {
    doctorName = null;
    profile = null;
    age = null;

    constructor(doctorDetails = null) {
        if (doctorDetails) {
            this.doctorName = doctorDetails.doctorName;
            this.profile = doctorDetails.profile;
            this.age = UtilityService.calculateAge(UtilityService.epochToLocalDateConverter(doctorDetails.dob));
        }
    }

}