import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/core/services/utility.service';
import { VitalData } from 'src/app/shared/models/vital-data.model';

enum BMI_TYPE {
  Underweight = 'Underweight',
  Normal = 'Normal',
  Overweight = 'Overweight',
  Obese = 'Obese'
}

@Component({
  selector: 'app-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.scss'],
})
export class VitalsComponent implements OnInit {
  @Input() options = { consultationIndex: null, itemIndex: null };
  @Input() consultationData: any;
  @Output() readonly saveVitalsEvent = new EventEmitter();
  vitialForm: FormGroup;
  bmiValue: any;
  value = 'Na';

  constructor(
    public formBuilder: FormBuilder,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.createVitalForm();
    this.setCreateFormData();
  }

  keytab = (event) => {
    const element: any = document.getElementById(event);
    if (element == null) {
      return;
    } else {
      element.focus();
    }
  };

  setCreateFormData = () => {
    this.computeBMI(this.consultationData.data);
    this.vitialForm.patchValue(this.consultationData.data);
  };

  createVitalForm = () => {
    this.vitialForm = this.formBuilder.group({
      heightFt: [
        null,
        Validators.compose([Validators.required, Validators.max(7)]),
      ],
      heightInch: [
        null,
        Validators.compose([Validators.required, Validators.max(11)]),
      ],
      weight: [null, Validators.compose([Validators.required, Validators.max(200)])],
      bmiValue: [null, Validators.required],
      crmVitalsComments: null,
      smoking: [null, Validators.required],
      alcohol: [null, Validators.required],
      tobacco: [null, Validators.required],
    });
  };

  computeBMI = ({ weight, heightFt, heightInch }) => {
    if (weight && heightFt <= 7  && heightInch <= 11 && heightFt >= 1 && weight <=200 ) {
      let Vitals = new VitalData();
      const finalHeight = heightFt * 30.48 + heightInch * 2.54;
      this.bmiValue = (weight / ((finalHeight * finalHeight) / 10000)).toFixed(
        2
      );
      // console.log(finalHeight);
      
    } else {
      this.bmiValue = 0;
    }
    this.vitialForm.get('bmiValue').setValue(this.bmiValue);
  };

  calculateBMI() {
    this.computeBMI(this.vitialForm.value);
    this.saveVitals();
  }

  calculateBmiType(): string {
    let value = 'N/A'
    if (this.bmiValue <= 18.5 && this.bmiValue !== 0) {
      value =  BMI_TYPE.Underweight;
    }
    if (this.bmiValue >= 18.6 && this.bmiValue <= 24.99) {
      value = BMI_TYPE.Normal;
    }
    if (this.bmiValue >= 25 && this.bmiValue <= 29.99) {
      value = BMI_TYPE.Overweight;
    }
    if (this.bmiValue > 30) {
      value = BMI_TYPE.Obese;
    }
    return value;
  }

  saveVitals() {
    if (this.vitialForm.valid) {
      this.computeBMI(this.vitialForm.value);
      this.saveVitalsEvent.emit(
        new VitalData(
          {
            ...this.vitialForm.value,
            customerId: this.utilityService.getFromStorage('customerId'),
            consultationId:
              this.utilityService.getFromStorage('consultationId'),
            bmiType: this.calculateBmiType(),
          },
          true
        )
      );
    }
  }
}
