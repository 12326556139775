<mat-toolbar
  [ngClass]="
    fromDashboard
      ? 'dashboard_header consultation_header_container'
      : 'consultation_header consultation_header_container'
  "
  fxLayout="row"
  fxLayoutAlign="start center"
  fxFlex="100"
>
  <div
    class="user_profile border-right"
    *ngIf="!fromDashboard"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxFlex="20"
  >
    <div class="doc_profile_img_container" fxLayoutAlign="center center">
      <div class="doc_profile_img">
        <img [src]="userdetails.profileImage" alt="" />
        <!-- <img [src]="details.profileImage" alt=""> -->
      </div>
    </div>

    <div
      class="user_details"
      fxLayout="column"
      fxLayoutAlign="space-around start"
      fxFlex="80"
    >
      <p class="user_name">{{ userdetails.username }}</p>
      <p class="user_gender user_info">
        {{ userdetails.gender }}, {{ userdetails.age }}
      </p>
    </div>
  </div>
  <div
    class="user_profile"
    *ngIf="fromDashboard"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxFlex="20"
  >
    <div class="doc_profile_img_container" fxLayoutAlign="center center">
      <div class="doc_profile_img" >
        <img [src]="doctordetails.profile" alt="" />
        <!-- <mat-menu #menu="matMenu" (click)="showLogout()">
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu> -->
      </div>
    </div>
    <div
      class="user_details"
      fxLayout="column"
      fxLayoutAlign="space-around start"
      fxFlex="80"
    >
      <p class="user_name">
        {{ doctordetails.doctorName }}
        <button
          class="Online_status"
          mat-button
          [matMenuTriggerFor]="onlinemenu"
        >
          <mat-icon class="mat-icon">keyboard_arrow_down</mat-icon>
        </button>
      </p>
      <mat-menu #onlinemenu="matMenu">
        <p class="Online_status_menu" (click)="onOfflineOnline('online')">
          <span class="dot_menu"></span>Online
        </p>
        <p class="Online_status_menu" (click)="onOfflineOnline('offline')">
          <span class="dot_menu_offline"></span> Offline
        </p>
        <p class="Online_status_menu logoutDiv" (click)="logout()">
          <mat-icon class="logoutIcon">power_settings_new</mat-icon> 
          <span class="logoutText">Logout</span>
        </p>
        <!-- <button mat-menu-item (click)="logout()">Logout</button> -->
        <!-- <button mat-menu-item (click)="logout()">
          <mat-icon>power_settings_new</mat-icon>(click)="toggle()"
          <span>Logout</span>
        </button> -->
      </mat-menu>
      <p *ngIf="noInternetConnection" class="Online_status">
        <span class="dot"></span> Online
      </p>
      <p *ngIf="!noInternetConnection" class="Online_status">
        <span class="dot_offline"></span> Offline
      </p>
      <p class="user_gender user_info">{{ doctordetails.age }}</p>
    </div>
  </div>
  <div *ngIf="!noInternetConnection" class="offlineCard">
    <mat-card>
      You are offline... 
    </mat-card>
  </div>
  <div
    *ngIf="!fromDashboard"
    class="header_item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedTabIndex">
        <nav>
          <mat-tab>
            <ng-template mat-tab-label>
              <a
                routerLink="/consultation"
                routerLinkActive="active"
                fxLayoutAlign="center center"
                class="heading_consult"
                (click)="vedioExpand()"
              >
                <div class="title_icon">
                  <img src="assets/header_icons/consult.png" alt="" />
                </div>
                <div class="title_icon title_icon_blue">
                  <img src="assets/header_icons/consult-blue.png" alt="" />
                </div>
                <p>Consult</p>
              </a>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <a
                routerLink="/consultation/docs-uploaded-by-patient"
                routerLinkActive="active"
                fxLayoutAlign="center center"
                (click)="vedioExpand()"
              >
                <div class="title_icon docs_icon">
                  <img src="assets/header_icons/Upload.png" alt="" />
                </div>
                <div class="title_icon title_icon_blue docs_icon">
                  <img src="assets/header_icons/upload-blue.png" alt="" />
                </div>
                <p>Docs uploaded by patient</p>
              </a>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <a
                routerLink="/consultation/testhistory"
                routerLinkActive="active"
                fxLayoutAlign="center center"
                (click)="vedioExpand()"
              >
                <div class="title_icon">
                  <img src="assets/header_icons/test-history.png" alt="" />
                </div>
                <div class="title_icon title_icon_blue">
                  <img src="assets/header_icons/test-history-blue.png" alt="" />
                </div>
                <p>Test History</p>
              </a>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <a
                routerLink="/consultation/drange"
                routerLinkActive="active"
                fxLayoutAlign="center center"
                (click)="vedioExpand()"
              >
                <div class="title_icon">
                  <img src="assets/header_icons/d-range.png" alt="" />
                </div>
                <div class="title_icon title_icon_blue">
                  <img src="assets/header_icons/d-range-blue.png" alt="" />
                </div>
                <p>D-Range</p>
              </a>
            </ng-template>
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
              <a
                routerLink="/consultation/consultationhistory"
                routerLinkActive="active"
                fxLayoutAlign="center center"
                (click)="vedioExpand()"
              >
                <div class="title_icon">
                  <img src="assets/header_icons/consult-history.png" alt="" />
                </div>
                <div class="title_icon title_icon_blue">
                  <img
                    src="assets/header_icons/consultation-history-blue.png"
                    alt=""
                  />
                </div>
                <p>Consultation History</p>
              </a>
            </ng-template>
          </mat-tab>
        </nav>
      </mat-tab-group>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="end end"
      class="prescription_container"
      fxLayoutAlign="end center"
    >
      <button
        class="dummy_call"
        mat-button
        color="primary"
        (click)="openDialog()"
      >
        <span>Call Dispose</span>
      </button>
      <button
        mat-raised-button
        class="btn preview-btn"
        (click)="openDummyCall()"
       
      >
        PREVIEW/SEND PRESCRIPTION
      </button>
      <!-- <div *ngIf="dummyCall">
        <button
          mat-raised-button
          class="btn send-btn"
          (click)="sendPrescription()"
        >
          SEND PRESCRIPTION
        </button>
      </div> -->
      <div matTooltip="Waiting Queue Count" class="export_option callWaitingQueueCount">
        <span>{{callWatingCount}}</span>
      </div>
    </div>
  </div>

  <div
    *ngIf="fromDashboard"
    class="header_item"
    fxLayout="row"
    fxLayoutAlign="end"
    fxFlex="1 1 auto"
  >
    <!-- <button
      class="dummy_call"
      mat-button
      color="primary"
      (click)="openCallModal()"
    >
      <span>Call notification</span>
    </button> -->
  </div>
</mat-toolbar>
<!-- <button class="logout-btn" *ngIf="logoutButton" mat-button (click)="logout()">Logout</button> -->
