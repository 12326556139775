import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { APIEndPoint } from 'src/app/core/https/ApiEndPoint';
import { RestApiService } from 'src/app/core/https/rest-api.service';
import { UserDetailsService } from 'src/app/core/services/userDetails.service';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-change-user-details',
  templateUrl: './change-user-details.component.html',
  styleUrls: ['./change-user-details.component.scss'],
})
export class ChangeUserDetailsComponent implements OnInit, OnDestroy {
  clearTimeout: any;
  userDetails: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangeUserDetailsComponent>,
    private utilityService: UtilityService,
    private restAPIService: RestApiService,
    private userDetailsService: UserDetailsService
  ) {}

  ngOnInit(): void {}

  openDialog() {
    const dialogRef = this.dialog.open(ChangeUserDetailsComponent);
    dialogRef.afterClosed().subscribe(() => {});
  }

  continue() {
    this.dialogRef.close(true);
  }

  removeAllContinue() {
    this.dialogRef.close(true);
    this.restAPIService
      .invoke(APIEndPoint.REMOVE_ALL_DATA, null, {
        customerId: this.data.selectedPatient.customerId,
        consultationId: this.utilityService.getFromStorage('consultationId'),
      })
      .subscribe(() => {
        this.utilityService.saveToStorage(
          'customerId',
          this.data.selectedPatient.customerId ||
            this.data.selectedPatient.customerID
        );
        this.userDetailsService.setUserData(
          this.data.selectedPatient.customerId ||
            this.data.selectedPatient.customerID
        );
      });
  }

  updateCallStatus = (status) => {
    const reqBody = {
      callStatus: status,
      consultationId: this.utilityService.getFromStorage('consultationId'),
      customerId: this.utilityService.getFromStorage('customerId'),
    };
    this.restAPIService
      .invoke(APIEndPoint.UPDATE_CALL_LANDING, null, reqBody)
      .subscribe(() => {});
  };

  ngOnDestroy(): void {
    clearTimeout(this.clearTimeout);
  }
}
