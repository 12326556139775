import { UtilityService } from "src/app/core/services/utility.service";

export class DocsUploadedByPatient {
    id = null;
    imageThumb = null;
    imageUrl = null;
    extention = null

    constructor(docsUploadedByPatient = null) {
        if (docsUploadedByPatient) {
            this.id = docsUploadedByPatient.docId;
            this.imageUrl = docsUploadedByPatient.imageUrl;
            this.imageThumb = docsUploadedByPatient.imageThumb;
            this.extention = docsUploadedByPatient.extention
        }
    }

}




