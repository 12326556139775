import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vedio-call',
  templateUrl: './vedio-call.component.html',
  styleUrls: ['./vedio-call.component.css']
})
export class VedioCallComponent implements OnInit {
  micOnSwitch:boolean = true;
  micOffSwitch: boolean = false;
  expand:boolean = false
  constructor() { }
  onSwitch(){
    this.micOnSwitch = ! this.micOnSwitch
    this.micOffSwitch = ! this.micOffSwitch ;
  }
  
  
  expandWidth(){
  this.expand =! this.expand
  }
  ngOnInit(): void {
  }

}
