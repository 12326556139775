export class SelectValue {
    id: number;
    name = '';
    comment = '';

    constructor(selectedValue = null) {
        if (selectedValue) {
            this.id = selectedValue.id;
            this.name = selectedValue.name;
            this.comment = selectedValue.comment ? selectedValue.comment : this.comment;
        }
    }
}