import { Injectable } from '@angular/core';
import { AuthEnum } from '../models/auth-token';
import { UtilityService } from '../services/utility.service';
import { DoctorCallService } from '../services/doctor-call.service';
import { URLS } from '../constants';


export const enum StorageEnum {
  CONSULTATION_ID = 'consultationId',
  CUSTOMER_ID = 'customerId',
  MED_TABLETS_PER_DAY = 'medTabletsPerDay',
  SELECTED_TEST = 'selectedTest',
  ROOM_ID = 'roomId',
  PRESCRIPTION_STATUS = "prescriptionStatus"
}
@Injectable({
  providedIn: 'root',
})
export class JwtHelperService {
  tokenGetter: () => string;
  constructor(
    private readonly utilityService: UtilityService,
    private readonly doctorCallService: DoctorCallService
  ) {
    this.tokenGetter = function tokenGetter() {
      return localStorage.getItem(AuthEnum.ACCESS_TOKEN);
    };
  }

  public urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }
    return this.b64DecodeUnicode(output);
  }

  // credits for decoder goes to https://github.com/atk
  private b64decode(str: string): string {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';

    str = String(str).replace(/=+$/, '');

    if (str.length % 4 === 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded."
      );
    }

    for (
      // initialize result and counters
      let bc = 0, bs: any, buffer: any, idx = 0;
      // get next character
      (buffer = str.charAt(idx++));
      // character found in table? initialize bit storage and add its ascii value;
      // tslint:disable-next-line:no-bitwise
      ~buffer &&
      ((bs = bc % 4 ? bs * 64 + buffer : buffer),
      // and if not first of each 4 characters,
      // convert the first 8 bits to one ascii character
      bc++ % 4)
        ? // tslint:disable-next-line:no-bitwise
          (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }

  private b64DecodeUnicode(str: any) {
    return decodeURIComponent(
      Array.prototype.map
        .call(this.b64decode(str), (c: any) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  public decodeToken(token: string = this.tokenGetter()): any {
    if (token === null) {
      return null;
    }

    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error(
        "The inspected token doesn't appear to be a JWT. " +
          'Check to make sure it has three parts and see https://jwt.io for more.'
      );
    }

    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token.');
    }

    return JSON.parse(decoded);
  }

  public getTokenExpirationDate(token: string = this.tokenGetter()): Date {
    let decoded: any;
    decoded = this.decodeToken(token);

    if (!decoded.hasOwnProperty('exp')) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  public isTokenExpired(
    token: string = this.tokenGetter(),
    offsetSeconds?: number
  ): boolean {
    if (token === null || token === '') {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;

    if (date === null) {
      return true;
    }

    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  getRoles = () => {
    return;
  };

  private redirectToLogin(): void {
    this.utilityService.navigate(URLS.LOGIN);
  }

  public removeLocalStorage(): void {
    window.localStorage.removeItem(AuthEnum.ACCESS_TOKEN);
    window.localStorage.removeItem(AuthEnum.REFRESH_TOKEN);
    window.localStorage.removeItem(StorageEnum.CONSULTATION_ID);
    window.localStorage.removeItem(StorageEnum.CUSTOMER_ID);
    window.localStorage.removeItem(StorageEnum.ROOM_ID);
    window.localStorage.removeItem(StorageEnum.MED_TABLETS_PER_DAY);
    window.localStorage.removeItem(StorageEnum.SELECTED_TEST);
  }

  public logoutUser(): void {
    this.removeLocalStorage();
    this.redirectToLogin();
    this.doctorCallService.offline().subscribe();
    this.doctorCallService.setMessage(null);
    this.doctorCallService.checkcall(false);
  }

  private isEmpty(val: any): boolean {
    return (
      val === null ||
      val === undefined ||
      val === '' ||
      val === 'undefined' ||
      val === 'null'
    );
  }

  public checkUserIsAuthenticated(isRoute: boolean = false): boolean {
    const token: string | undefined = window.localStorage.getItem(
      AuthEnum.ACCESS_TOKEN
    );
    if (!this.isEmpty(token)) {
      const isExpired: boolean = this.isTokenExpired(token);
      if (isExpired) {
        this.removeLocalStorage();
        this.doctorCallService.offline().subscribe();
        if (isRoute) {
          this.redirectToLogin();
        }
        return false;
      }
      return true;
    } else {
      this.removeLocalStorage();
      if (isRoute) {
        this.redirectToLogin();
      }
      return false;
    }
  }
}
