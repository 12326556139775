<div
  [ngClass]="
    expand
      ? 'cosultation_family_container_expand'
      : 'cosultation_family_container'
  "
>
  <div
    fxLayout="row"
    fxLayoutAlign="space-around center"
    [ngClass]="
      expand
        ? '  maxSize_vedio_calling_container'
        : 'minSize_vedio_calling_container'
    "
  >
    <div class="patient_vedio" fxFlex="100" fxLayout="column" style="position: relative;">
      <div
        fxLayout="row"
        fxFlex="100"
        [ngClass]="!isPreviewing ? 'preview-section' : ''"
      ><div class="expandIconBox" *ngIf="isPreviewing">
        <div class="expand_icon">
          <button
            class="pointer"
            (click)="expandWidth('expand')"
            *ngIf="expandIcon"
          >
            <img src="assets/vedio_calling_btns/expand.png" alt="expandIcon" />
          </button>
          <button
            class="pointer"
            (click)="expandWidth('collapse')"
            *ngIf="collapseIcon"
          >
            <img
              src="assets/vedio_calling_btns/collapse.png"
              alt="collapseIcon"
            />
          </button>
        </div>
        <div class="desMsg">Video disconnected...</div>
        </div>
        <div
          [style.display]="!isPreviewing ? 'block' : 'none'"
          id="local-media"
          #localMedia
        >
          <div *ngIf="isInitializing">No preview...</div>
        </div>
        <ng-container>
          <div
            [style.display]="isPreviewing ? 'block' : 'none'"
            id="remote-media"
            style="position: relative"
            #remoteMedia
          ></div>
        </ng-container>
      </div>
      <div
        class="vedio_calling_btns"
        fxLayout="row"
        fxFlex="100"
        style="z-index: 9"
        *ngIf="isPreviewing"
        fxLayoutAlign="space-around center"
      >
        <div
          class="call_duration_display"
          fxFlex="40"
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngIf="activeRoom?.isRecording"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <div class="call_status icon" fxLayoutAlign="center center"></div>
            <p *ngIf="false" fxLayoutAlign="center center">2:00</p>
          </div>
        </div>
        <div
          [ngClass]="activeRoom?.isRecording === true ? 'ml-40' : ''"
          class="call_switch_btn"
          fxFlex="20"
          *ngIf="activeRoom"
          fxLayoutAlign="space-around center"
        >
          <div class="vedio_cam_icon pointer" (click)="togglePreviewCamera()">
            <img src="assets/vedio_calling_btns/video.png" alt=" " />
          </div>
          <button
            class="mic_switch pointer"
            fxLayoutAlign="center center"
            (click)="onSwitch()"
          >
            <mat-icon (click)="muteAudio()" *ngIf="!isAudioMute"
              >mic_on</mat-icon
            >
            <mat-icon (click)="unMuteAudio()" *ngIf="isAudioMute"
              >mic_off</mat-icon
            >
          </button>
        </div>
        <div
          *ngIf="activeRoom"
          class="call_pick_drop_btn pointer"
          fxFlex="40"
          fxLayoutAlign="end center"
        >
          <div class="call_drop_icon">
            <img
              (click)="disconnectRoom()"
              src="assets/vedio_calling_btns/end-call.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="expand ? '  onExpand' : 'offExpand'">
    <div class="doc_upload" fxLayout="row" fxLayoutAlign="start center">
      <a
        routerLink="/consultation/docs-uploaded-by-patient"
        routerLinkActive="active"
        fxLayoutAlign="center center"
        style="text-decoration: none"
      >
        <div class="title_icon">
          <img src="assets/header_icons/upload-blue.png" alt="" />
        </div>
        <p class="upload_text">Docs uploaded by patient</p>
      </a>
    </div>

    <div class="add_member_container">
      <div class="add_member">
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header class="title_container">
              <mat-panel-title class="title"> Family </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div
                class="search_container"
                fxLayout="row"
                fxFlexLayoutAlign="space-between center"
              >
                <p fxFlex="20" class="phone_text">Phone</p>
                <div fxFlex="80" class="search_content">
                  <mat-form-field
                    appearance="outline"
                    class="custom-input"
                    fxFlex="100"
                  >
                    <div class="search_bar">
                      <mat-icon
                        fxFlex="10"
                        fxFlexLayoutAlign="space-between center"
                        class="search_icon"
                        >search
                      </mat-icon>
                      <input
                        matInput
                        maxLength="10"
                        type="text"
                        numbersOnly
                        placeholder="Search"
                        fxFlex="60"
                        (key.enter)="searchFamilyByNumber()"
                        [(ngModel)]="value"
                      />
                      <mat-icon
                        *ngIf="value"
                        (click)="value = ''"
                        style="font-size: 22px"
                        >cancel</mat-icon
                      >
                    </div>
                  </mat-form-field>
                  <button
                    (click)="searchFamilyByNumber()"
                    [disabled]="!(value?.toString()?.length === 10)"
                    mat-raised-button
                    color="primary"
                    class="search_btn"
                  >
                    Search
                  </button>
                </div>
              </div>
              <div class="family_details">
                <!-- <div fxLayout="row" fxLayoutAlign="start center">
                  <div
                    fxFlex="20"
                    fxLayoutAlign="center center"
                    class="add_member_icon_container"
                  >
                    <button
                      mat-fab
                      color="primary"
                      fxLayoutAlign="center center"
                    >
                      <a
                        routerLink="/consultation/addmemberform"
                        fxLayoutAlign="center center"
                      >
                        <mat-icon
                          fxLayoutAlign="center center"
                          class="add_member_icon"
                          >add</mat-icon
                        >
                      </a>
                    </button>
                  </div>
                  <div fxFlex="10"></div>
                  <div fxFlex="70">
                    <div mat-button class="add_member_btn">
                      <a routerLink="/consultation/addmemberform">
                        <p color="primary" class="title">Add Member</p>
                      </a>
                    </div>
                  </div>
                </div> -->

                <div *ngFor="let patient of patients">
                  <div
                    class="patient_lists"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    (click)="openChangeUserDetails(patient)"
                    [ngClass]="MemberId == patient.customerId ? 'selected' : ''"
                  >
                    <div
                      class="img_relation"
                      fxFlex="20"
                      fxLayoutAlign="center center"
                      fxLayout="column"
                    >
                      <div class="patient_img">
                        <img [src]="patient.imageUrl" alt="no img" />
                      </div>

                      <p class="patient_relation patient_info">
                        {{ patient.relation }}
                      </p>
                    </div>
                    <div fxFlex="10"></div>
                    <div
                      class="patient_details"
                      fxFlex="70"
                      fxLayout="column"
                      fxLayoutAlign="space-between start"
                    >
                      <p class="patient_name">
                        {{ patient.firstName }} {{ patient.lastName }}
                      </p>
                      <p class="patient_gender patient_info">
                        {{ patient.gender }}, {{ patient.dob }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>

  <div class="prescription_Notes comment_box">
    <div
      *ngIf="false"
      class="prescription_Notes comment_box"
      [ngClass]="expand ? '  offExpand' : 'onExpand'"
    >
      <div fxLayout="column" class="comment_box_container">
        <div>
          <mat-card-title class="title">Prescription Notes</mat-card-title>
        </div>
        <div fxLayoutAlign="space-between center">
          <textarea
            [ngClass]="expand ? '  comment_box_height' : null"
            name=""
            id=""
            rows="10"
            placeholder="Enter Comment Here..."
          ></textarea>
        </div>
      </div>
    </div>
    <div class="comment_box" fxLayout="column">
      <div fxLayout="column" class="comment_box_container" fxFlex.lg="100">
        <div>
          <mat-card-title class="title">Doctor Notes*</mat-card-title>
        </div>
        <div fxLayoutAlign="space-between center">
          <textarea
            (blur)="saveDoctorNotes()"
            [(ngModel)]="doctorNotes"
            [ngClass]="expand ? 'comment_box_height' : null"
            name=""
            id=""
            rows="10"
            placeholder="Enter Comment Here..."
          ></textarea>
        </div>
        <mat-panel-description>
          <mat-error
            class="error-instruction"
            *ngIf="!doctorNotes && isTouched"
          >
            Please fill all the required fields.
          </mat-error>
        </mat-panel-description>
      </div>
    </div>
  </div>
</div>
