import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToasterService } from 'angular2-toaster';

import { APIEndPoint } from 'src/app/core/https/ApiEndPoint';
import { RestApiService } from 'src/app/core/https/rest-api.service';
import { MiddleService } from 'src/app/core/services/middle.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { MomentService } from '../../../../../../core/services/moment.service';

export const durationConstants = [
  {
    label: '1d',
    value: 1,
  },
  {
    label: '2d',
    value: 2,
  },
  {
    label: '3d',
    value: 3,
  },
  {
    label: '4d',
    value: 4,
  },
  {
    label: '5d',
    value: 5,
  },
  {
    label: '1w',
    value: 7,
  },
  {
    label: '10d',
    value: 10,
  },
  {
    label: '2w',
    value: 14,
  },
  {
    label: '15d',
    value: 15,
  },
  {
    label: '3w',
    value: 21,
  },
  {
    label: '4w',
    value: 28,
  },
  {
    label: '1m',
    value: 30,
  },
  {
    label: '2m',
    value: 60,
  },
  {
    label: '3m',
    value: 90,
  },
  {
    label: '6m',
    value: 180,
  },
];

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.css'],
})
export class PrescriptionComponent implements OnInit {
  displayedColumns: string[] = [
    'sr_number',
    'name',
    'frequency',
    'duration',
    'notes',
    'comments',
  ];
  consultationDetails: any;
  date = new Date();
  constructor(
    public restAPIService: RestApiService,
    public utilityService: UtilityService,
    public toastrService: ToasterService,
    public dialog: MatDialog,
    private readonly momentService: MomentService,
    public dialogRef: MatDialogRef<PrescriptionComponent>,
    private readonly middleService: MiddleService
  ) {}

  ngOnInit(): void {
    this.getConsultationData();
  }

  getConsultationData() {
    const reqBody = {
      consultationId: this.utilityService.getFromStorage('consultationId'),
    };
    this.restAPIService
      .invoke(APIEndPoint.GET_CONSULTAION_DATA, null, reqBody)
      .subscribe((res: any) => {
        if (Array.isArray(res.medicines) && res.medicines.length > 0) {
          res.medicines = res?.medicines.map((m) => {
            if (m && m.duration) {
              const foundDuration = durationConstants.find(
                (d) => +d.value === +m.duration
              );
              if (foundDuration) {
                m.duration = foundDuration.label;
              }
            }
            return m;
          });
        }
        if (res.dob) {
          res.dob = this.utilityService.getAge(res.dob);
        }
        if (res.callLandingDateTime) {
          res.callLandingDateTime = this.momentService
            ._moment(res.callLandingDateTime)
            .format('DD-MM-YYYY, h:mm:ss');
        }
        this.consultationDetails = res;
      });
  }

  private moveUserToDashboard(): void {
    this.restAPIService
      .invoke(APIEndPoint.MOVE_DASHBOARD, null, {
        consultationId: this.utilityService.getFromStorage('consultationId'),
      })
      .subscribe((data: any) => {
        this.middleService.onPrescriptionSentChangeChange(true);
        // if (data['checkValue'] === true) {
        //   this.utilityService.navigate('/dashboard');
        // }
      });
  }

  sendPrescriptionReport() {
    const reqBody = {
      consultationId: this.utilityService.getFromStorage('consultationId'),
      prescriptionSentStatus: 1,
    };
    this.restAPIService
      .invoke(APIEndPoint.SEND_PRESCRIPTION_REPORT, null, reqBody)
      .subscribe((res: any) => {
        if (res?.statusCode === 1) {
          this.toastrService.pop(
            'success',
            null,
            'Your prescription sent successfully'
          );
          this.dialogRef.close(true);
          this.moveUserToDashboard();
          sessionStorage.setItem("prescriptionStatus", 'sent');
        }
      });
  }

  getCommaSeparatedValues(val: string = '', key: string = ''): string {
    if (!val && !key && !this.consultationDetails) {
      return '';
    }
    return this.utilityService.getCommaSeparatedValues(
      this.consultationDetails[val]?.filter((c) => c[key] !== null),
      key
    );
  }

  isCommentPresent(key: string = ''): boolean {
    if (!key && !this.consultationDetails) {
      return false;
    }
    const isPresent: any[] = this.consultationDetails[key]?.filter(
      (c) => c?.comment !== null
    );
    if (isPresent.length === 0) {
      return null;
    }
    return true;
  }
}
