<div class="patient_vedio" fxFlex="100" fxLayout="column">
    <div fxLayout="row" fxFlex="100">
      <div class="expand_icon">
        <button (click)="expandWidth()"><img src="assets/vedio_calling_btns/expand_icon.png" alt=""></button>
      </div>
      <img src="assets/images/patient_vedio.png" alt="" />
      
      <div
        fxLayout="row"
        fxFlex="100"
        fxLayoutAlign="end start"
        class="admin_vedio_container"
      >
        <div class="admin_vedio">
          <img src="assets/images/admin_video.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="vedio_calling_btns"
      fxLayout="row"
      fxFlex="100"
      fxLayoutAlign="space-around center"
    >
      <div
        class="call_duration_display"
        fxFlex="40"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div fxLayout="row" fxLayoutAlign="center center">
          <div class="call_status icon" fxLayoutAlign="center center"></div>

          <p fxLayoutAlign="center center">2:00</p>
        </div>
      </div>
      <div
        class="call_switch_btn"
        fxFlex="20"
        fxLayoutAlign="space-around center"
      >
        <div class="vedio_cam_icon">
          <img
            src="assets/vedio_calling_btns/video.png"
            alt=" "
          />
        </div>
        <button class="mic_switch"  fxLayoutAlign="center center" (click)="onSwitch()">
          <mat-icon *ngIf="micOnSwitch">mic_on</mat-icon>
          <mat-icon *ngIf="micOffSwitch">mic_off</mat-icon>
        </button>
      </div>
      <div class="call_pick_drop_btn" fxFlex="40" fxLayoutAlign="end center">
        <div class="call_drop_icon">
          <img
            src="assets/vedio_calling_btns/end-call.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>