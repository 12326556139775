
  <mat-card  fxLayoutAlign="center center"  fxLayout="column"> <h1 mat-dialog-title>Prescription sent</h1>

 
  

  <button mat-raised-button color="primary"  class="submit_btn" (click)="close()">
    Dashboard
  </button>
</mat-card>
