import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MiddleService } from 'src/app/core/services/middle.service';
import { SummaryNotes } from 'src/app/shared/models/summary-notes.model';
import { VitalData } from 'src/app/shared/models/vital-data.model';

@Component({
  selector: 'app-consultation-right',
  templateUrl: './consultation-right.component.html',
  styleUrls: ['./consultation-right.component.scss'],
})
export class ConsultationRightComponent implements OnInit {
  @Input() selectedConsultation: string;
  @Input() instructionForm: FormGroup;
  @Input() options = { consultationIndex: null, itemIndex: null };
  @Input() consultationData: any;
  @Output() vitalsEvent = new EventEmitter();

  constructor(private middleService: MiddleService) {}

  ngOnInit(): void {}

  saveVitals(data: VitalData) {
    this.vitalsEvent.emit(data);
  }

  updateMedicineHandler(payload) {}

  saveNotes(data: SummaryNotes) {
    if (data.summaryNotes && String(data.summaryNotes).length > 0) {
      this.middleService.updateSummarryNotes(data).subscribe();
    }
  }

  saveComment(payload) {
    const path = payload.path;
    delete payload.path;
    this.middleService.consultationCategory(payload, path).subscribe();
  }
}
