import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-ayushman-package-report',
  templateUrl: './ayushman-package-report.component.html',
  styleUrls: ['./ayushman-package-report.component.scss'],
})
export class AyushmanPackageReportComponent implements OnInit {
  selectedTest = null;

  constructor(private utilityService: UtilityService) {}

  ngOnInit(): void {
    this.selectedTest = this.utilityService.getFromStorage('selectedTest');
  }

  onBack(): void {
    this.utilityService.removeFromStorage('selectedTest');
    this.utilityService.navigate('/consultation/testhistory');
  }
}
