import { UtilityService } from 'src/app/core/services/utility.service';

export class TestHistory {
  id = 1;
  name = null;
  date = null;
  reportPdfPath = null;
  title = null;

  constructor(testHistory = null) {
    if (testHistory) {
      this.id = testHistory.index;
      this.name = UtilityService.titleCase(testHistory.itemType);
      this.date = testHistory.orderDate;
      // this.date = UtilityService.epochTimeInDateString(UtilityService.epochToLocalDateConverter(testHistory.orderDate));
      this.reportPdfPath = testHistory.reportPdfPath;
      this.title = testHistory.title;
    }
  }
}
