import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDetails } from 'src/app/shared/models/user-details.model';
import { APIEndPoint } from '../https/ApiEndPoint';
import { RestApiService } from '../https/rest-api.service';
const customerId = window.localStorage.getItem('customerId');
@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  $userChange = new BehaviorSubject(customerId);
  userChange = this.$userChange.asObservable();
  constructor(private restAPIService: RestApiService) {}

  setUserData(id) {
    this.$userChange.next(id);
  }
  getUserDetails(customerId) {
    return this.restAPIService
      .invoke(APIEndPoint.USER_DETAILS, null, {
        customerId,
      })
      .pipe(map((res) => new UserDetails(res)));
  }
}
