import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[mobileNumber]',
})
export class MobileNumberDirective {
  constructor() {}

  @HostListener('keydown', ['$event'])
  onkeydown(e: any) {
    if (e.target.selectionStart === 0 && e.key === '0') {
      e.preventDefault();
    }
  }
}
