import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APIEndPoint } from 'src/app/core/https/ApiEndPoint';
import { RestApiService } from 'src/app/core/https/rest-api.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { DoctorCallService } from 'src/app/core/services/doctor-call.service';
import { DoctorDetailsService } from 'src/app/core/services/doctorDetails.service';
import { UserDetailsService } from 'src/app/core/services/userDetails.service';
import { UserDetails } from 'src/app/shared/models/user-details.model';

@Component({
  selector: 'app-dashboard-dailog-box',
  templateUrl: './dashboard-dailog-box.component.html',
  styleUrls: ['./dashboard-dailog-box.component.scss'],
})
export class DashboardDailogBoxComponent implements OnInit, OnDestroy {
  clearTimeout: any;
  message: string;
  userdetails: UserDetails = new UserDetails();
  audio = new Audio();
  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private restAPIService: RestApiService,
    private doctorCallService: DoctorCallService,
    private doctorDetailsService: DoctorDetailsService,
    private userDetailsServices: UserDetailsService
  ) {}

  ngOnInit(): void {
    // this.clearTimeout = setTimeout(() => {
    //   this.updateCallStatus(3);
    //   this.dialog.closeAll();
    //   this.doctorCallService.checkcall(true);
    //   this.doctorDetailsService.callDisconnect(true);
    // }, 15000);
    this.message = this.doctorCallService.getMessage();
    this.getUserDetailsData();
    this.playAudio()
  }

  playAudio(): void {
    this.audio.src = '../assets/ring.mp3';
    this.audio.load();
    this.audio.play();
    this.audio.loop = true;
  }

  pauseAud() { 
    this.audio.pause(); 
}

  openDialog() {
    const dialogRef = this.dialog.open(DashboardDailogBoxComponent);
    dialogRef.afterClosed().subscribe(() => {});
  }

  disconnectCall() {
    this.pauseAud();
    this.dialog.closeAll();
    this.updateCallStatus(2);
    this.doctorCallService.checkcall(true);
    this.doctorDetailsService.callDisconnect(true);
    this.doctorCallService.online().subscribe();
    this.utilityService.removeFromStorage('roomId');
    this.utilityService.removeFromStorage('customerId');
  }

  joinCall() {

    // this.utilityService.saveToStorage('customerId', res.callData.userToCall);
    // this.utilityService.saveToStorage('roomId', res.callData.roomId);
    // this.utilityService.saveToStorage('roomToken', res.callData.roomToken);

    this.pauseAud()
    this.dialog.closeAll();
    this.updateCallStatus(1);
  }

  updateCallStatus = (status) => {
    const reqBody = {
      callStatus: status,
      consultationId: this.utilityService.getFromStorage('consultationId'),
      customerId: this.utilityService.getFromStorage('customerId'),
    };
    this.restAPIService
      .invoke(APIEndPoint.UPDATE_CALL_LANDING, null, reqBody)
      .subscribe(() => {
        if (status === 1) {
          this.utilityService.navigate('/consultation/consultation-details');
          // this.restAPIService
          //   .invoke(APIEndPoint.DOCTOR_OFFLINE)
          //   .subscribe(() => {});
        }
      });
  };

  getUserDetailsData() {
    this.userDetailsServices
      .getUserDetails(this.utilityService.getFromStorage('customerId'))
      .subscribe((res: any) => {
        this.userdetails = res;
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.clearTimeout);
    this.pauseAud()
  }
}
