import { Injectable } from '@angular/core';
import moment from 'moment';

type IMoment = typeof moment;
@Injectable({ providedIn: 'root' })
export class MomentService {
  public _moment: IMoment = moment;
  constructor() {}

  static getMoment(): IMoment {
    return moment;
  }

  static get _moment(): IMoment {
    return moment;
  }

  public formatDate(
    date: string | Date,
    format = 'DD/MM/YYYY',
    toDate: boolean = false
  ): string {
    if (!date) {
      return '';
    }
    return toDate
      ? (this._moment(date).format(format) as any).toDate()
      : this._moment(date).format(format);
  }

  public subtract(
    date: string | Date,
    count: number = 0,
    unit: 'year' | 'months' | 'days' = 'year',
    format: string = null,
    toDate: boolean = false
  ): any {
    if (!date) {
      if (format) {
        return this._moment().subtract(count, unit).format(format);
      }
      return this._moment().subtract(count, unit);
    }
    if (toDate) {
      return format
        ? this._moment(date).subtract(count, unit).format(format)
        : this._moment(date).subtract(count, unit).toDate();
    }
    if (format) {
      return this._moment().subtract(count, unit).format(format);
    }
    return this._moment(date).subtract(count, unit);
  }

  public add(
    date: string | Date,
    count: number = 0,
    unit: 'year' | 'months' | 'days' = 'year',
    format: string = null,
    toDate: boolean = false
  ): any {
    if (!date) {
      if (format) {
        return this._moment().add(count, unit).format(format);
      }
      return this._moment().add(count, unit);
    }
    if (toDate) {
      return format
        ? this._moment(date).add(count, unit).format(format)
        : this._moment(date).add(count, unit).toDate();
    }
    if (format) {
      return this._moment().add(count, unit).format(format);
    }
    return this._moment(date).add(count, unit);
  }
}
