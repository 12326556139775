<div class="comment_box" fxLayout="column" fxFlex="100">
  <mat-card fxLayoutAlign="start center">
    <mat-card-title>{{ consultationData.title }}</mat-card-title>
  </mat-card>
  <div
    class="comment_box_heading"
    fxLayout="column"
    fxLayoutAlign="start start"
  >
    <div fxLayout="row" class="title" *ngIf="options.itemIndex">
      <p>{{ consultationData?.selectedItems[options.itemIndex]?.name }}</p>
    </div>
    <div fxLayout="row">
      <mat-card-title class="comment">Comment</mat-card-title>
    </div>
  </div>
  <div fxLayout="column" class="comment_box_textarea">
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
      <textarea
        (blur)="saveNotes()"
        id=""
        rows="10"
        gdRows.lg="8"
        placeholder="Enter Comment Here.."
        fxFlex="86"
        fxFlex.lg="81"
        [(ngModel)]="consultationData.summaryNotes"
      ></textarea>
    </div>
    <!-- <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="100">
            <button [disabled]="!consultationData.summaryNotes" mat-raised-button color="primary" class="btn preview-btn" (click)="saveNotes()">
                Save
            </button>
        </div> -->
  </div>
</div>
