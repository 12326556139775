import { Allergy } from "../models/allergy.model";
import { Instructions } from "../models/instruction.model";
import { MedicalHistory } from "../models/medical-history.model";
import { Medicines } from "../models/medicines.model";
import { ProvisionalDiagnosis } from "../models/provisional-diagnosis.model";
import { SymptomsComplaints } from "../models/symptoms.model";
import { TestRecommendation } from "../models/test-recommendation.model";

export const REMOVE_API_MAPPER = {
    medical_history: {
        path: 'removeMedicalHistory',
        keyName: 'diseaseId'
    },
    symptoms: {
        path: 'removeCustomerSymptoms',
        keyName: 'symptomsId'
    },
    allergy: {
        path: 'removeCustomerAllergy',
        keyName: 'allergyId'
    },
    instruction: {
        path: 'removeCustomerInstruction',
        keyName: 'instructionId'
    },
    provisional_diagnosis: {
        path: 'removeCustomerDiagnosis',
        keyName: 'diagnosisId'

    },
    medicines: {
        path: 'removeCustomerMedicines',
        keyName: 'medicineId'
    },
    test_recommendation: {
        path: 'removeCustomerTestRecommendation',
        keyName: 'testId'
    }
};

export const ADD_API_MAPPER = {
    medical_history: {
        path: 'addCustomerMedicalHistory',
        keyName: 'diseaseId'
    },
    symptoms: {
        path: 'addCustomerSymptoms',
        keyName: 'symptomsId'
    },
    allergy: {
        path: 'addCustomerAllergy',
        keyName: 'allergyId'
    },
    instruction: {
        path: 'addCustomerInstruction',
        keyName: 'instructionId'
    },
    provisional_diagnosis: {
        path: 'addCustomerDiagnosis',
        keyName: 'diagnosisId'

    },
    medicines: {
        path: 'addCustomerMedicines',
        keyName: 'medicineId'
    },
    test_recommendation: {
        path: 'addCustomerTestRecommendation',
        keyName: 'testId'
    }
};

export const UPDATE_API_MAPPER = {
    medical_history: {
        path: 'updateMedicalHistory',
        keyName: 'diseaseId'
    },
    symptoms: {
        path: 'updateCustomerSymptoms',
        keyName: 'symptomsId'
    },
    allergy: {
        path: 'updateCustomerAllergy',
        keyName: 'allergyId'
    },
    provisional_diagnosis: {
        path: 'updateCustomerDiagnosis',
        keyName: 'diagnosisId'

    },
    medicines: {
        path: 'updateCustomerMedicines',
        keyName: 'medicineId'
    },
    test_recommendation: {
        path: 'updateCustomerTesRecommendation',
        keyName: 'testId'
    }
};

export const SEARCH_API_MAPPER = {
    medical_history: {
        path: 'searchMedicalHistory',
        keyName: 'disease',
        resKeyName: 'diseaseList',
        model: MedicalHistory
    },
    symptoms: {
        path: 'searchSymptoms',
        keyName: 'symptom',
        resKeyName: 'symptomsList',
        model: SymptomsComplaints
    },
    allergy: {
        path: 'searchAllergy',
        keyName: 'allergy',
        resKeyName: 'allergyList',
        model: Allergy
    },
    instruction: {
        path: 'searchInstruction',
        keyName: 'instruction',
        resKeyName: 'instructionList',
        model: Instructions
    },
    provisional_diagnosis: {
        path: 'searchDiagnosis',
        keyName: 'diagnosis',
        resKeyName: 'diagnosisList',
        model: ProvisionalDiagnosis

    },
    medicines: {
        path: 'searchMedicines',
        keyName: 'medicine',
        resKeyName: 'medicinesList',
        model: Medicines
    },
    test_recommendation: {
        path: 'searchTestRecommendation',
        keyName: 'test',
        resKeyName: 'testList',
        model: TestRecommendation
    }
};

export const GET_DEFAULT_API_MAPPER = {
    medical_history: {
        path: 'getDefaultMedicalHistory',
        resKeyName: 'diseaseList',
        model: MedicalHistory
    },
    symptoms: {
        path: 'getDefaultSymptoms',
        resKeyName: 'symptomsList',
        model: SymptomsComplaints
    },
    allergy: {
        path: 'getDefaultAllergy',
        resKeyName: 'allergyList',
        model: Allergy
    },
    instruction: {
        path: 'getDefaultInstruction',
        resKeyName: 'instructionList',
        model: Instructions
    },
    provisional_diagnosis: {
        path: 'getDefaultDiagnosis',
        resKeyName: 'diagnosisList',
        model: ProvisionalDiagnosis
    },
    medicines: {
        path: 'getDefaultMedicines',
        resKeyName: 'medicinesList',
        model: Medicines
    },
    test_recommendation: {
        path: 'getDefaultTestRecommendation',
        resKeyName: 'testList',
        model: TestRecommendation
    }
};
export const GET_HSITORY_API_MAPPER = {
    medical_history: {
        path: 'getCustomerMedicalHistory',
        resKeyName: 'diseaseList',
        model: MedicalHistory
    },
    symptoms: {
        path: 'getCustomerSymptoms',
        resKeyName: 'symptomsList',
        model: SymptomsComplaints
    },
    allergy: {
        path: 'getCustomerAllergy',
        resKeyName: 'allergyList',
        model: Allergy
    },
    instruction: {
        path: 'getCustomerInstruction',
        resKeyName: 'instructionList',
        model: Instructions
    },
    provisional_diagnosis: {
        path: 'getCustomerDiagnosis',
        resKeyName: 'diagnosisList',
        model: ProvisionalDiagnosis
    },
    medicines: {
        path: 'medicineCustomerList',
        resKeyName: 'medicinesList',
        model: Medicines
    },
    test_recommendation: {
        path: 'getCustomerTestRecommendation',
        resKeyName: 'testList',
        model: TestRecommendation
    }
};

export const PAST_HSITORY_API_MAPPER = {
    medical_history: {
        path: 'customerMedicalPastHistory',
        resKeyName: 'diseaseList',
        model: MedicalHistory
    },
    symptoms: {
        path: 'customerSymptomsPastHistory',
        resKeyName: 'symptomsList',
        model: SymptomsComplaints
    },
    allergy: {
        path: 'customerAllergyPastHistory',
        resKeyName: 'allergyList',
        model: Allergy
    },
    instruction: {
        path: 'customerInstructionPastHistory',
        resKeyName: 'instructionList',
        model: Instructions
    },
    provisional_diagnosis: {
        path: 'customerDiagnosisPastHistory',
        resKeyName: 'diagnosisList',
        model: ProvisionalDiagnosis
    },
    medicines: {
        path: 'customerMedicinesPastHistory',
        resKeyName: 'medicinesList',
        model: Medicines
    },
    test_recommendation: {
        path: 'customerTestRecommendationPastHistory',
        resKeyName: 'testList',
        model: TestRecommendation
    }
};
