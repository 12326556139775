import { Injectable } from '@angular/core';
import { FamiltyDetails } from 'src/app/shared/models/familyDetails.model';
import { APIEndPoint } from '../https/ApiEndPoint';
import { RestApiService } from '../https/rest-api.service';
import { UtilityService } from './utility.service';
import { map } from 'rxjs/operators';
import { AddNewMember } from 'src/app/shared/models/addNewMember.model';

@Injectable({
  providedIn: 'root',
})
export class ConsultationLeftService {
  constructor(
    private restAPIService: RestApiService,
    private utilityService: UtilityService
  ) {}

  saveDoctorComments(payload) {
    return this.restAPIService.invoke(
      APIEndPoint.DOCTOR_COMMENTS,
      null,
      payload
    );
  }

  getDoctorComments(body) {
    return this.restAPIService.invoke(
      APIEndPoint.GET_DOCTOR_COMMENTS,
      null,
      body
    );
  }

  searchFamilyByNumber(body) {
    return this.restAPIService
      .invoke(APIEndPoint.SEARCH_FAMILY_BY_NUMBER, null, body)
      .pipe(
        map((res: any) => {
          return res.members.map((elem) => new FamiltyDetails({ ...elem }));
        })
      );
  }

  getFamilyList(body) {
    return this.restAPIService
      .invoke(
        APIEndPoint.GET_FAMILY_DETAILS,
        { id: 'getfamilylist' },
        {
          customerId: this.utilityService.getFromStorage('customerId'),
        }
      )
      .pipe(
        map((res: any) => {
          return res.members.map((elem) => new FamiltyDetails({ ...elem }));
        })
      );
  }

  saveFamilymember(body) {
    return this.restAPIService.invoke(
      APIEndPoint.GET_FAMILY_DETAILS,
      { id: 'adduserfamily' },
      {
        parentCustomerId: this.utilityService.getFromStorage('customerId'),
        ...body,
      }
    );
  }

  checkPrescriptionSent(body) {
    return this.restAPIService.invoke(
      APIEndPoint.CHECK_PRESCRIPTION_SENT,
      null,
      body
    );
  }
}
